// ReasonsToWorkDesktop.jsx

import React, { useEffect, useRef, useState } from 'react';
import {
    TimelineBanner,
    SlideWrapper,
    CarouselSlide,
    TopContent,
    Title,
    Divider,
    ReasonItem,
    ReasonContainer,
    MobileReasons,
    ReasonInfo,
    ReasonNumber,
    NumberContent,
    NumberImg,
    NumberProgress,
    NumberBackground,
    TextContainer,
    ReasonTitle,
    ReasonDescription,
    TopLink,
} from './ReasonsToWork.styles';

import Number1 from '../../assets/media/n01_transparent.png';
import Number2 from '../../assets/media/n02_transparent.png';
import Number3 from '../../assets/media/n03_transparent.png';
import Number4 from '../../assets/media/n04_transparent.png';
import Number5 from '../../assets/media/n05_transparent.png';
import { useTranslation } from 'react-i18next';
import { buildUrl } from '../../utils/urlUtils';
import useLanguage from '../../hooks/useLanguage';

const ReasonsToWorkDesktop = () => {
    const [reasonProgress, setReasonProgress] = useState({});
    const reasonRefs = useRef([]);
    const { t } = useTranslation();
    const { language } = useLanguage();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const progress = entry.intersectionRatio;
                    setReasonProgress((prev) => ({
                        ...prev,
                        [entry.target.dataset.index]: progress,
                    }));
                });
            },
            { threshold: Array.from({ length: 101 }, (_, i) => i / 100) }
        );

        reasonRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            reasonRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    const getRevealStyle = (index) => ({
        backgroundImage: `linear-gradient(to bottom, var(--color-green)${(reasonProgress[index] || 0) * 100
            }%, transparent ${(reasonProgress[index] || 0) * 100}%)`,
        transition: 'background-image 0.1s ease-out',
    });

    return (
        <TimelineBanner>
            <SlideWrapper>
                <CarouselSlide>
                    <TopContent>
                        <Title>{t('reasonsToWork.title')}</Title>
                    </TopContent>

                    <MobileReasons>
                        {/* Reason 1 */}
                        <ReasonItem>
                            <ReasonContainer ref={(el) => (reasonRefs.current[0] = el)} data-index="0">
                                <ReasonInfo>
                                    <ReasonNumber>
                                        <NumberContent style={getRevealStyle('1')}>
                                            <NumberImg src={Number1} alt="Number 1" />
                                        </NumberContent>
                                        <NumberProgress />
                                        <NumberBackground />
                                    </ReasonNumber>
                                    <TextContainer>
                                        <ReasonTitle>{t('reasonsToWork.reasons.0.title')}</ReasonTitle>
                                        <ReasonDescription>
                                            {t('reasonsToWork.reasons.0.description')}
                                        </ReasonDescription>
                                    </TextContainer>
                                </ReasonInfo>
                            </ReasonContainer>
                        </ReasonItem>

                        {/* Reason 2 */}
                        <ReasonItem>
                            <ReasonContainer ref={(el) => (reasonRefs.current[1] = el)} data-index="1">
                                <ReasonInfo>
                                    <ReasonNumber>
                                        <NumberContent style={getRevealStyle('2')}>
                                            <NumberImg src={Number2} alt="Number 2" />
                                        </NumberContent>
                                        <NumberProgress background="#24b39c" />
                                        <NumberBackground />
                                    </ReasonNumber>
                                    <TextContainer>
                                        <ReasonTitle>{t('reasonsToWork.reasons.1.title')}</ReasonTitle>
                                        <ReasonDescription>
                                            {t('reasonsToWork.reasons.1.description')}{' '}
                                        </ReasonDescription>
                                        <TopLink href={buildUrl('/ourculture', language)}>{t('reasonsToWork.reasons.1.link')}</TopLink>
                                    </TextContainer>
                                </ReasonInfo>
                            </ReasonContainer>
                        </ReasonItem>

                        {/* Reason 3 */}
                        <ReasonItem>
                            <ReasonContainer ref={(el) => (reasonRefs.current[2] = el)} data-index="2">
                                <ReasonInfo>
                                    <ReasonNumber>
                                        <NumberContent style={getRevealStyle('3')}>
                                            <NumberImg src={Number3} alt="Number 3" />
                                        </NumberContent>
                                        <NumberProgress background="#59bc74" />
                                        <NumberBackground />
                                    </ReasonNumber>
                                    <TextContainer>
                                        <ReasonTitle>{t('reasonsToWork.reasons.2.title')}</ReasonTitle>
                                        <ReasonDescription>
                                            {t('reasonsToWork.reasons.2.description')}
                                        </ReasonDescription>
                                    </TextContainer>
                                </ReasonInfo>
                            </ReasonContainer>
                        </ReasonItem>

                        {/* Reason 4 */}
                        <ReasonItem>
                            <ReasonContainer ref={(el) => (reasonRefs.current[3] = el)} data-index="3">
                                <ReasonInfo>
                                    <ReasonNumber>
                                        <NumberContent style={getRevealStyle('4')}>
                                            <NumberImg src={Number4} alt="Number 4" />
                                        </NumberContent>
                                        <NumberProgress background="#7dc352" />
                                        <NumberBackground />
                                    </ReasonNumber>
                                    <TextContainer>
                                        <ReasonTitle>{t('reasonsToWork.reasons.3.title')}</ReasonTitle>
                                        <ReasonDescription>
                                            {t('reasonsToWork.reasons.3.description')}
                                        </ReasonDescription>
                                    </TextContainer>
                                </ReasonInfo>
                            </ReasonContainer>
                        </ReasonItem>

                        {/* Reason 5 */}
                        <ReasonItem>
                            <ReasonContainer ref={(el) => (reasonRefs.current[4] = el)} data-index="4">
                                <ReasonInfo>
                                    <ReasonNumber>
                                        <NumberContent style={getRevealStyle('5')}>
                                            <NumberImg src={Number5} alt="Number 5" />
                                        </NumberContent>
                                        <NumberProgress background="var(--color-green)" />
                                        <NumberBackground />
                                    </ReasonNumber>
                                    <TextContainer>
                                        <ReasonTitle>{t('reasonsToWork.reasons.4.title')}</ReasonTitle>
                                        <ReasonDescription>
                                            {t('reasonsToWork.reasons.4.description')}
                                        </ReasonDescription>
                                    </TextContainer>
                                </ReasonInfo>
                            </ReasonContainer>
                        </ReasonItem>
                        <ReasonItem>
                            <ReasonContainer 
                                style={{padding:"100px", position:"absolute"}} 
                                ref={el => reasonRefs.current[5] = el} 
                                data-index="5"
                            /> 
                        </ReasonItem>
                    </MobileReasons>
                </CarouselSlide>
            </SlideWrapper>
        </TimelineBanner>
    );
};

export default ReasonsToWorkDesktop;
