import styled from 'styled-components';

export const Container = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 4rem 2rem;
  view-transition-name: faq-container;
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  view-transition-name: faq-header;
`;

export const Description = styled.p`
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors?.text || '#64748B'};
  margin-bottom: 3rem;
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  view-transition-name: faq-description;
  
  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 0.3s ease;
  }
`;

export const FAQList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  view-transition-name: faq-list;
`;

export const FAQItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  view-transition-name: faq-item-${props => props.index};
  
  &:last-child {
    border-bottom: none;
  }
`;

export const Question = styled.button`
  width: 100%;
  text-align: left;
  padding: 1.5rem 0;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-green);
  font-size: 1.125rem;
  font-weight: 600;
  gap: 1rem;
  transition: all 0.2s ease;
  
  &:hover {
    opacity: 0.85;
  }

  svg {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform: ${({ isOpen }) => isOpen ? 'rotate(180deg)' : 'rotate(0)'};
    color: var(--color-green);
    opacity: 0.9;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
    svg {
      transition: none;
    }
  }
`;

export const Answer = styled.div`
  grid-area: answer;
  overflow: hidden;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform-origin: top;
  transform: ${({ isOpen }) => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  margin-bottom: ${({ isOpen }) => (isOpen ? '1.5rem' : '0')};
  view-transition-name: faq-answer-${props => props.index};

  @media (prefers-reduced-motion: no-preference) {
    transition: 
      visibility 0s ${({ isOpen }) => (isOpen ? '0s' : '0.3s')},
      opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      height 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      margin-bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  p {
    color: ${({ theme }) => theme.colors?.text || '#64748B'};
    line-height: 1.6;
    margin: 0;
    font-size: 1rem;
    max-width: 95%;
  }

  a {
    color: var(--color-green);
    text-decoration: none;
    transition: all 0.2s ease;
    
    &:hover {
      text-decoration: underline;
      opacity: 0.85;
    }
  }
`;