import styled from "styled-components";

export const ValuesContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
   
    @media (max-width: 960px) {
        flex-direction: column;
        text-align: center;
    }
    `;