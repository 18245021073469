import { useTranslation } from "react-i18next";

import CTASection from "../components/CTASection/CTASection";
import { buildUrl } from "../utils/urlUtils";
import useLanguage from "../hooks/useLanguage";
import SEO from "../components/SEO/SEOComponent";
import ContactSection from "../components/ContactSection/ContactSection";
import HeroSection from "../components/HeroSection/HeroSection";
import desktopImage from "../assets/media/HeroSlide4.png";

export default function Contact() {
    const { t } = useTranslation();
    const { language } = useLanguage();
    return (
        <>
            <SEO
                title={t('seo.contactPage.title')}
                description={t('seo.contactPage.description')}
                image="https://careers.bathfitter.com/Media/Default/careers/contact-hero.jpg"
                url="https://careers.bathfitter.com/contact"
                author={t('seo.common.author')}
                publishedAt="2024-03-21"
                type={t('seo.common.type')}
                schemaType="Organization"
                schema={{
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: t('seo.common.organization.name'),
                    alternateName: t('seo.common.organization.alternateName'),
                    description: t('seo.common.organization.description'),
                    url: 'https://careers.bathfitter.com/contact',
                    location: [
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.usa.address')
                            }
                        },
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.canada.address')
                            }
                        }
                    ],
                    contactPoint: [
                        {
                            '@type': 'ContactPoint',
                            contactType: "Careers Information",
                            email: t('seo.common.contactEmail'),
                            availableLanguage: ['English', 'French']
                        },
                        {
                            '@type': 'ContactPoint',
                            contactType: "FAQ",
                            url: 'https://careers.bathfitter.com/faq'
                        }
                    ],
                    sameAs: [
                        'https://www.linkedin.com/company/bath-fitter'
                    ],
                    mainEntityOfPage: {
                        '@type': 'ContactPage',
                        '@id': 'https://careers.bathfitter.com/contact',
                        name: t('seo.common.organization.name'),
                        description: t('contactPage.questions'),
                        mainEntity: {
                            '@type': 'FAQPage',
                            url: 'https://careers.bathfitter.com/faq'
                        }
                    },
                    potentialAction: {
                        '@type': 'AskAction',
                        target: {
                            '@type': 'EntryPoint',
                            urlTemplate: 'https://careers.bathfitter.com/contact',
                            actionPlatform: [
                                'http://schema.org/DesktopWebPlatform',
                                'http://schema.org/MobileWebPlatform'
                            ]
                        },
                        object: {
                            '@type': 'Question',
                            about: {
                                '@type': 'Thing',
                                name: 'Career Opportunities'
                            }
                        }
                    }
                }}
            />

            <HeroSection
                desktopImage={desktopImage}
                mobileImage={desktopImage}
                title={t('contactPage.heroTitle')}
                descriptionLine1={t('contactPage.heroDescription')}
            />

            <ContactSection />

            <CTASection
                title={t('contactPage.questions')}
                description={t('contactPage.faqDesc')}
                ctaLabel={t('contactPage.faq')}
                onCtaClick={() => window.location.href = buildUrl('/FAQ', language)}
            />
        </>
    )
}