// MediaContent.js

import React from 'react';
import {
  MediaContentContainer,
  MediaSection,
  ContentSection,
} from './MediaContent.styles';
import { Paragraph, SectionHeading, TertiaryButton } from '../common/Common.styles';

const MediaContent = ({
  mediaLayout = 'left', // 'left' or 'right'
  mediaSrc,
  mediaType = 'image', // 'image' or 'video'
  title,
  description,
  ctaLabel = '',
  onCtaClick = () => {}
}) => {
  return (
    <MediaContentContainer mediaLayout={mediaLayout}>
      {/* Media Section */}
      <MediaSection>
        {mediaType === 'image' ? (
          <img src={mediaSrc} alt={title} />
        ) : (
          <iframe
            src={mediaSrc}
            width="100%"
            height="315"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title={title}
          ></iframe>
        )}
      </MediaSection>

      {/* Content Section */}
      <ContentSection>
        <SectionHeading>{title}</SectionHeading>
        <Paragraph>{description}</Paragraph>
        {ctaLabel && (
          <TertiaryButton onClick={onCtaClick}>{ctaLabel}</TertiaryButton>
        )}
      </ContentSection>
    </MediaContentContainer>
  );
};

export default MediaContent;
