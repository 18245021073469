import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  CarouselContainer,
  StyledSlider,
  ContentSection,
  Description,
  CtaButton
} from './InformativeImageCarousel.styles';
import { SectionHeading, TertiaryButton } from '../common/Common.styles';

const InformativeImageCarousel = ({
  slides = [],
  title,
  description,
  ctaLabel,
  onCtaClick
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false
  };

  return (
    <CarouselContainer>
      <ContentSection>
        <SectionHeading>{title}</SectionHeading>
        <Description>{description}</Description>
        {ctaLabel && (
          <TertiaryButton onClick={onCtaClick}>
            {ctaLabel}
          </TertiaryButton>
        )}
      </ContentSection>
      <StyledSlider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img src={slide.image} alt={slide.altText || "Informative slide"} />
          </div>
        ))}
      </StyledSlider>
    </CarouselContainer>
  );
};

export default InformativeImageCarousel;