import React from 'react';
import {
  ContentSection,
  CTAsSection
} from './CTASection.styles';
import { Paragraph, SectionHeading, TertiaryButton } from '../common/Common.styles';

const CTASection = ({
  title,
  description,
  ctaLabel = '',
  cta2Label = '',
  onCtaClick = () => {},
  onCta2Click = () => {}
}) => {
  return (
      <ContentSection>
        <SectionHeading>{title}</SectionHeading>
        <Paragraph>{description}</Paragraph>
        <CTAsSection>
            {ctaLabel && (
                <TertiaryButton onClick={onCtaClick}>{ctaLabel}</TertiaryButton>
            )}
            {cta2Label && (
                <TertiaryButton onClick={onCta2Click}>{cta2Label}</TertiaryButton>
            )}
        </CTAsSection>
      </ContentSection>
  );
};

export default CTASection;
