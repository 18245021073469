import { Link } from 'react-router-dom';
import { PrimaryHeading, Paragraph, NotFoundContainer, PrimaryButton, Spacer } from '../components/common/Common.styles';
import  useLanguage  from '../hooks/useLanguage';
import { buildUrl } from '../utils/urlUtils';

export default function NotFound() {
  const { language } = useLanguage();

  return (
    <NotFoundContainer>
      <img 
        src="https://www.bainmagique.com/static/PageNotFound-91047dab628fc8d7795ccf9259de08d6.png" 
        alt="page-not-found" 
      />
      <PrimaryHeading>
       {language === 'en' ? ' Oops! Something went wrong.' : 'Oups! Quelque chose s\'est mal passé.'}
      </PrimaryHeading>
      <Paragraph>
        {language === 'en' ? ' The page you are looking for was moved, removed, renamed, or might never have existed.' : 'La page que vous recherchez a été déplacée, supprimée, renommée ou n\'a peut-être jamais existé.'}
      </Paragraph>
      <PrimaryButton>
        <Link to={buildUrl('/', language)}>
         {language === 'en' ? 'Go back to home' : 'Retourner à la page d\'accueil'}
        </Link>
      </PrimaryButton>
      <Spacer />
    </NotFoundContainer>
  );
}
