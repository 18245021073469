import styled from 'styled-components';

// Typography

export const PrimaryHeading = styled.h1`
    font-size: 3.5rem;

    & span {
        display: inline-block;
    }

    @media (max-width: 680px) {
        font-size: 3rem;
    }
`;

export const AlternativeHeading = styled.h1`
    font-size: 3rem;

    @media (max-width: 680px) {
        font-size: 2.5rem;
    }
`;

export const SecondaryHeading = styled.h2`
    font-size: 2rem;

    @media (max-width: 680px) {
        font-size: 1.5rem;
    }
`;

export const TertiaryHeading = styled.h3`
    font-size: 1.2rem;
`;

export const SectionHeading = styled.h2`
  font-size: 2.5rem;
  color: #2b545a;
 width: fit-content;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: #00555b;
    margin: 0.5rem auto;
  }

    @media (max-width: 960px) {
        font-size: 2rem;
    }
`;

export const Paragraph = styled.p`
    font-size: 1rem;
    max-width: 720px;

    a {
        color: var(--color-green);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const Redirect = styled.a`
    color: var(--color-green);
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

export const Microcopy = styled.p`
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color-green);
`;

// Buttons

export const PrimaryButton = styled.button`
    min-width: 200px;
    background-color: var(--color-green);
    color: var(--color-white);
    padding: 1rem 2rem;
    border: 2px solid var(--color-green);
    border-radius: 2px;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    &:hover {
       opacity: 0.8;
    }
`;

export const SecondaryButton = styled.button`
    min-width: 200px;
    background-color: var(--primary-color);
    color: var(--color-white);
    padding: 1rem 2rem;
    border: 2px solid var(--primary-color);
    border-radius: 2px;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
       opacity: 0.8;
    }
`;

export const TertiaryButton = styled.button`
    min-width: 200px;
    background-color: var(--color-white);
    color: var(--primary-color);
    padding: 1rem 2rem;
    border: 2px solid var(--primary-color);
    border-radius: 2px;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
       opacity: 0.8;
    }
`;

export const FloatingButton = styled.button`
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    background-color: var(--color-green);
    color: var(--color-white);
    border: 2px solid var(--color-green);
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 1000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    height: 3rem;
    width: 3rem;
    display: grid;
    place-items: center;

    & svg {
        height: 2rem;
        width: 2rem;
    }

    &:hover {
       opacity: 0.8;
       filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

       & svg {
            scale: 1.1;
       }
    }

    &:active {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        & svg {
            scale: 0.98;
        }
    }

    @media (max-width: 680px) {
        bottom: 1rem;
        left: 1rem;
    }
`;

// Grid

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;

    @media (max-width: 1350px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 680px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const GridItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-white);
`;

// Flex

export const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 0auto;

    @media (max-width: 680px) {
        flex-direction: column;
        gap: 2rem;
    }
`;

export const FlexItem = styled.div`
    background-color: var(--color-white);
`;

// Containers

export const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.618rem;
`;

export const JumboContainer = styled.div`
    width: 100%;
    position: relative;
    padding: 6rem 2rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.618rem;
    `;

export const LoadingContainer = styled.div` 
    position: fixed;
    background-color: var(--color-white);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: ${props => props.$loading ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.618rem;

    z-index: 1000;
`;

export const QuoteContainer = styled.div`
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.618rem;
    background-image: url('${props => props.$imgurl}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: var(--color-white);
    z-index: 1;
    
    & p {
        font-size: 2rem;
        text-align: center;
        max-width: 960px;
    }

    & hr{
        margin: 20px 0 15px 0;
        height: 1px;
        width: 50px;
        border: 0;
        border-top: 1px solid #fff;
        border-top-color: #fff;
        padding-bottom: 10px;
    }

    &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(90deg, #84bd00 0%, rgba(132, 189, 0, .8) 1%, rgba(0, 164, 153, .6) 71%, #00a499 100%);
    background: -webkit-linear-gradient(90deg, #84bd00 0%, rgba(132, 189, 0, .8) 1%, rgba(0, 164, 153, .6) 71%, #00a499 100%);
    background: linear-gradient(90deg, #84bd00 0%, rgba(132, 189, 0, .8) 1%, rgba(0, 164, 153, .6) 71%, #00a499 100%);
    z-index: -1;
    }

    @media (max-width: 680px) {
        & p {
            font-size: 1.2rem;
        }
    }
`;




export const NotFoundContainer = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 130px);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
    background-color: var(--header-background-color);
    color: var(--color-white);

    & img {
        position: absolute;
        width: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: hue-rotate(90deg);
    }

    @media (max-width: 960px) {
        height: calc(100vh - 135px);
        text-align: center;
       img {
        width: 50%;
       }

       h1 {
           font-size: 1.2rem;
       }

       p {
              max-width: 80%;
         }
    }

    @media (max-width: 680px) {
        height: calc(100vh - 130px);
        text-align: center;
        img {
            width: 70%;
        }

        h1 {
            font-size: 1rem;
        }

        p {
            max-width: 90%;
        }
    }
`;

// Inputs

export const Input = styled.input`
    border: 1px solid var(--color-grey);
    box-shadow: none;
    color: var(--color-black);
    font-size: 1rem;
    height: 55px;
    line-height: 20px;
    padding: 5px 10px;
    outline: none;
    border-radius: 2px;
    width: 100%;

    &:focus {
        border-color: var(--primary-color);
    }

    &::placeholder {
        color: var(--color-grey);
    }

`;

export const Select = styled.select`
    width: 100%;
    height: 55px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 1px solid var(--color-grey);
    border-radius: 2px;
    outline: none;

    &:focus {
        border-color: var(--primary-color);
    }

    &::placeholder {
        color: var(--color-grey);
    }

    & > option {
        color: var(--color-black);
    }
`;

export const Textarea = styled.textarea`
    border: 1px solid var(--color-grey);
    box-shadow: none;
    color: var(--color-black);
    font-size: 1rem;
    height: 55px;
    line-height: 20px;
    padding: 5px 10px;
    outline: none;
    resize: none;
    border-radius: 2px;
    width: 100%;

    &:focus {
        border-color: var(--primary-color);
    }

    &::placeholder {
        color: var(--color-grey);
    }
`;


// Cards

export const Card = styled.div`
    position: relative;
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: start;
    aspect-ratio: 1/1;

    & p {
        width: 80%;
    }

    & svg {
        height: 5rem;
        width: 5rem;
        margin: 2rem auto;
        font-size: 2rem;
        
        & path {
            stroke: #5bbd74;
        }
    }

    @media (max-width: 680px) {
        height: 285px;
    }
    
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 680px;
`;

// Misc

export const Spacer = styled.div`
    height: 2rem;
    width: 100%;
`;

export const List = styled.ul`
    list-style-type: circle;
    padding: 0;
    margin-left: 1rem;
    width: 100%;
    max-width: 720px;

    & li {
        font-size: 0.9rem;
        line-height: 1.5;
        opacity: 0.8;
    }
`;