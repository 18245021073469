import React from 'react';
import { Helmet } from 'react-helmet';

import CultureHero from '../../assets/media/CultureHero.png';

const SEO = ({
  title,
  description,
  image,
  url,
  author = 'BathFitter | Bain Magique',
  publishedAt,
  type = 'article',
  schemaType = 'Article'
}) => {
  // Format date for schema
  const formattedDate = publishedAt ? new Date(publishedAt).toISOString() : new Date().toISOString();

  // Base schema
  const schema = {
    '@context': 'https://schema.org',
    '@type': schemaType,
    headline: title,
    description: description,
    author: {
      '@type': 'Organization',
      name: author,
    },
    image: CultureHero,
    url: url,
    datePublished: formattedDate,
    dateModified: formattedDate,
    publisher: {
      '@type': 'Organization',
      name: author,
      logo: {
        '@type': 'ImageObject',
        url: 'https://bathfittercom-prod-backend.azurewebsites.net/wp-content/uploads/2020/09/SvgLogo.svg',
      }
    }
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={CultureHero} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={author} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={CultureHero} />

      {/* Schema.org */}
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

export default SEO;