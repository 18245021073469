
export const ResourcesEn = {
    categories: {
        Admin: 'Administration',
        Plant: 'Plant',
        Retail: 'Retail',
    },
    departments: {
        id_4005298008: 'Store Office',
        id_4005251008: 'Human Resources',
        id_4005300008: 'Plumber',
        id_4005279008: 'Information Technology',
        id_4005303008: 'Branch Manager',
        id_4005237008: 'Marketing',
        id_4005289008: 'Machine Shop',
        id_4005232008: 'Manufacturing Operations',
        id_4005236008: 'Customer Service',
        id_4005241008: 'Finance',
        id_4005227008: 'Admin - Employee',
        id_4005239008: 'Human Resources',
        id_4005247008: 'Communications',
        id_4005238008: 'Product Development',
        id_4005242008: 'Legal Affairs & Admin',
        id_4005243008: 'Information Technology',
        id_4005245008: 'Retail Operation',
        id_4005246008: 'Customer Experience',
        id_4005240008: 'Supply Chain',
        id_4005233008: 'Continuous Improvement',
        id_4005234008: 'Engineering',
        id_4005235008: 'Bistro',
        id_4005244008: 'Learning & Development',
        id_4005250008: 'Product Development',
        id_4005248008: 'Manufacturing Operations',
        id_4005249008: 'Marketing',
        id_4005228008: 'Admin - Executive',
        id_4005252008: 'Supply Chain',
        id_4005253008: 'Finance',
        id_4005254008: 'Legal Affairs & Admin',
        id_4005255008: 'Information Technology',
        id_4005256008: 'Learning & Development',
        id_4005257008: 'Retail Operations',
        id_4005258008: 'Tubs',
        id_4005259008: 'Accessories',
        id_4005260008: 'Composites',
        id_4005261008: 'Shipping',
        id_4005262008: 'Display',
        id_4005263008: 'Machine Shop',
        id_4005264008: 'Maintenance',
        id_4005265008: 'Inventory Stockroom',
        id_4005266008: 'Extrusion',
        id_4005267008: 'Quality Inspection',
        id_4005268008: 'Manufacturing Operations',
        id_4005229008: 'Admin - Management',
        id_4005270008: 'Continuous Improvement',
        id_4005275008: 'Human Resources',
        id_4005276008: 'Supply Chain',
        id_4005278008: 'Legal Affairs & Admin',
        id_4005271008: 'Engineering',
        id_4005272008: 'Customer Service',
        id_4005277008: 'Finance',
        id_4005282008: 'Customer Experience',
        id_4005284008: 'Tubs',
        id_4005286008: 'Composites',
        id_4005291008: 'Inventory Stockroom',
        id_4005292008: 'Extrusion',
        id_4005293008: 'Quality Inspection',
        id_4005294008: 'Freight',
        id_4005295008: 'Adhesives',
        id_4005296008: 'Installer',
        id_4005297008: 'Sales Consultant',
        id_4005299008: 'Warehouse/Shop Hand',
        id_4005301008: 'Commercial Sales Consultant',
        id_4005302008: 'Production Supervision',
        id_4005304008: 'Installer Assistant',
        id_4005305008: 'Show Workers/Events',
        id_4005273008: 'Marketing',
        id_4005274008: 'Product Development',
        id_4005281008: 'Retail Operations',
        id_4005283008: 'Communications',
        id_4005285008: 'Accessories',
        id_4005287008: 'Shipping',
        id_4005288008: 'Display',
        id_4005230008: 'Plant',
        id_4005231008: 'Retail',
        id_4005290008: 'Maintenance',
        id_4016143008: 'Internal Call Center',
        id_4020328008: 'Bench Branch Leader',
    },
    locations: {
        'Saint-EustacheQuebecCanada': 'St-Eustache, Quebec, Canada',
        'SpringfieldTennesseeUnitedStates': 'Springfield, Tennessee, United States',
        'WoburnMassachusettsUnitedStates': 'Woburn, Massachusetts, United States',
        'EvansvilleIndianaUnitedStates': 'Evansville, Indiana, United States',
        'WestPalmBeachFloridaUnitedStates': 'West Palm Beach, Florida, United States',
        'HuntsvilleAlabamaUnitedStates': 'Huntsville, Alabama, United States',
        'KansasCityKansasUnitedStates': 'Kansas City, Kansas, United States',
        'LouisvilleKentuckyUnitedStates': 'Louisville, Kentucky, United States',
        'FresnoCaliforniaUnitedStates': 'Fresno, California, United States',
        'NewOrleansLouisianaUnitedStates': 'New Orleans, Louisiana, United States',
        'PortlandOregonUnitedStates': 'Portland, Oregon, United States',
        'SyracuseNewYorkUnitedStates': 'Syracuse, New York, United States',
        'SacramentoCaliforniaUnitedStates': 'Sacramento, California, United States',
        'LombardIllinoisUnitedStates': 'Lombard, Illinois, United States',
        'JacksonMississippiUnitedStates': 'Jackson, Mississippi, United States',
        'ArlingtonTexasUnitedStates': 'Arlington, Texas, United States',
        'PortlandMaineUnitedStates': 'Portland, Maine, United States',
        'NewBrunswickCanada': 'New Brunswick, Canada',
        'MontrealNordQuebecCanada': 'Montréal-Nord, Quebec, Canada',
        'VancouverBritishColumbiaCanada': 'Vancouver, British Columbia, Canada',
        'EtobicokeOntarioCanada': 'Etobicoke, Ontario, Canada',
        'HamiltonOntarioCanada': 'Hamilton, Ontario, Canada',
        'MississaugaOntarioCanada': 'Mississauga, Ontario, Canada',
        'SanJoseCaliforniaUnitedStates': 'San Jose, California, United States',
        'WichitaKansasUnitedStates': 'Wichita, Kansas, United States',
        'NorthbrookIllinoisUnitedStates': 'Northbrook, Illinois, United States',
        'NashvilleTennesseeUnitedStates': 'Nashville, Tennessee, United States',
        'MokenaIllinoisUnitedStates': 'Mokena, Illinois, United States',
        'OmahaNebraskaUnitedStates': 'Omaha, Nebraska, United States',
        'RochesterNewYorkUnitedStates': 'Rochester, New York, United States',
        'PensacolaFloridaUnitedStates': 'Pensacola, Florida, United States',
        'PhoenixArizonaUnitedStates': 'Phoenix, Arizona, United States',
        'TallahasseeFloridaUnitedStates': 'Tallahassee, Florida, United States',
        'AlbuquerqueNewMexicoUnitedStates': 'Albuquerque, New Mexico, United States',
        'BirminghamAlabamaUnitedStates': 'Birmingham, Alabama, United States',
        'BurlingtonVermontUnitedStates': 'Burlington, Vermont, United States',
        'LivoniaMichiganUnitedStates': 'Livonia, Michigan, United States',
        'BatonRougeLouisianaUnitedStates': 'Baton Rouge, Louisiana, United States',
        'BedfordNewHampshireUnitedStates': 'Bedford, New Hampshire, United States',
        'AtlantaGeorgiaUnitedStates': 'Atlanta, Georgia, United States',
        'BridgewaterMassachusettsUnitedStates': 'Bridgewater, Massachusetts, United States',
        'DeerfieldBeachFloridaUnitedStates': 'Deerfield Beach, Florida, United States',
        'CharlestonSouthCarolinaUnitedStates': 'Charleston, South Carolina, United States',
        'ChattanoogaTennesseeUnitedStates': 'Chattanooga, Tennessee, United States',
        'HartfordConnecticutUnitedStates': 'Hartford, Connecticut, United States',
        'HoustonTexasUnited States': 'Houston, Texas, United States',
        'IndianapolisIndianaUnitedStates': 'Indianapolis, Indiana, United States',
        'KnoxvilleTennesseeUnitedStates': 'Knoxville, Tennessee, United States',
        'SanAntonioTexasUnitedStates': 'San Antonio, Texas, United States',
        'KitchenerOntarioCanada': 'Kitchener, Ontario, Canada',
        'Montréal-NordQuebecCanada': 'Montreal-Nord, Quebec, Canada',
    },
    empty: 'No jobs found',
    error:{
        info:"We are currently performing updates to our website.",
        infoalt:"There are currently no job offers available.",
        message1:"However, this will not stop you from applying to our job offers.",
        message2:"Please send your resume and application to"
    }
}