import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useLanguage from "./useLanguage";

export const useJobDetails = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const gh_jid = searchParams.get('gh_jid');
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { language, getURL } = useLanguage();

    useEffect(() => {
        const fetchJob = async () => {
            try {
                setLoading(true);
                const url = getURL();  // Use dynamic URL based on language
                const response = await fetch(url + `jobs/${gh_jid}?content=true`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setJob(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (gh_jid) {
            fetchJob();
        }
    }, [gh_jid, language]);

    return { job, loading, error };
};
