import React, { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HeroSection from '../components/HeroSection/HeroSection.jsx';
import InformativeSection from '../components/InformativeSection/InformativeSection.jsx';
import JobsListing from '../components/jobslisting/JobsListing.jsx';

import desktopImage from '../assets/media/desktop.jpg';
import mobileImage from '../assets/media/mobile.jpg';
import FullWidthInfo from '../components/FullWidthInfo/FullWidthInfo.jsx';
import SEO from '../components/SEO/SEOComponent.jsx';

const Careers = () => {
    const { t } = useTranslation();
    const jobListingRef = useRef(null);
    const location = useLocation();

    const scrollToForm = useCallback(() => {
        jobListingRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (['category', 'location', 'department', 'keyword'].some(param => searchParams.has(param))) {
            requestAnimationFrame(scrollToForm);
        }
    }, [location, scrollToForm]);

    return (
        <>
            <SEO
                title={t('seo.careersPage.title')}
                description={t('seo.careersPage.description')}
                image="https://careers.bathfitter.com/Media/Default/careers/careers-hero.jpg"
                url="https://careers.bathfitter.com/careers"
                author={t('seo.common.author')}
                publishedAt="2024-03-21"
                type={t('seo.common.type')}
                schemaType="Organization"
                schema={{
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: t('seo.common.organization.name'),
                    alternateName: t('seo.common.organization.alternateName'),
                    description: t('seo.careersPage.schema.divisions.admin'),
                    url: 'https://careers.bathfitter.com/careers',
                    location: [
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.usa.address')
                            }
                        },
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.canada.address')
                            }
                        }
                    ],
                    department: [
                        {
                            '@type': 'Organization',
                            name: t('divisions.admin.title'),
                            description: t('seo.careersPage.schema.divisions.admin')
                        },
                        {
                            '@type': 'Organization',
                            name: t('divisions.plant.title'),
                            description: t('seo.careersPage.schema.divisions.plant')
                        },
                        {
                            '@type': 'Organization',
                            name: t('divisions.retail.title'),
                            description: t('seo.careersPage.schema.divisions.retail')
                        }
                    ],
                    jobLocation: [
                        {
                            '@type': 'Place',
                            name: t('seo.locations.usa.country'),
                            address: {
                                '@type': 'PostalAddress',
                                addressCountry: 'US',
                                addressRegion: t('seo.careersPage.schema.locations.usa')
                            }
                        },
                        {
                            '@type': 'Place',
                            name: t('seo.locations.canada.country'),
                            address: {
                                '@type': 'PostalAddress',
                                addressCountry: 'CA',
                                addressRegion: t('seo.careersPage.schema.locations.canada')
                            }
                        }
                    ],
                    benefits: {
                        '@type': 'BenefitsType',
                        benefits: [
                            t('seo.careersPage.schema.benefits.salary'),
                            t('seo.careersPage.schema.benefits.growth'),
                            t('seo.careersPage.schema.benefits.environment'),
                            t('seo.careersPage.schema.benefits.teamwork')
                        ]
                    },
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': 'https://careers.bathfitter.com/careers',
                        name: t('seo.common.organization.name'),
                        description: t('seo.careersPage.description')
                    },
                    potentialAction: {
                        '@type': 'SearchAction',
                        target: {
                            '@type': 'EntryPoint',
                            urlTemplate: 'https://careers.bathfitter.com/careers?category={category}&location={location}&department={department}'
                        },
                        'query-input': [
                            {
                                '@type': 'PropertyValueSpecification',
                                valueName: 'category',
                                valueRequired: false
                            },
                            {
                                '@type': 'PropertyValueSpecification',
                                valueName: 'location',
                                valueRequired: false
                            },
                            {
                                '@type': 'PropertyValueSpecification',
                                valueName: 'department',
                                valueRequired: false
                            }
                        ]
                    }
                }}
            />
            <HeroSection
                desktopImage={desktopImage}
                mobileImage={mobileImage}
                title={t('careersPage.weare')}
                titleHighlight={t('careersPage.hiring')}
                descriptionLine1={t('careersPage.description_line_1')}
                descriptionLine2={t('careersPage.description_line_2')}
                buttonLabel={t('careersPage.openPositions')}
                onButtonClick={scrollToForm}
            />
            <InformativeSection />
            <FullWidthInfo
                backgroundImage="https://careers.bathfitter.com/Media/Default/careers/Home-stats.jpg"
                descriptionKey="careersPage.installerApplyDescription"
                buttonLabelKey="careersPage.installerApplyButton"
                path={'/installers'}
                buttonTarget="_parent"
            />
            <JobsListing jobListingRef={jobListingRef} />
        </>
    );
};

export default Careers;
