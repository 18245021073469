import styled from 'styled-components';

// Content Section with text and CTA
export const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem 2rem;
  gap: 1rem;

  @media (max-width: 960px) {
    align-items: center;
  }
`;

// CTAs Section
export const CTAsSection = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

