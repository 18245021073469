export const pathMappings = {
  '' : {
      en: '/',
      fr: '/',
  },
  '/': {
      en: '/',
      fr: '/',
  },
  '/installers': {
      en: '/installers',
      fr: '/installateurs',
  },
  '/installateurs': {
      en: '/installers',
      fr: '/installateurs',
  },
  '/ourculture': {
      en: '/ourculture',
      fr: '/notre-culture',
  },
  '/notre-culture': {
      en: '/ourculture',
      fr: '/notre-culture',
  },
  '/whoweare': {
      en: '/whoweare',
      fr: '/qui-nous-sommes',
  },
  '/qui-nous-sommes': {
      en: '/whoweare',
      fr: '/qui-nous-sommes',
  },
  '/contact': {
      en: '/contact',
      fr: '/contact',
  },
  '/careers': {
      en: '/careers',
      fr: '/carrieres',
  },
  '/carrieres': {
      en: '/careers',
      fr: '/carrieres',
  },
  '/careers/job': {
      en: '/careers/job',
      fr: '/carrieres/emploi',
  },
  '/carrieres/emploi': {
      en: '/careers/job',
      fr: '/carrieres/emploi',
  },
  '/careers/?department=4005296008': {
      en: '/careers/?department=4005296008',
      fr: '/carrieres/?department=4005296008',
  },
  '/carrieres/?department=4005296008': {
      en: '/careers/?department=4005296008',
      fr: '/carrieres/?department=4005296008',
  },
  '/careers/#joblisting': {
      en: '/careers/#joblisting',
      fr: '/carrieres/#joblisting',
  },
  '/carrieres/#joblisting': {
      en: '/careers/#joblisting',
      fr: '/carrieres/#joblisting',
  },
  '/careers?category=Admin': {
      en: '/careers?category=Admin',
      fr: '/carrieres?category=Admin',
  },
  '/carrieres?category=Admin': {
      en: '/careers?category=Admin',
      fr: '/carrieres?category=Admin',
  },
  '/careers?category=Retail': {
      en: '/careers?category=Retail',
      fr: '/carrieres?category=Retail',
  },
  '/carrieres?category=Retail': {
      en: '/careers?category=Retail',
      fr: '/carrieres?category=Retail',
  },
  '/careers?category=Plant': {
      en: '/careers?category=Plant',
      fr: '/carrieres?category=Plant',
  },
  '/carrieres?category=Plant': {
      en: '/careers?category=Plant',
      fr: '/carrieres?category=Plant',
  },
  '/career-sitemap': {
      en: '/career-sitemap',
      fr: '/sitemap-carrieres',
  },
  '/sitemap-carrieres': {
      en: '/career-sitemap',
      fr: '/sitemap-carrieres',
  },
  '/career-installers': {
      en: '/career-installers',
      fr: '/carrieres-installateurs',
  },
  '/carrieres-installateurs': {
      en: '/career-installers',
      fr: '/carrieres-installateurs',
  },
  '/privacy-policy': {
      en: '/privacy-policy',
      fr: '/politique-de-confidentialite',
  },
  '/politique-de-confidentialite': {
      en: '/privacy-policy',
      fr: '/politique-de-confidentialite',
  },
  '/FAQ': {
      en: '/FAQ',
      fr: '/FAQ',
  },
  '/faq': {
      en: '/FAQ',
      fr: '/FAQ',
  },
};


  export const buildUrl = (pathKey, language = 'en') => {
    const langPaths = pathMappings[pathKey];
    if (!langPaths) {
      throw new Error(`Path key "${pathKey}" is not defined in pathMappings.`);
    }
  
    const path = langPaths[language];
    if (!path) {
      throw new Error(`Language "${language}" is not defined for path "${pathKey}".`);
    }
  
    return `/${language}${path}`;
  };