// DivisionsSection.styles.js
import styled from 'styled-components';

export const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  
  @media (max-width: 768px) {
    padding: 4rem 1.5rem;
  }
`;

export const Header = styled.div`
  text-align: center;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors?.primary || '#1E293B'};
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.125rem;
  line-height: 1.5;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    font-size: 1.125rem;
  }
`;

export const DivisionTitle = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors?.primary || '#1E293B'};
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

export const DivisionText = styled.p`
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors?.text || '#64748B'};
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Lists = styled.div`
  display: flex;
  gap: 3rem;
  margin-bottom: 2.5rem;

`;

export const List = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    color: ${({ theme }) => theme.colors?.text || '#64748B'};
    font-size: 0.9rem;
    line-height: 1.6;

    :empty {
    display: none !important;
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: color 0.2s ease;

      &:hover {
        color: ${({ theme }) => theme.colors?.primary || '#3B82F6'};
      }
    }
  }
`;

export const DivisionButton = styled.a`
  display: inline-block;
  background: ${({ theme }) => theme.colors?.primary || '#3B82F6'};
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: all 0.2s ease;
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    background: transparent;
    border-color: ${({ theme }) => theme.colors?.primary || '#3B82F6'};
    color: ${({ theme }) => theme.colors?.primary || '#3B82F6'};
  }
  
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 1rem;
  }
`;


export const DivisionCard = styled.div`
  display: flex;
  gap: 4rem;
  align-items: stretch; // Changed from center to stretch
  flex-direction: ${({ reversed }) => reversed ? 'row-reverse' : 'row'};
  min-height: 400px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 2rem;
    margin-bottom: 4rem;
    min-height: auto;
  }
`;

export const Content = styled.div`
  flex: 0 0 calc(50% - 2rem); // Changed to fixed width
  background: ${({ theme }) => theme.colors?.cardBg || '#FFFFFF'};
  padding: 3rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
      flex: 1;
    width: 100%;
    padding: 2rem;
  }
`;

export const ImageWrapper = styled.div`
  flex: 0 0 calc(50% - 2rem); 
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &::before {
    content: '';
    display: block;
  }

  img {
    width: 100%;
    aspect-ratio: 7 / 5;
    object-fit: contain;
    display: block;
    transition: transform 0.3s ease;

    @media (max-width: 768px) {
      position: relative;
      top: 0;
      transform: none;
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;
