import {
    JumboContainer,
    SecondaryHeading,
    Paragraph,
    Spacer,
    Grid,
    GridItem,
    Card,
    TertiaryHeading,
    SectionHeading,
} from '../common/Common.styles';
import { SalaryIcon, GrowthIcon, EarthIcon, TeamworkIcon } from '../../assets/media/icons.js';
import { useTranslation } from 'react-i18next';

export default function InformativeSection() {
    const { t } = useTranslation();

    return (
        <JumboContainer>
            <SectionHeading>{t('careersPage.InvestInYourFuture')}</SectionHeading>
            <Paragraph>{t('careersPage.InvestInYourFutureDescription')}</Paragraph>
            <Spacer />
            <Grid>
                <GridItem>
                    <Card>
                        <SalaryIcon />
                        <TertiaryHeading>{t('careersPage.card_1_title')}</TertiaryHeading>
                        <Paragraph>{t('careersPage.card_1_description')}</Paragraph>
                    </Card>
                </GridItem>
                <GridItem>
                    <Card>
                        <GrowthIcon />
                        <TertiaryHeading>{t('careersPage.card_2_title')}</TertiaryHeading>
                        <Paragraph>{t('careersPage.card_2_description')}</Paragraph>
                    </Card>
                </GridItem>
                <GridItem>
                    <Card>
                        <EarthIcon />
                        <TertiaryHeading>{t('careersPage.card_3_title')}</TertiaryHeading>
                        <Paragraph>{t('careersPage.card_3_description')}</Paragraph>
                    </Card>
                </GridItem>
                <GridItem>
                    <Card>
                        <TeamworkIcon />
                        <TertiaryHeading>{t('careersPage.card_4_title')}</TertiaryHeading>
                        <Paragraph>{t('careersPage.card_4_description')}</Paragraph>
                    </Card>
                </GridItem>
            </Grid>
        </JumboContainer>
    )
};