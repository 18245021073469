import styled from 'styled-components';

// Main container for the showcase
export const ShowcaseContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 3rem 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

// Individual item in the carousel with a fixed width of 200px
export const CarouselItem = styled.div`
  height: 220px;
  flex: none;
  padding: 0 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  outline: none;
  border: none;
`;

// Container for the slider
export const SliderContainer = styled.div`
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    overflow: hidden;
`;

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;