import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'lucide-react';
import { buildUrl } from '../../utils/urlUtils';
import {
  Container,
  Header,
  Description,
  FAQList,
  FAQItem,
  Question,
  Answer
} from './FAQSection.styles';
import useLanguage from '../../hooks/useLanguage';

const FAQSection = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);
  const { language } = useLanguage();

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: t('faq.questions.0.question'),
      answer: t('faq.questions.0.answer'),
      email: language === 'en' ? 'careers@bathfitter.com' : 'carrieres@bainmagique.com'
    },
    {
      question: t('faq.questions.1.question'),
      answer: t('faq.questions.1.answer'),
      linkText: t('faq.questions.1.linkText'), // "Check out our job list page"
      link: '/careers'
    },
    {
      question: t('faq.questions.2.question'),
      answer: t('faq.questions.2.answer')
    },
    {
      question: t('faq.questions.3.question'),
      answer: t('faq.questions.3.answer')
    },
    {
      question: t('faq.questions.4.question'),
      answer: t('faq.questions.4.answer'),
      linkText: t('faq.questions.4.linkText'), // "employment opportunities"
      link: '/careers',
      afterLink: t('faq.questions.4.afterLink')
    },
    {
      question: t('faq.questions.5.question'),
      answer: t('faq.questions.5.answer')
    },
    {
      question: t('faq.questions.6.question'),
      answer: t('faq.questions.6.answer')
    }
  ];

  const renderAnswer = (faq) => {
    if (faq.email) {
      const parts = faq.answer.split(faq.email);
      return (
        <>
          {parts[0]}
          <a href={`mailto:${faq.email}`}>{faq.email}</a>
          {parts[1]}
        </>
      );
    }

    if (faq.link && faq.linkText) {
      const parts = faq.answer.split(faq.linkText);
      return (
        <>
          {parts[0]}
          <a href={buildUrl(faq.link, language)}>{faq.linkText}</a>
          {parts[1]}
          {faq.afterLink}
        </>
      );
    }

    return faq.answer;
  };

  return (
    <Container>
      <Header>
        <Description>
          {t('faq.description')}
        </Description>
      </Header>
      
      <FAQList>
        {faqs.map((faq, index) => (
          <FAQItem key={index} index={index}>
            <Question
              onClick={() => handleToggle(index)}
              isOpen={openIndex === index}
            >
              {faq.question}
              <ChevronDown />
            </Question>
            <Answer 
              isOpen={openIndex === index}
              index={index}
            >
              <p>{renderAnswer(faq)}</p>
            </Answer>
          </FAQItem>
        ))}
      </FAQList>
    </Container>
  );
};

export default FAQSection;