import { buildUrl } from "../../utils/urlUtils"; // Ensure this is correctly imported
import { LangSwitchContainer } from "./LangSwitch.styles";

export default function LangSwitch() {

    // Get the current path and remove the language prefix if it exists
    const path = window.location.pathname;
    const slug = path
    .replace(/^\/(en|fr)/, '')
    .replace(/careers\/job.*/, 'careers')
    .replace(/carrieres\/emploi.*/, 'carrieres')
    .replace(/\/+$/, '');

    const toggleFrench = () => {
        window.location.href = buildUrl(slug, 'fr');
    };

    const toggleEnglish = () => {
        window.location.href = buildUrl(slug, 'en');
    };

    return (
        <LangSwitchContainer>
            {/* Button for French */}
            <button
                onClick={toggleFrench}
                style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'blue',
                }}
            >
                FR
            </button>

            <span style={{ color: 'white', margin: '0 0.5rem' }}>|</span>

            {/* Button for English */}
            <button
                onClick={toggleEnglish}
                style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'blue',
                }}
            >
                EN
            </button>
        </LangSwitchContainer>
    );
}
