import HeroSection from "../components/HeroSection/HeroSection";
import MediaContent from "../components/MediaContent/MediaContent";
import TestimonialSection from "../components/TestimonialSection/TestimonialSection";
import { buildUrl } from "../utils/urlUtils";
import useLanguage from "../hooks/useLanguage";
import { useTranslation } from "react-i18next";

import desktopImage from '../assets/media/whoweare.png';
import bathfitter from '../assets/media/bathfitter-place.png';
import bathfitter2 from '../assets/media/bathfitter-place-2.png';
import Person1 from '../assets/media/Division-bethany-t.png';
import Person2 from '../assets/media/division-yannick-l.png';
import Person3 from '../assets/media/MariaMeguiar.png';
import DivisionsSection from "../components/DivisionsSection/DivisionsSection";
import SEO from "../components/SEO/SEOComponent";
import InformativeImageCarousel from "../components/InformativeImageCarousel/InformativeImageCarousel";

const slides = [
    {
        image: bathfitter,
    },
    {
        image: bathfitter2,
    },
];

export default function WhoWeAre() {
    const { language } = useLanguage();
    const { t } = useTranslation();

    const testimonials = [
        {
            quote: t('testimonial.testimonial7Quote'),
            author: t('testimonial.testimonial7Author'),
            role: t('testimonial.testimonial7Role'),
            image: Person1,
        },
        {
            quote: t('testimonial.testimonial8Quote'),
            author: t('testimonial.testimonial8Author'),
            role: t('testimonial.testimonial8Role'),
            image: Person2,
        },
        {
            quote: t('testimonial.testimonial9Quote'),
            author: t('testimonial.testimonial9Author'),
            role: t('testimonial.testimonial9Role'),
            image: Person3,
        },
    ];

    return (
        <>
            <SEO
                title={t('seo.whoWeArePage.title')}
                description={t('seo.whoWeArePage.description')}
                image="https://careers.bathfitter.com/Media/Default/careers/who-we-are-hero.jpg"
                url="https://careers.bathfitter.com/who-we-are"
                author={t('seo.common.author')}
                publishedAt="2024-03-21"
                type={t('seo.common.type')}
                schemaType="Organization"
                schema={{
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: t('seo.common.organization.name'),
                    alternateName: t('seo.common.organization.alternateName'),
                    description: t('whowearePage.mediaContentDescription'),
                    url: 'https://careers.bathfitter.com/who-we-are',
                    foundingDate: '1984',
                    numberOfEmployees: {
                        '@type': 'QuantitativeValue',
                        value: '230+',
                        unitText: 'retail locations'
                    },
                    location: [
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.usa.address')
                            }
                        },
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.canada.address')
                            }
                        }
                    ],
                    department: [
                        {
                            '@type': 'Organization',
                            name: t('divisions.admin.title'),
                            description: t('divisions.admin.description'),
                        },
                        {
                            '@type': 'Organization',
                            name: t('divisions.plant.title'),
                            description: t('divisions.plant.description'),
                        },
                        {
                            '@type': 'Organization',
                            name: t('divisions.retail.title'),
                            description: t('divisions.retail.description'),
                        }
                    ],
                    contactPoint: {
                        '@type': 'ContactPoint',
                        email: t('seo.common.contactEmail'),
                        contactType: t('careers.careersInformation')
                    },
                    review: [
                        {
                            '@type': 'Review',
                            author: {
                                '@type': 'Person',
                                name: t('testimonial.testimonial1Author')
                            },
                            reviewBody: t('testimonial.testimonial1Quote'),
                            position: t('testimonial.testimonial1Role')
                        },
                        {
                            '@type': 'Review',
                            author: {
                                '@type': 'Person',
                                name: t('testimonial.testimonial2Author')
                            },
                            reviewBody: t('testimonial.testimonial2Quote'),
                            position: t('testimonial.testimonial2Role')
                        },
                        {
                            '@type': 'Review',
                            author: {
                                '@type': 'Person',
                                name: t('testimonial.testimonial3Author')
                            },
                            reviewBody: t('testimonial.testimonial3Quote'),
                            position: t('testimonial.testimonial3Role')
                        }
                    ],
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': 'https://careers.bathfitter.com/who-we-are',
                        name: t('seo.common.organization.name'),
                        description: t('divisions.mainDescription')
                    }
                }}
            />
            <HeroSection
                desktopImage={desktopImage}
                mobileImage={desktopImage}
                title={t('whowearePage.heroTitle')}
                descriptionLine1={t('whowearePage.heroDescriptionLine1')}
            />
             <InformativeImageCarousel
                slides={slides}
                title={t('whowearePage.mediaContentTitle')}
                description={t('whowearePage.mediaContentDescription')}
                ctaLabel={t('whowearePage.mediaContentCtaLabel')}
                onCtaClick={() => window.location.href = buildUrl('/careers', language)}
            />
            <DivisionsSection />
            <TestimonialSection testimonials={testimonials} />
        </>
    )
}