import { useParams } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

// A wrapper component to extract language from the route params
export const LanguageWrapper = ({ Component }) => {
  const { lang } = useParams(); // Get the language from the URL path
  const { language } = useLanguage(); // Access the language hook

  let currentLang = lang;

  // If lang is not present, search the URL for /en or /fr
  if (currentLang !== "en" || currentLang !== "fr" || !currentLang) {
    const path = window.location.pathname;
    if (path.includes("/en")) {
      currentLang = "en";
    } else if (path.includes("/fr")) {
      currentLang = "fr";
    } else {
      currentLang = "en"; // Default to 'en' if neither is found
    }
  }

  // Update the hook's language state if it doesn't match the path
  if (language !== currentLang) {
    window.history.pushState({}, "", `/${currentLang}`);
    window.location.reload();
  }

  return <Component />;
};
