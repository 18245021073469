import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import {LanguageWrapper} from "./utils/langUtils";

import Careers from "./pages/Careers";
import JobDetails from "./pages/JobDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import NotFound from "./pages/NotFound";
import HomePage from "./pages/HomePage";
import OurCulture from "./pages/OurCulture";
import WhoWeAre from "./pages/WhoWeAre";
import Contact from "./pages/Contact";
import FAQPage from "./pages/FAQPage";
import ScrollUpButton from "./components/ScrollUpButton/ScrollUpButton";
import Installers from "./pages/Installers";

export default function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* English routes */}
        <Route path="/" element={<Navigate to="/en/" />} />
        <Route path="/en" element={<LanguageWrapper Component={HomePage} />} />
        <Route path="/en/ourculture" element={<LanguageWrapper Component={OurCulture} />} />
        <Route path="/en/whoweare" element={<LanguageWrapper Component={WhoWeAre} />} />
        <Route path="/en/contact" element={<LanguageWrapper Component={Contact} />} />
        <Route path="/en/careers" element={<LanguageWrapper Component={Careers} />} />
        <Route path="/en/careers/job" element={<LanguageWrapper Component={JobDetails} />} />
        <Route path="/en/privacy-policy" element={<LanguageWrapper Component={PrivacyPolicy} />} />
        <Route path="/en/FAQ" element={<LanguageWrapper Component={FAQPage} />} />
        <Route path="/en/installers" element={<LanguageWrapper Component={Installers} />} />

        {/* French routes */}
        <Route path="/fr" element={<LanguageWrapper Component={HomePage} />} />
        <Route path="/fr/notre-culture" element={<LanguageWrapper Component={OurCulture} />} />
        <Route path="/fr/qui-nous-sommes" element={<LanguageWrapper Component={WhoWeAre} />} />
        <Route path="/fr/contact" element={<LanguageWrapper Component={Contact} />} />
        <Route path="/fr/carrieres" element={<LanguageWrapper Component={Careers} />} />
        <Route path="/fr/carrieres/emploi" element={<LanguageWrapper Component={JobDetails} />} />
        <Route path="/fr/politique-de-confidentialite" element={<LanguageWrapper Component={PrivacyPolicy} />} />
        <Route path="/fr/FAQ" element={<LanguageWrapper Component={FAQPage} />} />
        <Route path="/fr/installateurs" element={<LanguageWrapper Component={Installers} />} />

        {/* Redirect unknown paths to default route */}
        <Route path="*" element={<NotFound /> } />
      </Routes>
      <ScrollUpButton />
      <Footer />
    </Router>
  );
}
