import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuoteContainer, Paragraph, SecondaryButton, Spacer } from '../common/Common.styles';
import useLanguage from '../../hooks/useLanguage';
import { buildUrl } from '../../utils/urlUtils';

const FullWidthInfo = ({
  backgroundImage,
  descriptionKey,
  buttonLabelKey,
  path,
  buttonTarget = '_self',
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <QuoteContainer $imgurl={backgroundImage}>
      <Paragraph style={{ maxWidth: '765px' }}>
        {t(descriptionKey)}
      </Paragraph>

      {buttonLabelKey && (
        <>
          <Spacer />
          <a href={buildUrl(path, language)} target={buttonTarget} rel="noopener noreferrer">
            <SecondaryButton>{t(buttonLabelKey)}</SecondaryButton>
          </a>
        </>
      )}
    </QuoteContainer>
  );
};

export default FullWidthInfo;
