import { useState, useEffect } from 'react';
import useLanguage from './useLanguage';

export const useJobsList = () => {
    const [Jobs, setJobsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { language, getURL } = useLanguage();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                setLoading(true);
                const url = getURL(); // Use dynamic URL based on language
                const response = await fetch(url + 'jobs?content=true');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setJobsList(data.jobs);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchJobs();
    }, [language]);

    return { Jobs, loading, error };
};
