import {
    GridItem,
    TertiaryHeading,
    SectionHeading,
    Spacer,
} from '../common/Common.styles.js';
import { BenefitsCard, BenefitsContainer, Container } from './Benefits.styles';
import {BenefitsIcon, TrainingIcon, BriefcaseIcon, CheckIcon } from '../../assets/media/icons.js';
import { useTranslation } from 'react-i18next';

export default function BenefitsSection() {
    const { t } = useTranslation();

    return (
        <Container>  
        <SectionHeading>{t('installers.benefitsSectionTitle')}</SectionHeading>
            <BenefitsContainer>
                <GridItem>
                    <BenefitsCard>
                        <BriefcaseIcon />
                        <TertiaryHeading>{t('installers.benefitsCard1Title')}</TertiaryHeading>
                    </BenefitsCard>
                </GridItem>
                <GridItem>
                    <BenefitsCard>
                    <BenefitsIcon />
                        <TertiaryHeading>{t('installers.benefitsCard2Title')}</TertiaryHeading>
                    </BenefitsCard>
                </GridItem>
                <GridItem>
                    <BenefitsCard>
                        <TrainingIcon />
                        <TertiaryHeading>{t('installers.benefitsCard3Title')}</TertiaryHeading>
                    </BenefitsCard>
                </GridItem>
                <GridItem>
                    <BenefitsCard>
                        <CheckIcon />
                        <TertiaryHeading>{t('installers.benefitsCard4Title')}</TertiaryHeading>
                    </BenefitsCard>
                </GridItem>
            </BenefitsContainer>
            </Container>
    )
};