// MediaContent.styles.js

import styled from 'styled-components';

// Main container for media and content
export const MediaContentContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mediaLayout === 'right' ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 3rem 2rem;
  gap: 4rem;

  @media (max-width: 960px) {
    flex-direction: column;
    text-align: center;
    gap: 0;
  }
`;

// Media Section (Video or Image)
export const MediaSection = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  video, img {
    max-width: 100%;
    border-radius: 2px;
    object-fit: cover;
  }

  img {
   aspect-ratio: 16 / 9;
  }
`;

// Content Section with text and CTA
export const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;

  @media (max-width: 960px) {
    align-items: center;
  }
`;
