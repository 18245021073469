// index.js
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import i18n, { initializeI18n } from './assets/translation/i18n';
import useLanguage  from './hooks/useLanguage';

import "./assets/styles/brand.css";
import "./assets/styles/fonts.css";
import "./assets/styles/reset.css";

// Wrapper component to initialize i18n and handle loading state
const RootComponent = () => {
  const { language } = useLanguage(); // Get the language from the hook
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize i18n when the language is ready
  useEffect(() => {
    initializeI18n(language);
    setIsInitialized(true); // Mark i18n as initialized
  }, [language]);

  if (!isInitialized) {
    // Optionally show a loading spinner until i18n is initialized
    return <div>Loading...</div>;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </I18nextProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

reportWebVitals();
