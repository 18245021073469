import React, { useState, useCallback, useMemo } from 'react';
import { Container, Flex, SecondaryHeading, Spacer } from "../common/Common.styles";
import JobsFilters from "./JobsFilters";
import { JobsList } from "./JobsList";
import { useJobsList } from '../../hooks/useJobsList';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Category mappings for department groupings
const categoryMappings = {
    Admin: ['4005227008', '4005228008', '4005229008'],
    Plant: ['4005230008'],
    Retail: ['4005231008']
};

// Helper function to get category by ID
const getCategory = (categoryId) => {
    const categoryIdStr = categoryId?.toString();
    const foundKey = Object.entries(categoryMappings).find(([key, ids]) =>
        ids.includes(categoryIdStr)
    );
    return foundKey ? `resources.categories.${foundKey[0]}` : 'resources.categories.unknown';
};

export default function JobsListings({ jobListingRef }) {
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const keyword = queryParams.get('keyword') || '';

    const { Jobs, loading, error } = useJobsList();
    const { t } = useTranslation();

    const [filters, setFilters] = useState({
        category: '',
        department: '',
        location: ''
    });

    // Memoized function to handle filter changes
    const handleFilterChange = useCallback((filterType, value) => {
        const firstValue = value.split(',')[0].trim(); // Take the first number before the comma as they are all the same 
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: filterType === 'department' ? firstValue : value
        }));
    }, []);



    // Render the component
    return (
        <>
            <Spacer ref={jobListingRef} />
            <Flex id="joblisting">
                <Container>
                    <SecondaryHeading>{t('careersPage.yourCareer')}</SecondaryHeading>
                </Container>
            </Flex>

            <JobsFilters
                Jobs={Jobs}
                handleFilterChange={handleFilterChange}
                currentFilters={filters}
                categoryMappings={categoryMappings}
            />

            {error ? (
                <Container>
                    <p>{t('careersPage.errorLoadingJobs')}</p>
                </Container>
            ) : (
                <JobsList
                    Jobs={Jobs}
                    loading={loading}
                    error={error}
                    filters={filters}
                    getCategory={getCategory}
                    keyword={keyword}
                />
            )}

            <Spacer />
            <Spacer />
        </>
    );
}
