import HeroSection from "../components/HeroSection/HeroSection";
import MediaContent from "../components/MediaContent/MediaContent";
import TestimonialSection from "../components/TestimonialSection/TestimonialSection";
import ValuesSection from "../components/ValuesSection/ValuesSection";
import FullWidthInfo from "../components/FullWidthInfo/FullWidthInfo";
import InformativeImageCarousel from "../components/InformativeImageCarousel/InformativeImageCarousel";
import { buildUrl } from "../utils/urlUtils";
import useLanguage from "../hooks/useLanguage";
import { useTranslation } from "react-i18next";

import desktopImage from "../assets/media/CultureHero.png";
import safety from "../assets/media/safety.png";
import Person2 from '../assets/media/Culture-christian-c.png';
import Person3 from '../assets/media/Culture-sylvie-l.png';
import culture1 from '../assets/media/Culture-S4-1.jpg';
import culture2 from '../assets/media/Culture-S4-2.jpg';
import culture3 from '../assets/media/Culture-S4-3.jpg';
import culture4 from '../assets/media/Culture-S4-4.jpg';
import culture5 from '../assets/media/Culture-S4-5.jpg';
import culture6 from '../assets/media/Culture-S4-6.jpg';
import CTASection from "../components/CTASection/CTASection";
import SEO from "../components/SEO/SEOComponent";



export default function OurCulture() {
    const { language } = useLanguage();
    const { t } = useTranslation();

    const testimonials = [
        {
            quote: t('testimonial.testimonial5Quote'),
            author: t('testimonial.testimonial5Author'),
            role: t('testimonial.testimonial5Role'),
            image: Person2,
        },
        {
            quote: t('testimonial.testimonial6Quote'),
            author: t('testimonial.testimonial6Author'),
            role: t('testimonial.testimonial6Role'),
            image: Person3,
        },
    ];

    const slides = [
        {
            image: culture1,
        },
        {
            image: culture2,
        },
        {
            image: culture3,
        },
        {
            image: culture4,
        },
        {
            image: culture5,
        },
        {
            image: culture6,
        },
    ];

    return (
        <>
            <SEO
                title={t('seo.culturePage.title')}
                description={t('seo.culturePage.description')}
                image="https://careers.bathfitter.com/Media/Default/careers/culture-hero.jpg"
                url="https://careers.bathfitter.com/culture"
                author={t('seo.common.author')}
                publishedAt="2024-03-21"
                type={t('seo.common.type')}
                schemaType="Organization"
                schema={{
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: t('seo.common.organization.name'),
                    alternateName: t('seo.common.organization.alternateName'),
                    description: t('seo.culturePage.schema.missionStatement'),
                    url: 'https://careers.bathfitter.com/culture',
                    location: [
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.usa.address')
                            }
                        },
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.canada.address')
                            }
                        }
                    ],
                    mission: t('seo.culturePage.schema.missionStatement'),
                    slogan: t('seo.culturePage.schema.visionStatement'),
                    contactPoint: {
                        '@type': 'ContactPoint',
                        email: t('seo.common.contactEmail'),
                        contactType: t('careers.careersInformation')
                    },
                    review: [
                        {
                            '@type': 'Review',
                            author: {
                                '@type': 'Person',
                                name: t('testimonial.testimonial1Author')
                            },
                            reviewBody: t('testimonial.testimonial1Quote'),
                            position: t('testimonial.testimonial1Role')
                        },
                        {
                            '@type': 'Review',
                            author: {
                                '@type': 'Person',
                                name: t('testimonial.testimonial2Author')
                            },
                            reviewBody: t('testimonial.testimonial2Quote'),
                            position: t('testimonial.testimonial2Role')
                        },
                        {
                            '@type': 'Review',
                            author: {
                                '@type': 'Person',
                                name: t('testimonial.testimonial3Author')
                            },
                            reviewBody: t('testimonial.testimonial3Quote'),
                            position: t('testimonial.testimonial3Role')
                        }
                    ],
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': 'https://careers.bathfitter.com/culture',
                        name: t('seo.common.organization.name'),
                        description: t('seo.culturePage.schema.valuesStatement')
                    }
                }}
            />
            <HeroSection
                desktopImage={desktopImage}
                mobileImage={desktopImage}
                title={t('culturePage.heroTitle')}
                descriptionLine1={t('culturePage.heroDescriptionLine1')}
            />

            <MediaContent
                mediaLayout="right"
                mediaSrc={language === 'en' ? "https://player.vimeo.com/video/403403701" : 'https://player.vimeo.com/video/403738439'}
                mediaType="video"
                title={t('culturePage.ourCulture')}
                description={t('culturePage.ourCultureDescription')}
                ctaLabel={t('culturePage.ourCultureCta')}
                onCtaClick={() => window.location.href = buildUrl('/careers', language)}
            />


            <ValuesSection />

            <MediaContent
                mediaLayout="left"
                mediaSrc={safety}
                mediaType="image"
                title={t('culturePage.safety')}
                description={t('culturePage.safetyDescription')}
                ctaLabel={t('culturePage.safetyCta')}
                onCtaClick={() => window.location.href = buildUrl('/whoweare', language)}
            />

            <FullWidthInfo
                backgroundImage="https://careers.bathfitter.com/Media/Default/careers/Home-stats.jpg"
                descriptionKey="culturePage.applyDescription"
                buttonLabelKey="culturePage.applyCta"
                path="/careers/#joblisting"
                buttonTarget="_parent"
            />

            <InformativeImageCarousel
                slides={slides}
                title={t('culturePage.infoCarouselTitle')}
                description={t('culturePage.infoCarouselDescription')}
            />

            <TestimonialSection testimonials={testimonials} />

            <CTASection
                title={t('contactPage.questions')}
                description={t('contactPage.faqDesc')}
                ctaLabel={t('contactPage.faq')}
                onCtaClick={() => window.location.href = buildUrl('/FAQ', language)}
            />
        </>
    );
}