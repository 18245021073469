export const SalaryIcon = () => (
    <svg  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24">
        <path d="M17 9V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"></path>
        <path d="M9 19h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2Zm0 0h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H9a2 2 0 0 0-1.414.586"></path>
        <path d="M15.414 15.414a2 2 0 1 0-2.828-2.828 2 2 0 0 0 2.828 2.828Z"></path>
    </svg>
);

export const GrowthIcon = () => (
    <svg  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24">
        <path d="M13 7h8m0 0v8m0-8-8 8-4-4-6 6"></path>
    </svg>
);

export const EarthIcon = () => (
    <svg  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24">
        <path d="M3.055 11H5a2 2 0 0 1 2 2v1a2 2 0 0 0 2 2 2 2 0 0 1 2 2v2.945"></path>
        <path d="M8 3.935V5.5A2.5 2.5 0 0 0 10.5 8h.5a2 2 0 0 1 2 2 2 2 0 1 0 4 0 2 2 0 0 1 2-2h1.064"></path>
        <path d="M15 20.488V18a2 2 0 0 1 2-2h3.064"></path>
        <path d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
    </svg>
);

export const TeamworkIcon = () => (
    <svg  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24">
        <path d="M18 9v3-3Zm0 3v3-3Zm0 0h3-3Zm0 0h-3 3Zm-5-5a4 4 0 1 1-8 0 4 4 0 0 1 8 0v0ZM3 20a6 6 0 1 1 12 0v1H3v-1Z"></path>
    </svg>
);

export const BackIcon = () => (
    <svg fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 12H5"></path>
        <path d="m12 19-7-7 7-7"></path>
    </svg>
);

export const StarIcon = () => (
    <svg fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.951 2.927c-.299-.921-1.602-.921-1.902 0L9.53 7.601a1 1 0 0 1-.951.69H3.665c-.968 0-1.372 1.24-.588 1.81l3.976 2.888a1 1 0 0 1 .363 1.118l-1.518 4.674c-.3.921.755 1.688 1.538 1.118l3.976-2.888a1 1 0 0 1 1.176 0l3.976 2.888c.783.57 1.838-.196 1.538-1.118l-1.518-4.674a1 1 0 0 1 .363-1.118l3.976-2.888c.783-.57.381-1.81-.588-1.81H15.42a1 1 0 0 1-.95-.69l-1.519-4.674Z"></path>
    </svg>
);

export const SunIcon = () => (
    <svg fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364-.707-.707M6.343 6.343l-.707-.707m12.728 0-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"></path>
    </svg>
);

export const HeartIcon = () => (
    <svg fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.343 7.778a4.5 4.5 0 0 1 7.339-1.46L12 7.636l1.318-1.318a4.5 4.5 0 1 1 6.364 6.364L12 20.364l-7.682-7.682a4.501 4.501 0 0 1-.975-4.904Z"></path>
    </svg>
);

export const BenefitsIcon = () => (
    <svg fill="none" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M12 14.25A5.625 5.625 0 1 0 12 3a5.625 5.625 0 0 0 0 11.25Z"></path>
<path d="M16.125 21a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25Z"></path>
<path d="M7.875 21a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25Z"></path>
</svg>
);

export const BriefcaseIcon = () => (
    <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.25 6H3.75A2.25 2.25 0 0 0 1.5 8.25v10.5A2.25 2.25 0 0 0 3.75 21h16.5a2.25 2.25 0 0 0 2.25-2.25V8.25A2.25 2.25 0 0 0 20.25 6Z"></path>
    <path d="M6.75 6V4.5A1.5 1.5 0 0 1 8.25 3h7.5a1.5 1.5 0 0 1 1.5 1.5V6"></path>
    <path d="M22.5 11.25h-21"></path>
    <path d="M15 11.25v1.125a.375.375 0 0 1-.375.375h-5.25A.375.375 0 0 1 9 12.375V11.25"></path>
    </svg>
);

export const TrainingIcon = () => (
    <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 7.5c.75-2.96 3.583-4.472 9.75-4.5a.747.747 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75c-6 0-8.318 1.21-9.75 3-1.424-1.781-3.75-3-9.75-3-.463 0-.75-.377-.75-.84V3.75A.747.747 0 0 1 2.25 3c6.167.028 9 1.54 9.75 4.5Z"></path>
    <path d="M12 7.5V21"></path>
    </svg>
);

export const CheckIcon = () => (
    <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.75 9.938V21a.75.75 0 0 0 .75.75H9v-6.375a1.125 1.125 0 0 1 1.125-1.125h3.75A1.125 1.125 0 0 1 15 15.375v6.375h4.5a.75.75 0 0 0 .75-.75V9.937"></path>
    <path d="m22.5 12-9.99-9.563c-.234-.248-.782-.25-1.02 0L1.5 11.999"></path>
    <path d="M18.75 8.39V3H16.5v3.234"></path>
    </svg>
);