import React, { useEffect, useRef, useState } from 'react';
import { TimelineBanner, SlideWrapper, CarouselSlide, TopContent, Title, Divider, ReasonItem, ReasonContainer, ReasonImage, ImageContent, ReasonImg, ImageProgress, ImageBackground, ReasonInfo, ReasonNumber, NumberContent, NumberImg, NumberProgress, NumberBackground, TextContainer, ReasonTitle, ReasonDescription, TopLink} from './ReasonsToWork.styles';

import Reason1 from '../../assets/media/reason_1_transparent.png';
import Reason2 from '../../assets/media/reason_2_transparent.png';
import Reason3 from '../../assets/media/reason_3_transparent.png';
import Reason4 from '../../assets/media/reason_4_transparent.png';
import Reason5 from '../../assets/media/reason_5_transparent.png';
import Number1 from '../../assets/media/n01_transparent.png';
import Number2 from '../../assets/media/n02_transparent.png';
import Number3 from '../../assets/media/n03_transparent.png';
import Number4 from '../../assets/media/n04_transparent.png';
import Number5 from '../../assets/media/n05_transparent.png';
import { useTranslation } from 'react-i18next';
import { buildUrl } from '../../utils/urlUtils';
import useLanguage from '../../hooks/useLanguage';

const ReasonsToWorkDesktop = () => {
    const [reasonProgress, setReasonProgress] = useState({});
    const reasonRefs = useRef([]);
    const { t } = useTranslation();
    const { language } = useLanguage();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const progress = entry.intersectionRatio;
                    setReasonProgress(prev => ({
                        ...prev,
                        [entry.target.dataset.index]: progress
                    }));
                });
            },
            { threshold: Array.from({ length: 101 }, (_, i) => i / 100) }
        );

        reasonRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            reasonRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    const getRevealStyle = (index) => ({
        backgroundImage: `linear-gradient(to bottom, var(--color-green)${reasonProgress[index] * 100}%, transparent ${reasonProgress[index] * 100}%)`,
        transition: 'background-image 0.1s ease-out'
    });

    return (
        <TimelineBanner>
            <SlideWrapper>
                <CarouselSlide>
                    <TopContent>
                    <Title>{t('reasonsToWork.title')}</Title>
                    </TopContent>
                    <ReasonItem>
                        <ReasonContainer ref={el => reasonRefs.current[0] = el} data-index="0">
                            <ReasonImage>
                                <ImageContent style={getRevealStyle('1')}>
                                    <ReasonImg src={Reason1} alt="Rocket" />
                                </ImageContent>
                                <ImageProgress />
                                <ImageBackground />
                            </ReasonImage>
                            <ReasonInfo>
                                <ReasonNumber>
                                    <NumberContent style={getRevealStyle('1')}>
                                        <NumberImg src={Number1} alt="Number 1" />
                                    </NumberContent>
                                    <NumberProgress />
                                    <NumberBackground />
                                </ReasonNumber>
                                <TextContainer>
                                <ReasonTitle>{t('reasonsToWork.reasons.0.title')}</ReasonTitle>
                                    <ReasonDescription>
                                        {t('reasonsToWork.reasons.0.description')}
                                    </ReasonDescription>
                                </TextContainer>
                            </ReasonInfo>
                        </ReasonContainer>
                    </ReasonItem>
                    <ReasonItem>
                        <ReasonContainer left="-175px" leftMedium="-100px" leftSmall="-75px" ref={el => reasonRefs.current[1] = el} data-index="1">
                            <ReasonImage float="right">
                                <ImageContent left="28.5px" style={getRevealStyle('2')}>
                                    <ReasonImg src={Reason2} alt="Handshake" />
                                </ImageContent>
                                <ImageProgress left="28.5px" width="178px" background="#24b39c" />
                                <ImageBackground left="28.5px" width="178px" />
                            </ReasonImage>
                            <ReasonInfo float="left">
                                <ReasonNumber float="right" left="-155px" leftLarge="-108.33px" leftMedium="-75px" leftSmall="-50px">
                                    <NumberContent style={getRevealStyle('2')}>
                                        <NumberImg src={Number2} alt="Number 2" />
                                    </NumberContent>
                                    <NumberProgress background="#24b39c" />
                                    <NumberBackground />
                                </ReasonNumber>
                                <TextContainer clear="right" float="right" align="right">
                                <ReasonTitle>{t('reasonsToWork.reasons.1.title')}</ReasonTitle>
                                    <ReasonDescription>
                                        {t('reasonsToWork.reasons.1.description')}
                                    </ReasonDescription>  
                                    <TopLink href={buildUrl('/ourculture', language)}>{t('reasonsToWork.reasons.1.link')}</TopLink>
                                </TextContainer>
                            </ReasonInfo>
                        </ReasonContainer>
                    </ReasonItem>
                    <ReasonItem>
                        <ReasonContainer ref={el => reasonRefs.current[2] = el} data-index="2">
                            <ReasonImage>
                                <ImageContent left="33.5px" style={getRevealStyle('3')}>
                                    <ReasonImg src={Reason3} alt="Recognition" />
                                </ImageContent>
                                <ImageProgress left="33.5px" width="168px" background="#59bc74" />
                                <ImageBackground left="33.5px" width="168px" />
                            </ReasonImage>
                            <ReasonInfo>
                                <ReasonNumber>
                                    <NumberContent style={getRevealStyle('3')}>
                                        <NumberImg src={Number3} alt="Number 3" />
                                    </NumberContent>
                                    <NumberProgress background="#59bc74" />
                                    <NumberBackground />
                                </ReasonNumber>
                                <TextContainer>
                                <ReasonTitle>{t('reasonsToWork.reasons.2.title')}</ReasonTitle>
                                    <ReasonDescription>
                                        {t('reasonsToWork.reasons.2.description')}
                                    </ReasonDescription>
                                </TextContainer>
                            </ReasonInfo>
                        </ReasonContainer>
                    </ReasonItem>
                    <ReasonItem>
                        <ReasonContainer left="-175px" leftMedium="-100px" leftSmall="-75px" ref={el => reasonRefs.current[3] = el} data-index="3">
                            <ReasonImage float="right">
                                <ImageContent left="45.5px" style={getRevealStyle('4')}>
                                    <ReasonImg src={Reason4} alt="Heart" />
                                </ImageContent>
                                <ImageProgress left="45.5px" width="144px" background="#7dc352" />
                                <ImageBackground left="45.5px" width="144px" />
                            </ReasonImage>
                            <ReasonInfo float="left">
                                <ReasonNumber float="right" left="-155px" leftLarge="-108.33px" leftMedium="-75px" leftSmall="-50px">
                                    <NumberContent style={getRevealStyle('4')}>
                                        <NumberImg src={Number4} alt="Number 4" />
                                    </NumberContent>
                                    <NumberProgress background="#7dc352" />
                                    <NumberBackground />
                                </ReasonNumber>
                                <TextContainer clear="right" float="right" align="right">
                                <ReasonTitle>{t('reasonsToWork.reasons.3.title')}</ReasonTitle>
                                    <ReasonDescription>
                                        {t('reasonsToWork.reasons.3.description')}
                                    </ReasonDescription>
                                </TextContainer>
                            </ReasonInfo>
                        </ReasonContainer>
                    </ReasonItem>
                    <ReasonItem isLast>
                        <ReasonContainer ref={el => reasonRefs.current[4] = el} data-index="4">
                            <ReasonImage>
                                <ImageContent left="32.5px" style={getRevealStyle('5')}>
                                    <ReasonImg src={Reason5} alt="Community" />
                                </ImageContent>
                                <ImageProgress left="32.5px" width="170px" background="var(--color-green)" />
                                <ImageBackground left="32.5px" width="170px" />
                            </ReasonImage>
                            <ReasonInfo>
                                <ReasonNumber>
                                    <NumberContent style={getRevealStyle('5')}>
                                        <NumberImg src={Number5} alt="Number 5" />
                                    </NumberContent>
                                    <NumberProgress background="var(--color-green)" />
                                    <NumberBackground />
                                </ReasonNumber>
                                <TextContainer>
                                <ReasonTitle>{t('reasonsToWork.reasons.4.title')}</ReasonTitle>
                                    <ReasonDescription>
                                        {t('reasonsToWork.reasons.4.description')}
                                    </ReasonDescription>
                                </TextContainer>
                            </ReasonInfo>
                        </ReasonContainer>
                    </ReasonItem>
                    <ReasonItem>
                    <ReasonContainer style={{padding:"100px", position:"absolute"}} ref={el => reasonRefs.current[5] = el} data-index="5"> 
                    </ReasonContainer>
                    </ReasonItem>
                </CarouselSlide>
            </SlideWrapper>
        </TimelineBanner>
    );
};

export default ReasonsToWorkDesktop;