import React, { useState, useEffect } from 'react';
import { CarouselContainer, Slide, DotsContainer, Dot, Testimonial, TestimonialText, TestimonialName, TestimonialImage, TestimonialAuthor, TestimonialRole, QuoteImage} from './TestimonialSection.styles';
import BackgroundImage from '../../assets/media/Home-test-wall.png';
import QuoteIcon from '../../assets/media/testi-quota.png';

const HeroCarousel = ({ testimonials }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => setCurrentIndex(index);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 8000);
        return () => clearInterval(interval);
    }, [testimonials.length]);

    return (
        <CarouselContainer>
            {testimonials.map((testimonial, index) => (
                <Slide
                    key={index}
                    className={index === currentIndex ? 'active' : ''}
                    $imgurl={BackgroundImage}
                    $imgurlmobile={testimonial.mobileImage}
                >
                    <Testimonial>
                        <QuoteImage src={QuoteIcon} alt="Quote" />
                        <TestimonialText>
                            {testimonial.quote}
                        </TestimonialText>
                        <TestimonialAuthor>
                            <TestimonialImage src={testimonial.image} alt={testimonial.author} />
                            <div>   <TestimonialName>
                                {testimonial.author}
                            </TestimonialName>
                                <TestimonialRole>
                                    {testimonial.role}
                                </TestimonialRole>
                            </div>

                        </TestimonialAuthor>
                    </Testimonial>
                </Slide>
            ))}

            <DotsContainer>
                {testimonials.map((_, index) => (
                    <Dot
                        key={index}
                        active={index === currentIndex}
                        onClick={() => goToSlide(index)}
                    />
                ))}
            </DotsContainer>
        </CarouselContainer>
    );
};

export default HeroCarousel;
