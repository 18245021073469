import { useTranslation } from 'react-i18next';
import useLanguage from '../../hooks/useLanguage';
import { Paragraph, SectionHeading } from '../common/Common.styles';
import { Container, ContentSection, MapSection, ContactLink, ContactLinks } from './ContactSection.styles';
import MapComponent from './Map';


export default function ContactSection() {
    const { language } = useLanguage();
    const {t} = useTranslation();

    return (
        <Container>
            <ContentSection>
                <SectionHeading>{t('contactPage.sectionTitle')}</SectionHeading>
                <Paragraph>{t('contactPage.sectionDescription')}</Paragraph>
                <Paragraph>{t('contactPage.sectionDescription2')}</Paragraph>
                <ContactLinks>
                    <ContactLink>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.364 17.364 12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0ZM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                        </svg>
                        <a href={'https://maps.app.goo.gl/sBSUPDKKwCdFvG7TA'}>
                            {'102 Evergreen Dr, Springfield, TN 37172, USA'}
                        </a>
                    </ContactLink>
                    <ContactLink>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.364 17.364 12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0ZM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                        </svg>
                        <a href={'https://maps.app.goo.gl/6LaLywqkWRozKsLs5'}>
                            {'225 Rue Roy, Saint-Eustache, QC J7R 5R5, Canada'}
                        </a>
                    </ContactLink>
                    <ContactLink>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm17 4.238-7.928 7.1L4 7.216V19h16V7.238ZM4.511 5l7.55 6.662L19.502 5H4.511Z"></path>
                        </svg>
                        {
                            language === 'en' ? <a href="mailto:careers@bathfitter.com">careers@bathfitter.com</a> : <a href="mailto:carrieres@bainmagique.com">carrieres@bainmagique.com</a>
                        }
                    </ContactLink>
                </ContactLinks>
            </ContentSection>
            <MapSection>
                <MapComponent />
            </MapSection>
        </Container>
    );
}