import {
    Paragraph,
    GridItem,
    Card,
    TertiaryHeading,
} from '../common/Common.styles';
import { ValuesContainer } from './ValuesSection.styles';
import { StarIcon, SunIcon, HeartIcon } from '../../assets/media/icons.js';
import { useTranslation } from 'react-i18next';

export default function ValuesSection() {
    const { t } = useTranslation();

    return (

            <ValuesContainer>
                <GridItem>
                    <Card>
                        <StarIcon />
                        <TertiaryHeading>{t('values.card_1_title')}</TertiaryHeading>
                        <Paragraph>{t('values.card_1_description')}</Paragraph>
                    </Card>
                </GridItem>
                <GridItem>
                    <Card>
                        <SunIcon />
                        <TertiaryHeading>{t('values.card_2_title')}</TertiaryHeading>
                        <Paragraph>{t('values.card_2_description')}</Paragraph>
                    </Card>
                </GridItem>
                <GridItem>
                    <Card>
                        <HeartIcon />
                        <TertiaryHeading>{t('values.card_3_title')}</TertiaryHeading>
                        <Paragraph>{t('values.card_3_description')}</Paragraph>
                    </Card>
                </GridItem>
            </ValuesContainer>
       
    )
};