import React from 'react';
import { HeroContainer } from './HeroSection.styles.js';
import { 
  PrimaryHeading, 
  Paragraph, 
  PrimaryButton, 
  Spacer, 
  Container 
} from '../common/Common.styles.js';

const HeroSection = ({
  desktopImage,
  mobileImage,
  title,
  titleHighlight = '',
  descriptionLine1 = '',
  descriptionLine2 = '',
  buttonLabel = '',
  onButtonClick,
  maxWidth = '465px',
}) => (
  <HeroContainer $imgurl={desktopImage} $imgurlmobile={mobileImage}>
    <Container>
      <PrimaryHeading>
        {title}&nbsp;<i>{titleHighlight}</i>
      </PrimaryHeading>
      <Paragraph style={{ maxWidth }}>
        {descriptionLine1}&nbsp;{descriptionLine2}
      </Paragraph>
      <Spacer />
      {buttonLabel && (
        <PrimaryButton onClick={onButtonClick}>
          {buttonLabel}
        </PrimaryButton>
      )}
    </Container>
  </HeroContainer>
);

export default HeroSection;
