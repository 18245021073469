import styled from 'styled-components';

// Ensure Carousel Container occupies space and handles overflow properly
export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 550px;
  overflow: hidden;
  background-color: #f0f0f0;
`;

// Slide styling with proper transitions and consistent layout
export const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: 
    linear-gradient(to right, #003337E0, #00333700 100%),
    url(${(props) => props.$imgurl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 2rem;
  opacity: ${(props) => (props.className === 'active' ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 960px) {
    background-image: 
      linear-gradient(to bottom, #00333780, #00333780 100%),
      url(${(props) => props.$imgurlmobile});
    padding: 2rem;
  }
`;

// Dots Container
export const DotsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
`;

// Dots for Navigation
export const Dot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? 'var(--color-green)' : '#ffffff50')};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;
