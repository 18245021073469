import { useTranslation } from "react-i18next";
import FAQSection from "../components/FAQSection/FAQSection";
import FullWidthInfo from "../components/FullWidthInfo/FullWidthInfo";
import SEO from "../components/SEO/SEOComponent";

export default function FAQPage() {
    const { t } = useTranslation();
    return (
        <>
            <SEO
                title={t('seo.faqPage.title')}
                description={t('seo.faqPage.description')}
                image="https://careers.bathfitter.com/Media/Default/careers/faq-hero.jpg"
                url="https://careers.bathfitter.com/faq"
                author={t('seo.common.author')}
                publishedAt="2024-03-21"
                type={t('seo.common.type')}
                schemaType="FAQPage"
                schema={{
                    '@context': 'https://schema.org',
                    '@type': 'FAQPage',
                    name: t('seo.common.organization.name'),
                    description: t('faq.description'),
                    url: 'https://careers.bathfitter.com/faq',
                    mainEntity: [
                        {
                            '@type': 'Question',
                            name: t('faq.questions.0.question'),
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: `${t('faq.questions.0.answer')}${t('faq.questions.0.emailText')}${t('faq.questions.0.afterEmail')}`
                            }
                        },
                        {
                            '@type': 'Question',
                            name: t('faq.questions.1.question'),
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: `${t('faq.questions.1.answer')}${t('faq.questions.1.linkText')}${t('faq.questions.1.afterLink')}`
                            }
                        },
                        {
                            '@type': 'Question',
                            name: t('faq.questions.2.question'),
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: t('faq.questions.2.answer')
                            }
                        },
                        {
                            '@type': 'Question',
                            name: t('faq.questions.3.question'),
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: t('faq.questions.3.answer')
                            }
                        },
                        {
                            '@type': 'Question',
                            name: t('faq.questions.4.question'),
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: `${t('faq.questions.4.answer')}${t('faq.questions.4.linkText')}${t('faq.questions.4.afterLink')}`
                            }
                        },
                        {
                            '@type': 'Question',
                            name: t('faq.questions.5.question'),
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: t('faq.questions.5.answer')
                            }
                        },
                        {
                            '@type': 'Question',
                            name: t('faq.questions.6.question'),
                            acceptedAnswer: {
                                '@type': 'Answer',
                                text: t('faq.questions.6.answer')
                            }
                        }
                    ],
                    isPartOf: {
                        '@type': 'WebSite',
                        '@id': 'https://careers.bathfitter.com',
                        name: t('seo.common.organization.alternateName'),
                        publisher: {
                            '@type': 'Organization',
                            name: t('seo.common.organization.name'),
                            sameAs: 'https://www.linkedin.com/company/bath-fitter'
                        }
                    },
                    breadcrumb: {
                        '@type': 'BreadcrumbList',
                        itemListElement: [
                            {
                                '@type': 'ListItem',
                                position: 1,
                                name: t('header.home'),
                                item: 'https://careers.bathfitter.com'
                            },
                            {
                                '@type': 'ListItem',
                                position: 2,
                                name: t('contactPage.faq'),
                                item: 'https://careers.bathfitter.com/faq'
                            }
                        ]
                    }
                }}
            />
            <FullWidthInfo
                backgroundImage="https://careers.bathfitter.com/Media/Default/careers/Home-stats.jpg"
                descriptionKey={t('seo.faqPage.title')}
            />
            <FAQSection />
        </>
    )
}