import { useState, useEffect } from 'react';

const useLanguage = () => {
    // Helper function to extract language from URL path
    const getInitialLanguage = () => {
        const pathLang = window.location.pathname.split('/')[1]?.toLowerCase(); // Extracts first segment of the path
        return pathLang === 'fr' || pathLang === 'en' ? pathLang : 'en'; // Default to 'en' if not 'fr' or 'en'
    };

    const [language, setLanguage] = useState(getInitialLanguage());

    // React to changes in URL path without reloading the page
    useEffect(() => {
        const handleLanguageChange = () => {
            setLanguage(getInitialLanguage());
        };

        window.addEventListener('popstate', handleLanguageChange); // Listen to browser history changes
        return () => window.removeEventListener('popstate', handleLanguageChange);
    }, []);

    // Function to determine API URL based on language
    const getURL = () => {
        return language === 'en' ? process.env.REACT_APP_ENURL : process.env.REACT_APP_FRURL;
    };

    return { language, getURL };
};

export default useLanguage;
