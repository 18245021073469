import React from 'react';
import { useTranslation } from 'react-i18next';
import { JobDetailsContainer, JobDetails, JobDetail, JobsFilterContainer, ErrorContainer } from './JobListing.styles';
import { Paragraph, Redirect, TertiaryHeading } from '../common/Common.styles';
import { useNavigate } from 'react-router-dom';

// Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faBuilding, faListAlt } from '@fortawesome/free-regular-svg-icons';
import useLanguage from '../../hooks/useLanguage';

// JobContainer component - displays limited job details
// [prop] id: job ID
// [prop] title: job title
// [prop] location: job location
// [prop] departments: job departments
// [prop] category: job category
// Returns: JSX element with job details
export const JobContainer = ({ id, title, location, departments, category }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { language } = useLanguage(); // Use language from the hook

    // Construct the URL with the job ID and path-based language
    const url = `/${language}/careers/job/?gh_jid=${id}`;
    const urlFr = `/${language}/carrieres/emploi/?gh_jid=${id}`;

    return (
        <JobDetailsContainer onClick={() => navigate(
            language === 'en' ? url : urlFr
        )}>
            <TertiaryHeading>{title}</TertiaryHeading>
            <JobDetails>
                {category !== "resources.categories.unknown" && (
                    <JobDetail>
                        <span aria-label="Category">
                            <FontAwesomeIcon icon={faBuilding} />
                            {t(category)}
                        </span>
                    </JobDetail>
                )}
                {departments.length > 0 && (
                    <JobDetail>
                        <span aria-label="Departments">
                            <FontAwesomeIcon icon={faListAlt} />
                            <ul>
                                {departments.map(department => (
                                    <li key={department.id}>{t(`resources.departments.id_${department.id}`, `${department.name}`)}</li>
                                ))}
                            </ul>
                        </span>
                    </JobDetail>
                )}
                {location && (
                    <JobDetail>
                        <span aria-label="Location">
                            <FontAwesomeIcon icon={faMap} />
                            {t(`resources.locations.${location.replace(/[\s,]+/g, '')}`, location)}
                        </span>
                    </JobDetail>
                )}
            </JobDetails>
        </JobDetailsContainer>
    );
};

// JobsList component - displays a list of jobs
// [prop] Jobs: array of job objects
// [prop] loading: boolean indicating if the jobs are loading
// [prop] error: boolean indicating if there was an error loading the jobs
// [prop] filters: object containing category, department, and location filters
// [prop] getCategory: function to get the category name from the category ID
// [prop] keyword: string containing the search keyword
// Returns: JSX element with the list of jobs
export const JobsList = ({ Jobs, loading, error, filters, getCategory, keyword }) => {
    const { t } = useTranslation();
    const { language } = useLanguage(); // Use language from the hook

    const parseFilterIds = (filterValue) => {
        return filterValue ? filterValue.split(',').map(id => id.trim()) : [];
    };

    // Filter jobs based on category, department, and location
    const filteredJobs = Jobs.filter(job => {
        const categoryIds = parseFilterIds(filters.category);
        const departmentIds = parseFilterIds(filters.department);
        const matchesCategory = !categoryIds.length || job.departments.some(department => categoryIds.includes(String(department.parent_id)));
        const matchesDepartment = !departmentIds.length || job.departments.some(department => departmentIds.includes(String(department.id)));
        const matchesLocation = !filters.location || (job.location && job.location.name === filters.location);
        const matchesKeyword = !keyword || job.title.toLowerCase().includes(keyword.toLowerCase());

        return matchesCategory && matchesDepartment && matchesLocation && matchesKeyword;
    });

    // Sort jobs to put "unknown" categories at the end
    const sortedJobs = filteredJobs.sort((a, b) => {
        const categoryA = getCategory(a?.departments[0]?.parent_id);
        const categoryB = getCategory(b?.departments[0]?.parent_id);

        if (categoryA === "resources.categories.unknown" && categoryB !== "resources.categories.unknown") {
            return 1;
        } else if (categoryA !== "resources.categories.unknown" && categoryB === "resources.categories.unknown") {
            return -1;
        }
        return 0;
    });

    const NoJobsFound = (alt) => (
        <ErrorContainer>
            {alt ? (
                <Paragraph><strong>{t("resources.error.infoalt")}</strong></Paragraph>
            ) : (
                <Paragraph><strong>{t("resources.error.info")}</strong></Paragraph>
            )}
            <Paragraph>
                {t("resources.error.message1")} <br />
                {t("resources.error.message2")}
            </Paragraph>
            {language === 'fr' && <Redirect href="mailto:carrieres@bainmagique.com">carrieres@bainmagique.com</Redirect>}
            {language === 'en' && <Redirect href="mailto:careers@bathfitter.com">careers@bathfitter.com</Redirect>}
        </ErrorContainer>
    );

    // Component states
    if (loading) return <div>Loading...</div>;
    if (error) return <NoJobsFound />;
    if (!sortedJobs.length) return <NoJobsFound alt />;

    // Render job list
    return (
        <JobsFilterContainer>
            {sortedJobs.map(job => (
                <JobContainer
                    key={job.id}
                    id={job.id}
                    title={job.title}
                    location={job.location && job.location.name}
                    departments={job.departments}
                    category={getCategory(job?.departments[0]?.parent_id)}
                />
            ))}
        </JobsFilterContainer>
    );
};
