import styled from 'styled-components';
import { Container } from '../common/Common.styles';

const JobsFilterContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 2rem 2rem 2rem;
    width: 1200px;
    max-height: 450px;
    overflow: hidden;
    overflow-y: auto;

        &::-webkit-scrollbar {
  width: 8px;
}

 &::-webkit-scrollbar-thumb {
  background-color: #40c1ac;
  border-radius: 4px;
}

&::-webkit-scrollbar-track {
  background-color: #40c1ac10;
}
`;

const EmptyDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
   
  p{
    font-size: 1.5rem;
  }

    @media (max-width: 680px) {
        p {
            font-size: 1.2rem;
        }
    }

    @media (max-width: 490px) {
        p {
            font-size: 1rem;
        }
    }
    
`;

const ErrorContainer = styled(Container)`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4rem 2rem 8rem 2rem;
    text-align: center;
    
    & p, & a {
        font-size: 1.2rem;
    }
`

const JobsFilterTitle = styled.div`
    width: max-content;
`;  

const JobsFilterForm = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.618rem;

    @media (max-width: 600px) {
        margin: 0 auto;
        flex-direction: column;
        width: 100%;
    }
`;

const FormContent = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
        width: 100%;
    }
}`;

const JobsFilterInput = styled.input`
    border: 1px solid var(--color-black);
    border-radius: 4px;
    padding: 0.7rem 1rem;
    width: 100%;
`;

const JobsFilterDropdown = styled.select`
    border: 1px solid #40c1ac50;
    background-color: #40c1ac10;
    outline: none;
    border-radius: 4px;
    padding: 1rem 1.5rem; 
    padding-right: 2.5rem; 
    width: 250px;
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none;

    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNDYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzQwYzFhYyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJtMTkgOS03IDctNy03Ij48L3BhdGg+Cjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 16px 16px;
    

    option {
        padding: 1rem 1.5rem; 
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const JobsFilterButton = styled.div`
    height: 45px;
    border: none;
    outline: none;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
`;

const SearchInputContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 300px;
    position: relative;
    z-index: 10;

    @media (max-width: 1000px) {
        width: 425px;
    }

    @media (max-width: 490px) {
        width: 300px;
    }
`;


const JobDetailsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
   
  p{
    position: relative;
    font-size: 0.9rem;
    line-height: 1;
    margin-bottom: 0.5rem;
    &::before {
        content: "○";
        margin-right: 0.2rem;
    }
  }

    &:hover, &:focus-within, &:focus {
        transition: all 0.1s ease;
        background-color: #40c1ac10;
        padding-left: 1.5rem;

         h3 {
            color: #40c1ac;
        }

        svg {
            stroke: #40c1ac;
        }
       
    }

    &:active {
        svg {
            stroke: #00000050;
        }
    }

    & svg {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        height: 1.5rem;
    }

    @media (max-width: 680px) {
        p {
            font-size: 1.2rem;
        }
    }

    @media (max-width: 490px) {
        p {
            font-size: 1rem;
        }
    }
    
`;

const JobDetails = styled.article`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    color: var(--color-black-opacity-70);

    @media (max-width: 680px) {
        flex-direction: column;
        gap: 0;
    }
`;

const JobDetail = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;

    &:last-child {
        margin-bottom: 0;
    }

    span {
        display: flex;
        align-items: center;
    }

    svg {
        all: unset;
        flex-shrink: 0;
        height: 0.8rem;
        margin-right: 0.25rem;

        path {
            stroke: currentColor;
            opacity: 0.8;
        }
    }
`;


export {
    JobsFilterContainer,
    JobsFilterTitle,
    JobsFilterForm,
    JobsFilterInput,
    JobsFilterDropdown,
    JobsFilterButton,
    SearchInputContainer,
    JobDetailsContainer,
    JobDetails,
    JobDetail,
    FormContent,
    EmptyDetailsContainer,
    ErrorContainer
}