import styled from 'styled-components';

const TimelineBanner = styled.div`
  background-color: #f3f3f3;
  box-sizing: border-box;
  height: 100%;
  padding: 30px 0;
  width: 100%;
`;

const SlideWrapper = styled.section`
  box-sizing: border-box;
  display: block;
  height: 70%;
  position: unset;
  top: unset;
  transform: unset;

  @media (max-width: 1199px) {
    height: 80%;
  }

  @media (max-width: 845px) {
    padding: 0 25px;
  }
`;

const CarouselSlide = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  z-index: 100;
 padding: 3rem 2rem;
`;

const TopContent = styled.div`
  box-sizing: border-box;
  height: 10%;
  margin-bottom: 50px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #2b545a;
 width: max-content;
 text-align: center;
 width: 100%;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: #00555b;
    margin: 0.5rem auto;
  }

    @media (max-width: 960px) {
        font-size: 2rem;
    }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #00adbc;
  box-sizing: content-box;
  height: 1px;
  margin: auto auto 20px;
  text-align: left;
  width: 50px;
`;

const ReasonItem = styled.div`
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  width: 100%;
  margin-bottom: ${props => props.isLast ? '-10px' : '0'};

    @media (max-width: 845px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const EmptyReasonItem = styled(ReasonItem)`
    background-color: #f3f3f3;
    height: 300px;
    margin-bottom: 0;
`;

const ReasonContainer = styled.div`
  box-sizing: border-box;
  display: inline-block;
  left: ${props => props.left || '175px'};
  position: relative;

  @media (max-width: 845px) {
    left: 0;
  }
`;

const ReasonImage = styled.div`
  background-color: #f3f3f3;
  box-sizing: border-box;
  display: inline-block;
  float: ${props => props.float || 'left'};
  height: 289px;
  margin: auto auto 10px;
  overflow: hidden;
  position: relative;
  width: 237px;

  @media (max-width: 1199px) {
    height: 200px;
    width: 145px;
  }
`;

const ImageContent = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  display: inline-block;
  height: 101%;
  left: ${props => props.left || '47px'};
  position: absolute;
  z-index: 999;
`;

const ReasonImg = styled.img`
  border: none;
  box-sizing: border-box;
  height: 100%;
  object-fit: contain;
  vertical-align: middle;
`;

const ImageProgress = styled.div`
  background: ${props => props.background || '#05adbc'};
  box-sizing: border-box;
  display: inline-block;
  height: 0%;
  left: ${props => props.left || '47px'};
  overflow: hidden;
  position: absolute;
  width: ${props => props.width || '141px'};
  z-index: 99;
`;

const ImageBackground = styled(ImageProgress)`
  background-color: #c4c4c4;
  height: 100%;
  z-index: 9;
`;

const ReasonInfo = styled.div`
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  float: ${props => props.float || 'none'};

  @media (max-width: 845px) {
    width: 100%;
  }
`;

const ReasonNumber = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 93px;
  margin-bottom: 18px;
  position: relative;
  vertical-align: middle;
  float: ${props => props.float || 'none'};
  left: ${props => props.left || '0'};

  @media (max-width: 1199px) {
    height: 65px;
    margin-bottom: 5px;
    left: ${props => props.leftLarge || '0'};
  }

`;

const NumberContent = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  overflow: hidden;
  position: absolute;
  z-index: 999;
`;

const NumberImg = styled.img`
  border: none;
  box-sizing: border-box;
  object-fit: contain;
  vertical-align: middle;

  @media (max-width: 1199px) {
    height: 65px;
  }
`;

const NumberProgress = styled.div`
  background: ${props => props.background || '#05adbc'};
  box-sizing: border-box;
  display: inline-block;
  height: 0%;
  max-height: 93px;
  position: absolute;
  width: 155px;
  z-index: 99;

  @media (max-width: 1199px) {
    width: 108.33px;
  }
`;

const NumberBackground = styled(NumberProgress)`
  background-color: #c4c4c4;
  height: 93px;
  z-index: 9;

  @media (max-width: 1199px) {
    height: 65px;
  }
`;

const TextContainer = styled.div`
  box-sizing: border-box;
  display: inline-block;
  max-width: 350px;
  vertical-align: middle;
  width: 100%;
  clear: ${props => props.clear || 'none'};
  float: ${props => props.float || 'none'};
  text-align: ${props => props.align || 'left'};

  @media (max-width: 991px) {
    width: 85%;
  }

  @media (max-width: 845px) {
    width: 100%;
    }
`;

const ReasonTitle = styled.h3`
  box-sizing: border-box;
  color: #717d7c;
  font-family: GothamBook, sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 18px;
  margin-left: unset;
  margin-right: unset;
  margin-top: unset;
  padding: unset;
  text-align: unset;
  vertical-align: unset;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  @media (max-width: 485px) {
    font-size: 0.9rem;
  }
`;

const ReasonDescription = styled.div`
  box-sizing: border-box;
  color: #6b6f6e;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: .56px;
  line-height: 18px;
  opacity: .35;

  @media (max-width: 1199px) {
    font-size: 0.9rem;
  }

  &:hover {
    color: #3d3d3d;
    opacity: 1;
  }
`;

const TopLink = styled.a`
  background-color: transparent;
  box-sizing: border-box;
  color: #024d71;
  font-family: Gotham-Bold, sans-serif;
  font-size: 0.9rem;
  text-decoration: none;

  &:active {
    outline: 0;
  }

  &:hover {
    color: #3ab0c8;
    outline: 0;
  }

  &:focus {
    color: #157ab5;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    text-decoration-line: underline;
  }
`;

const BackToTopImage = styled(ReasonImage)`
  height: 72.23px;

  @media (max-width: 1199px) {
    height: 49.83px;
  }
`;

const BackToTopContent = styled(ImageContent)`
  height: auto;

  @media (max-width: 1199px) {
    height: auto;
  }

  @media (max-width: 767px) {
    height: auto;
  }

  @media (max-width: 485px) {
    height: auto;
  }
`;

const BackToTopImg = styled(ReasonImg)`
  width: 149.31px;

  @media (max-width: 1199px) {
    width: 103px;
  }

  @media (max-width: 767px) {
    width: 103.33px;
  }

  @media (max-width: 485px) {
    width: 103.33px;
  }
`;

const MobileReasons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 20px;
    width: 100%;
`;

export {
    TimelineBanner,
    SlideWrapper,
    CarouselSlide,
    TopContent,
    Title,
    Divider,
    ReasonItem,
    ReasonContainer,
    ReasonImage,
    ImageContent,
    ReasonImg,
    ImageProgress,
    ImageBackground,
    ReasonInfo,
    ReasonNumber,
    NumberContent,
    NumberImg,
    NumberProgress,
    NumberBackground,
    TextContainer,
    ReasonTitle,
    ReasonDescription,
    TopLink,
    BackToTopImage,
    BackToTopContent,
    BackToTopImg,
    MobileReasons,
    };