import React, { useState, useEffect } from 'react';
import ReasonsToWorkDesktop from './ReasonsToWorkDesktop';
import ReasonsToWorkMobile from './ReasonsToWorkMobile';

export default function ReasonToWork() {
  const [windowWidth, setWindowWidth] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }
    return 0;
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    // Set initial window width
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth > 845 ? (
    <ReasonsToWorkDesktop />
  ) : (
    <ReasonsToWorkMobile />
  );
}
