/* global Grnhse */

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    LoadingContainer, FloatingButton
} from "../components/common/Common.styles";
import { useJobDetails } from "../hooks/useJobsDetails";
import BarLoader from 'react-spinners/BarLoader';
import useLanguage from '../hooks/useLanguage';
import SEO from '../components/SEO/SEOComponent';
import DetailsHero from '../components/HeroSection/DetailsHero';

// SVG Component for the Floating Button
const BackIcon = () => (
    <svg fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 12H5"></path>
        <path d="m12 19-7-7 7-7"></path>
    </svg>
);

export default function JobDetails() {
    const { t } = useTranslation();
    const { job, loading, error } = useJobDetails();
    const formRef = useRef(null);
    const { language } = useLanguage();
    const [isLoading, setIsLoading] = useState(true);

    // Category groupings
    const categoryMappings = {
        Admin: ['4005227008', '4005228008', '4005229008'],
        Plant: ['4005230008'],
        Retail: ['4005231008']
    };

    // Get category based on category ID
    const getCategory = useCallback((categoryId) => {
        const categoryIdStr = categoryId?.toString();
        const foundKey = Object.entries(categoryMappings).find(([key, ids]) => ids.includes(categoryIdStr));
        return foundKey ? `resources.categories.${foundKey[0]}` : 'resources.categories.unknown';
    }, []);

    // Load Greenhouse.io script and initialize iframe
    useEffect(() => {
        const script = document.createElement('script');
        const board = language === 'fr' ? 'bainmagique' : 'bathfitterdistributinginc';
        script.src = `https://boards.greenhouse.io/embed/job_board/js?for=${board}`;

        const gh_jid = new URLSearchParams(window.location.search).get('gh_jid');

        const handleScriptLoad = () => {
            setTimeout(() => {
                Grnhse.Iframe.load(gh_jid);
            }, 1000);
        };

        setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        script.addEventListener('load', handleScriptLoad);
        document.body.appendChild(script);

        return () => {
            script.removeEventListener('load', handleScriptLoad);
            document.body.removeChild(script);
        };
    }, [language]);

    // Redirect URL based on language
    const redirectToCareers = useCallback(() => {
        window.location.href = language === 'fr' ? '/fr/carrieres' : '/en/careers';
    }, [language]);

    // Component states handling
    if (loading) return <div>{t('jobDetailsPage.loading')}</div>;
    if (error) return <div>{t('jobDetailsPage.error', { error: error.message })}</div>;
    if (!job) return <div>{t('jobDetailsPage.notFound')}</div>;

    const jobCategory = t(getCategory(job.departments[0]?.parent_id));
    const jobDepartment = t(`resources.departments.id_${job.departments[0]?.id}`, job.departments[0]?.name);
    const jobLocation = t(`resources.locations.${job?.location?.name.replace(/[\s,]+/g, '')}`, job?.location?.name);

    return (
        <>
            <SEO
                title={`${job.title} | ${t('seo.common.organization.name')}`}
                description={`${job.title} - ${t(`resources.departments.id_${job.departments[0]?.id}`, job.departments[0]?.name)} ${t(`resources.locations.${job?.location?.name.replace(/[\s,]+/g, '')}`, job?.location?.name)}`}
                image="https://careers.bathfitter.com/Media/Default/careers/careers-hero.jpg"
                url={`https://careers.bathfitter.com/job-details?gh_jid=${job.id}`}
                author={t('seo.common.author')}
                publishedAt={job.updated_at}
                type="JobPosting"
                schemaType="JobPosting"
                schema={{
                    '@context': 'https://schema.org',
                    '@type': 'JobPosting',
                    title: job.title,
                    description: job.content,
                    datePosted: job.updated_at,
                    validThrough: job.updated_at, // Add 30 days to posting date
                    employmentType: t(`resources.categories.${getCategory(job.departments[0]?.parent_id)}`),
                    hiringOrganization: {
                        '@type': 'Organization',
                        name: t('seo.common.organization.name'),
                        sameAs: 'https://careers.bathfitter.com',
                        logo: 'https://careers.bathfitter.com/logo.png'
                    },
                    jobLocation: {
                        '@type': 'Place',
                        address: {
                            '@type': 'PostalAddress',
                            addressLocality: job?.location?.name,
                            addressRegion: job?.location?.name.split(',')[1]?.trim(),
                            addressCountry: job?.location?.name.split(',')[2]?.trim()
                        }
                    },
                    baseSalary: {
                        '@type': 'MonetaryAmount',
                        currency: job?.location?.name.includes('Canada') ? 'CAD' : 'USD',
                        value: {
                            '@type': 'QuantitativeValue',
                            unitText: 'YEAR',
                            minValue: job.salary_min || '',
                            maxValue: job.salary_max || ''
                        }
                    },
                    industry: t('seo.common.organization.description'),
                    occupationalCategory: t(`resources.departments.id_${job.departments[0]?.id}`, job.departments[0]?.name),
                    qualifications: job.qualifications || '',
                    responsibilities: job.responsibilities || '',
                    skills: job.required_skills || '',
                    department: {
                        '@type': 'Organization',
                        name: t(`resources.departments.id_${job.departments[0]?.id}`, job.departments[0]?.name)
                    },
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': `https://careers.bathfitter.com/job-details?gh_jid=${job.id}`,
                        breadcrumb: {
                            '@type': 'BreadcrumbList',
                            itemListElement: [
                                {
                                    '@type': 'ListItem',
                                    position: 1,
                                    name: t('header.home'),
                                    item: 'https://careers.bathfitter.com'
                                },
                                {
                                    '@type': 'ListItem',
                                    position: 2,
                                    name: t('header.careers'),
                                    item: 'https://careers.bathfitter.com/careers'
                                },
                                {
                                    '@type': 'ListItem',
                                    position: 3,
                                    name: job.title,
                                    item: `https://careers.bathfitter.com/job-details?gh_jid=${job.id}`
                                }
                            ]
                        }
                    },
                    potentialAction: {
                        '@type': 'ApplyAction',
                        target: {
                            '@type': 'EntryPoint',
                            urlTemplate: `https://careers.bathfitter.com/job-details?gh_jid=${job.id}#grnhse_app`,
                            actionPlatform: [
                                'http://schema.org/DesktopWebPlatform',
                                'http://schema.org/MobileWebPlatform'
                            ]
                        }
                    }
                }}
            />

            {/* Floating Back Button */}
            <FloatingButton onClick={redirectToCareers}>
                <BackIcon />
            </FloatingButton>

            {/* Loading Container */}
            <LoadingContainer $loading={isLoading}>
                <BarLoader color="#40c1ac" />
            </LoadingContainer>

            {/* Hero Section */}
            <DetailsHero
                desktopImage="https://cdn01.onelogin.com/images/brands/backgrounds/login/d10e16a95980543a58ed32e1bad443f483267712.jpg?1605031090"
                mobileImage="https://cdn01.onelogin.com/images/brands/backgrounds/login/d10e16a95980543a58ed32e1bad443f483267712.jpg?1605031090"
                jobname={job?.title}
                dep={jobDepartment}
                cat={jobCategory}
                loc={jobLocation}
                maxWidth="465px"
            />

            {/* Greenhouse Job Listing Form */}
            <div ref={formRef} id="grnhse_app"></div>
        </>
    );
}
