import React, { useCallback } from 'react';
import {
    Container,
    SecondaryHeading,
    Paragraph,
    Spacer,
    FloatingButton,
    List,
    TertiaryHeading
} from '../components/common/Common.styles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection/HeroSection';
import { BackIcon } from '../assets/media/icons';

// Section Component to Reduce Repetition
const Section = ({ title, description, listItems }) => (
    <Container>
        <SecondaryHeading>{title}</SecondaryHeading>
        <Paragraph>{description}</Paragraph>
        {listItems && (
            <List>
                {listItems.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </List>
        )}
    </Container>
);

export default function PrivacyPolicy() {
    const { t } = useTranslation();

    // Memoized function to generate the list content to prevent unnecessary re-renders
    const renderList = useCallback(
        (listKey) => t(listKey, { returnObjects: true }),
        [t]
    );

    return (
        <>
            {/* SEO Metadata */}
            <Helmet>
                <title>{t('privacyPolicy.pageTitle')}</title>
                <meta name="description" content={t('privacyPolicy.pageTitle')} />
            </Helmet>

            {/* Floating Back Button */}
            <FloatingButton onClick={() => window.history.back()}>
                <BackIcon />
            </FloatingButton>

            {/* Hero Section */}
            <HeroSection
                desktopImage="https://cdn.brandfolder.io/CXW2BXXD/at/w3pm2tr99pw96t3xtkxqg89/wall_savona_white.png"
                mobileImage="https://cdn.brandfolder.io/CXW2BXXD/at/w3pm2tr99pw96t3xtkxqg89/wall_savona_white.png"
                title={t('privacyPolicy.r')}
                titleHighlight={t('privacyPolicy.pageTitle')}
            />

            {/* Privacy Policy Sections */}
            <Section
                title={t('privacyPolicy.objectives.title')}
                description={t('privacyPolicy.objectives.description')}
            />
            <Section
                title={t('privacyPolicy.scope.title')}
                description={t('privacyPolicy.scope.description')}
            />
            <Section
                title={t('privacyPolicy.personalInfo.title')}
                description={t('privacyPolicy.personalInfo.description')}
            />
            <Section
                title={t('privacyPolicy.generalPolicy.title')}
                description={t('privacyPolicy.generalPolicy.description')}
            />
            <Section
                title={t('privacyPolicy.collectionOfInfo.title')}
                description={t('privacyPolicy.collectionOfInfo.description')}
                listItems={renderList('privacyPolicy.collectionOfInfo.list')}
            />
            <Section
                title={t('privacyPolicy.useOfInfo.title')}
                description={t('privacyPolicy.useOfInfo.description')}
                listItems={renderList('privacyPolicy.useOfInfo.list')}
            />
            <Section
                title={t('privacyPolicy.sharingOfInfo.title')}
                description={t('privacyPolicy.sharingOfInfo.description')}
                listItems={[...renderList('privacyPolicy.sharingOfInfo.list'), t('privacyPolicy.sharingOfInfo.addition')]}
            >
                <Paragraph>{t('privacyPolicy.sharingOfInfo.addition')}</Paragraph>
            </Section>
            <Section
                title={t('privacyPolicy.crossBorderTransfer.title')}
                description={t('privacyPolicy.crossBorderTransfer.description')}
            />

            <Container>
                <SecondaryHeading>{t('privacyPolicy.monitoring.sectionTitle')}</SecondaryHeading>
                <TertiaryHeading>{t('privacyPolicy.monitoring.useOfResources.title')}</TertiaryHeading>
                <Paragraph>{t('privacyPolicy.monitoring.useOfResources.description')}</Paragraph>
                <Spacer />
                <TertiaryHeading>{t('privacyPolicy.monitoring.videoMonitoring.title')}</TertiaryHeading>
                <Paragraph>{t('privacyPolicy.monitoring.videoMonitoring.description')}</Paragraph>
            </Container>

            <Section
                title={t('privacyPolicy.accuracyAndCorrections.title')}
                description={t('privacyPolicy.accuracyAndCorrections.description')}
            />
            <Section
                title={t('privacyPolicy.complaintsAndQuestions.title')}
                description={t('privacyPolicy.complaintsAndQuestions.description')}
            />
            <Section
                title={t('privacyPolicy.changesToNotice.title')}
                description={t('privacyPolicy.changesToNotice.description')}
            />

            <Spacer />
        </>
    );
}
