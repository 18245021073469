import styled from 'styled-components';
import { PrimaryButton } from '../common/Common.styles';

export const FooterContainer = styled.footer`
    background-color: var(--header-background-color);
    padding: 3rem 2rem;
    font-size: 1rem;
    color: #FFFFFF;
    box-shadow: 0 -2px 10px 0 rgba(0,0,0,.2);
    z-index: 100;
    position: relative;
`;

export const FooterContent = styled.div`
    max-width: 1200px;
    padding: 0 2rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: stretch;
    justify-content: space-between;
    text-align: center;

    @media (max-width: 960px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const FooterContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    min-height: 230px;
    
    @media (max-width: 960px) {
        width: 100%;
        padding-bottom: 2rem;
        border-bottom: 1px solid #FFFFFF50;

        &:is(:empty) {
            display: none;
        }
    }
`;

export const FooterLogo = styled.img`
    height:50px;
`;

export const FooterInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    text-align: left;

    & svg {
        height: 20px;
        width: 20px;
        fill: #FFF;
    }

    & a {
        color: #FFFFFF;
    }
`;

export const FooterLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;

    & a {
        color: #FFFFFF;
    }

    & h4 {
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
    }
`;

export const FooterBottomLinks = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    & a {
        color: #FFFFFF;
    }
`;

export const FooterForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 1rem;

    
    & h4 {
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
    }

    @media (max-width: 960px) {
        margin: 0 auto;
    }
`;

export const FooterInput = styled.input`
    padding: 1rem;
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
    background-color: #047179;
    outline: none;
    color: #FFFFFF;

    &::placeholder {
        color: #FFFFFF;
    }
`;

export const FooterButton = styled(PrimaryButton)`
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
`;

export const FooterBottom = styled.div`
    max-width: 1200px;
    padding: 0 2rem;
    margin: auto;
    margin-top: 2rem;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 960px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & p {
            margin-top: 1rem;
            text-align: center;
        }
    }
`;