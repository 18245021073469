import React, { useState } from 'react';
import {
    HeaderContainer, Nav, NavLink, HamburgerIcon,
    MobileMenu, MobileNavLink, MobileLogo, MobileWeAreHiring
} from './Header.styles';
import { PrimaryButton } from '../common/Common.styles';
import useLanguage from '../../hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import { buildUrl } from '../../utils/urlUtils';
import { Link, useLocation } from 'react-router-dom';

import BF from '../../assets/media/BathFitterLogo_White.png';
import BM from '../../assets/media/BainMagique_Logo_blanc.png';
import LangSwitch from '../LangSwitch/LangSwitch';

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const { language } = useLanguage();
    const { t } = useTranslation();
    const location = useLocation();  // Get current location

    // Toggle mobile menu
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Helper function to check if a link is active
    const isActive = (path) => location.pathname === path;

    // Header component
    return (
        <>
            <HeaderContainer>
                <HamburgerIcon onClick={toggleMenu}>
                    <span />
                    <span />
                    <span />
                </HamburgerIcon>

                {/* Mobile Logo */}
                <Link to={buildUrl('/', language)}>
                    <MobileLogo
                        src={language === 'en' ? BF : BM}
                        alt="website logo"
                    />
                </Link>

                {/* Desktop Navigation */}
                <Nav>
                    <NavLink href={buildUrl('/', language)} className={isActive(buildUrl('/', language)) ? 'active-header-link' : ''}>
                        <img
                             src={language === 'en' ? BF : BM}
                            alt="website logo"
                        />
                    </NavLink>
                    <NavLink href={buildUrl('/', language)} className={isActive(buildUrl('/', language)) ? 'active-header-link' : ''}>
                        {t('header.home')}
                    </NavLink>
                    <NavLink href={buildUrl('/ourculture', language)} className={isActive(buildUrl('/ourculture', language)) ? 'active-header-link' : ''}>
                        {t('header.about')}
                    </NavLink>
                    <NavLink href={buildUrl('/whoweare', language)} className={isActive(buildUrl('/whoweare', language)) ? 'active-header-link' : ''}>
                        {t('header.whoweare')}
                    </NavLink>
                    <NavLink href={buildUrl('/careers', language)} className={isActive(buildUrl('/careers', language)) ? 'active-header-link' : ''}>
                        {t('header.careers')}
                    </NavLink>
                    <NavLink href={buildUrl('/contact', language)} className={isActive(buildUrl('/contact', language)) ? 'active-header-link' : ''}>
                        {t('header.contact')}
                    </NavLink>
                    <LangSwitch />

                    {/* Primary "We Are Hiring" Button */}
                    <PrimaryButton onClick={() => window.location.href = buildUrl('/careers/#joblisting', language)}>
                        {t('header.hiring')}
                    </PrimaryButton>
                </Nav>

                {/* Mobile Menu */}
                {isOpen && (
                    <MobileMenu>
                        <MobileNavLink href={buildUrl('/', language)} onClick={toggleMenu} className={isActive(buildUrl('/', language)) ? 'active-header-link' : ''}>
                            {t('header.home')}
                        </MobileNavLink>
                        <MobileNavLink href={buildUrl('/ourculture', language)} onClick={toggleMenu} className={isActive(buildUrl('/ourculture', language)) ? 'active-header-link' : ''}>
                            {t('header.about')}
                        </MobileNavLink>
                        <MobileNavLink href={buildUrl('/whoweare', language)} onClick={toggleMenu} className={isActive(buildUrl('/whoweare', language)) ? 'active-header-link' : ''}>
                            {t('header.whoweare')}
                        </MobileNavLink>
                        <MobileNavLink href={buildUrl('/careers', language)} onClick={toggleMenu} className={isActive(buildUrl('/careers', language)) ? 'active-header-link' : ''}>
                            {t('header.careers')}
                        </MobileNavLink>
                        <MobileNavLink href={buildUrl('/contact', language)} onClick={toggleMenu} className={isActive(buildUrl('/contact', language)) ? 'active-header-link' : ''}>
                            {t('header.contact')}
                        </MobileNavLink>
                        <LangSwitch />
                        <MobileWeAreHiring onClick={() => window.location.href = buildUrl('/careers/#joblisting', language)}>
                            We are Hiring
                        </MobileWeAreHiring>
                    </MobileMenu>
                )}
            </HeaderContainer>

            {/* Optional Mobile "We Are Hiring" Button */}
            {!isOpen && (
                <MobileWeAreHiring onClick={() => window.location.href = buildUrl('/careers/#joblisting', language)}>
                    We are Hiring
                </MobileWeAreHiring>
            )}
        </>
    );
}
