import styled from 'styled-components';
import Slider from 'react-slick';

export const CarouselContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 3rem 2rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  position: relative;

  @media (max-width: 960px) {
    flex-direction: column;
    min-height: auto;
    gap: 2rem;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 50%;
  height: 100%;
  
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slick-slide {
    height: 100%;
    
    > div {
      height: 100%;
      
      > div {
        height: 100%;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slick-dots {
    bottom: 20px;
    z-index: 1;
    
    li {
      margin: 0 4px;
      
      button {
        width: 12px;
        height: 12px;
        padding: 0;
        border-radius: 50%;
        background: transparent;
        border: 1px solid white;
        transition: background-color 0.3s ease;
        
        &:before {
          display: none;
        }
      }
      
      &.slick-active button {
        background: white;
      }
    }
  }

  @media (max-width: 960px) {
    width: 100%;
    height: 400px;
  }
`;

export const ContentSection = styled.div`
  width: 50%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  background-color: white;
  height: 100%;

  @media (max-width: 960px) {
    width: 100%;
    height: auto;
  }
`;

export const Title = styled.h2`
  font-size: 2.25rem; // Increased font size
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors?.primary || '#000'};
  line-height: 1.2;
`;

export const Description = styled.p`
  font-size: 1.125rem; // Increased font size
  line-height: 1.5;
  color: ${props => props.theme.colors?.text || '#666'};
  margin-bottom: 2rem;
`;

export const CtaButton = styled.button`
  padding: 1rem 2rem;
  background-color: ${props => props.theme.colors?.primary || '#3b82f6'};
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
  font-size: 1.125rem;

  &:hover {
    background-color: ${props => props.theme.colors?.primaryDark || '#2563eb'};
  }

  @media (max-width: 960px) {
    align-self: flex-start;
    padding: 0.875rem 1.75rem;
  }
`;