import React from 'react';
import HeroCarousel from '../components/HeroCarousel/HeroCarousel';
import MediaContent from '../components/MediaContent/MediaContent';
import CarouselShowcase from '../components/CarouselShowcase/CarouselShowcase';
import FullWidthInfo from '../components/FullWidthInfo/FullWidthInfo';
import { buildUrl } from '../utils/urlUtils';
import useLanguage from '../hooks/useLanguage';
import ReasonToWork from '../components/ReasonsToWork/ReasonToWorkResponsive';
import TestimonialSection from '../components/TestimonialSection/TestimonialSection';

import HeroSlide1 from '../assets/media/HeroSlide1.png';
import HeroSlide2 from '../assets/media/HeroSlide2.png';
import HeroSlide1Mobile from '../assets/media/HeroSlide1-mobile.png';
import HeroSlide2Mobile from '../assets/media/HeroSlide2-mobile.png';
import CandidateSlide1 from '../assets/media/CandidateSlide1.png';

import Award1en from '../assets/media/awards/en/1.png';
import Award2en from '../assets/media/awards/en/2.png';
import Award3en from '../assets/media/awards/en/3.png';
import Award4en from '../assets/media/awards/en/4.png';
import Award5en from '../assets/media/awards/en/5.png';
import Award6en from '../assets/media/awards/en/6.png';
import Award7en from '../assets/media/awards/en/7.png';
import Award8en from '../assets/media/awards/en/8.png';

import Award1fr from '../assets/media/awards/fr/1.png';
import Award2fr from '../assets/media/awards/fr/2.png';
import Award3fr from '../assets/media/awards/fr/3.png';
import Award4fr from '../assets/media/awards/fr/4.png';
import Award5fr from '../assets/media/awards/fr/5.png';
import Award6fr from '../assets/media/awards/fr/6.png';
import Award7fr from '../assets/media/awards/fr/7.png';
import Award8fr from '../assets/media/awards/fr/8.png';

import Person1 from '../assets/media/Home-manon-c.png';
import Person2 from '../assets/media/Home-mark-r.png';
import Person3 from '../assets/media/Home-tammy-w.png';
import CTASection from '../components/CTASection/CTASection';
import { useTranslation } from 'react-i18next';
import SEO from '../components/SEO/SEOComponent';


export default function HomePage() {
    const { language } = useLanguage();
    const { t } = useTranslation();

    const slides = [
        {
            desktopImage: HeroSlide1,
            mobileImage: HeroSlide1Mobile,
            title: t('homePage.heroSlide1Title'),
            titleHighlight: t('homePage.heroSlide1TitleHighlight'),
            descriptionLine1: t('homePage.heroSlide1DescriptionLine1'),
            descriptionLine2: t('homePage.heroSlide1DescriptionLine2'),
            buttonLabel: t('homePage.heroSlide1ButtonLabel'),
            onButtonClick: () => (window.location.href = buildUrl('/ourculture', language)),
        },
        {
            desktopImage: HeroSlide2,
            mobileImage: HeroSlide2Mobile,
            title: t('homePage.heroSlide2Title'),
            titleHighlight: t('homePage.heroSlide2TitleHighlight'),
            descriptionLine1: t('homePage.heroSlide2DescriptionLine1'),
            descriptionLine2: t('homePage.heroSlide2DescriptionLine2'),
            buttonLabel: t('homePage.heroSlide2ButtonLabel'),
            onButtonClick: () => (window.location.href = buildUrl('/careers', language)),
        },
    ];

    const awardsEn = [
        { image: Award1en, alt: 'Award 1' },
        { image: Award2en, alt: 'Award 2' },
        { image: Award3en, alt: 'Award 3' },
        { image: Award4en, alt: 'Award 4' },
        { image: Award5en, alt: 'Award 5' },
        { image: Award6en, alt: 'Award 6' },
        { image: Award7en, alt: 'Award 7' },
        { image: Award8en, alt: 'Award 8' },
    ];

    const awardsFr = [
        { image: Award1fr, alt: 'Award 1' },
        { image: Award2fr, alt: 'Award 2' },
        { image: Award3fr, alt: 'Award 3' },
        { image: Award4fr, alt: 'Award 4' },
        { image: Award5fr, alt: 'Award 5' },
        { image: Award6fr, alt: 'Award 6' },
        { image: Award7fr, alt: 'Award 7' },
        { image: Award8fr, alt: 'Award 8' },
    ];

    const testimonials = [
        {
            quote:
                t('testimonial.testimonial1Quote'),
            author: t('testimonial.testimonial1Author'),
            role: t('testimonial.testimonial1Role'),
            image: Person1,
        },
        {
            quote: t('testimonial.testimonial2Quote'),
            author: t('testimonial.testimonial2Author'),
            role: t('testimonial.testimonial2Role'),
            image: Person2,
        },
        {
            quote: t('testimonial.testimonial3Quote'),
            author: t('testimonial.testimonial3Author'),
            role: t('testimonial.testimonial3Role'),
            image: Person3,
        },
    ];

    return (
        <>
            <SEO
                title={`${t('seo.homePage.title')}`}
                description={t('seo.homePage.description')}
                image="https://careers.bathfitter.com/Media/Default/careers/Home-stats.jpg"
                url="https://careers.bathfitter.com"
                author={t('seo.common.author')}
                publishedAt="2024-03-21"
                type={t('seo.common.type')}
                schemaType="Organization"
                schema={{
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    name: t('seo.common.organization.name'),
                    alternateName: t('seo.common.organization.alternateName'),
                    description: t('seo.common.organization.description'),
                    url: 'https://careers.bathfitter.com',
                    location: [
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.usa.address')
                            }
                        },
                        {
                            '@type': 'Place',
                            address: {
                                '@type': 'PostalAddress',
                                streetAddress: t('seo.locations.canada.address')
                            }
                        }
                    ],
                    contactPoint: {
                        '@type': 'ContactPoint',
                        email: t('seo.common.contactEmail'),
                        contactType: t('seo.homePage.schema.jobsAvailable')
                    },
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': 'https://careers.bathfitter.com',
                        name: t('seo.common.organization.name'),
                        description: t('seo.homePage.schema.companyMission')
                    }
                }}
            />
            <HeroCarousel slides={slides} />

            <MediaContent
                mediaLayout="left"
                mediaSrc={language === 'en' ? "https://player.vimeo.com/video/403403554" : "https://player.vimeo.com/video/403738385"}
                mediaType="video"
                title={t('homePage.mediaContentTitle')}
                description={t('homePage.mediaContentDescription')}
                ctaLabel={t('homePage.mediaContentCtaLabel')}
                onCtaClick={() => window.location.href = buildUrl('/whoweare', language)}
            />

            <CarouselShowcase
                title={t('homePage.awardsTitle')}
                items={language === 'en' ? awardsEn : awardsFr}
            />

            <FullWidthInfo
                backgroundImage="https://careers.bathfitter.com/Media/Default/careers/Home-stats.jpg"
                descriptionKey="homePage.fullWidthInfoDescription"
            />

            <MediaContent
                mediaLayout="right"
                mediaSrc={CandidateSlide1}
                mediaType="image"
                title={t('homePage.mediaContent2Title')}
                description={t('homePage.mediaContent2Description')}
                ctaLabel={t('homePage.mediaContent2CtaLabel')}
                onCtaClick={() => window.location.href = buildUrl('/careers', language)}
            />

            <ReasonToWork />

            <TestimonialSection testimonials={testimonials} />

            <CTASection
                title={t('homePage.ctaSectionTitle')}
                description={t('homePage.ctaSectionDescription')}
                ctaLabel={t('homePage.ctaSectionCtaLabel')}
                cta2Label={t('homePage.ctaSectionCta2Label')}
                onCtaClick={() => window.location.href = buildUrl('/careers', language)}
                onCta2Click={() => window.location.href = buildUrl('/FAQ', language)}
            />
        </>
    );
}
