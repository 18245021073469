import styled from 'styled-components';

// Main container for media and content
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 3rem 2rem;
  gap: 2rem;

  @media (max-width: 960px) {
    flex-direction: column;
    text-align: center;
  }
`;

// Map Section
export const MapSection = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Content Section with text and CTA
export const ContentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
`;


export const ContactLinks = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
`;


export const ContactLink = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    text-align: left;

    & svg {
        height: 20px;
        width: 20px;
        fill: #006269;
    }

    & a {
        color: #000;
    }
`;