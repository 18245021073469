import React, { useState, useEffect } from 'react';
import { CarouselContainer, Slide, DotsContainer, Dot } from './HeroCarousel.styles';
import { PrimaryHeading, Paragraph, PrimaryButton, Spacer, Container } from '../common/Common.styles';

const HeroCarousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => setCurrentIndex(index);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <CarouselContainer>
      {slides.map((slide, index) => (
        <Slide
          key={index}
          className={index === currentIndex ? 'active' : ''}
          $imgurl={slide.desktopImage}
          $imgurlmobile={slide.mobileImage}
        >
          <Container>
            <PrimaryHeading>
              {slide.title}&nbsp;<i>{slide.titleHighlight}</i>
            </PrimaryHeading>
            <Paragraph>
              {slide.descriptionLine1}
            </Paragraph>
            <Paragraph>
              {slide.descriptionLine2}
            </Paragraph>
            {slide.buttonLabel && (
              <>
                <Spacer />
                <PrimaryButton onClick={slide.onButtonClick}>
                  {slide.buttonLabel}
                </PrimaryButton>
              </>
            )}
          </Container>
        </Slide>
      ))}

      <DotsContainer>
        {slides.map((_, index) => (
          <Dot
            key={index}
            active={index === currentIndex}
            onClick={() => goToSlide(index)}
          />
        ))}
      </DotsContainer>
    </CarouselContainer>
  );
};

export default HeroCarousel;
