export const ResourcesFr = {
    categories: {
        Admin: 'Administration',
        Plant: 'Usine',
        Retail: 'Vente au détail',
    },
    departments: {
        id_4005298008: 'Bureau de magasin',
        id_4005251008: 'Ressources humaines',
        id_4005300008: 'Plombier',
        id_4005279008: 'Technologie de l\'information',
        id_4005303008: 'Directeur de succursale',
        id_4005237008: 'Marketing',
        id_4005289008: 'Atelier d\'usinage',
        id_4005232008: 'Opérations manufacturières',
        id_4005236008: 'Service à la clientèle',
        id_4005241008: 'Finance',
        id_4005227008: 'Employé administratif',
        id_4005239008: 'Ressources humaines',
        id_4005247008: 'Communications',
        id_4005238008: 'Développement de produits',
        id_4005242008: 'Affaires juridiques et administratives',
        id_4005243008: 'Technologie de l\'information',
        id_4005245008: 'Opération de détail',
        id_4005246008: 'Expérience client',
        id_4005240008: 'Approvisionnement',
        id_4005233008: 'Amélioration continue',
        id_4005234008: 'Ingénierie',
        id_4005235008: 'Bistro',
        id_4005244008: 'Apprentissage et développement',
        id_4005250008: 'Développement de produits',
        id_4005248008: 'Opérations manufacturières',
        id_4005249008: 'Marketing',
        id_4005228008: 'Exécutif administratif',
        id_4005252008: 'Approvisionnement',
        id_4005253008: 'Finance',
        id_4005254008: 'Affaires juridiques et administratives',
        id_4005255008: 'Technologie de l\'information',
        id_4005256008: 'Apprentissage et développement',
        id_4005257008: 'Opération de détail',
        id_4005258008: 'Bains',
        id_4005259008: 'Accessoires',
        id_4005260008: 'Composites',
        id_4005261008: 'Expédition',
        id_4005262008: 'Présentoir',
        id_4005263008: 'Atelier d\'usinage',
        id_4005264008: 'Entretien',
        id_4005265008: 'Magasin d\'inventaire',
        id_4005266008: 'Extrusion',
        id_4005267008: 'Inspection de qualité',
        id_4005268008: 'Opérations manufacturières',
        id_4005229008: 'Gestionnaire administratif',
        id_4005270008: 'Amélioration continue',
        id_4005275008: 'Ressources humaines',
        id_4005276008: 'Approvisionnement',
        id_4005278008: 'Affaires juridiques et administratives',
        id_4005271008: 'Ingénierie',
        id_4005272008: 'Service à la clientèle',
        id_4005277008: 'Finance',
        id_4005282008: 'Expérience client',
        id_4005284008: 'Bains',
        id_4005286008: 'Composites',
        id_4005291008: 'Magasin d\'inventaire',
        id_4005292008: 'Extrusion',
        id_4005293008: 'Inspection de qualité',
        id_4005294008: 'Transport',
        id_4005295008: 'Adhésif',
        id_4005296008: 'Installateur',
        id_4005297008: 'Conseiller de ventes',
        id_4005299008: 'Magasinier',
        id_4005301008: 'Consultant ventes commerciales',
        id_4005302008: 'Supervision de production',
        id_4005304008: 'Assistant installateur',
        id_4005305008: 'Coordonnateur d\'événements',
        id_4005273008: 'Marketing',
        id_4005274008: 'Développement de produits',
        id_4005281008: 'Opération de détail',
        id_4005283008: 'Communications',
        id_4005285008: 'Accessoires',
        id_4005287008: 'Expédition',
        id_4005288008: 'Présentoir',
        id_4005230008: 'Usine',
        id_4005231008: 'Ventes au détail',
        id_4005290008: 'Entretien',
        id_4016143008: 'Centre d\'appels interne',
        id_4020328008: 'Chef de succursale',
    },
    locations: {
        'Saint-EustacheQuebecCanada': 'Saint-Eustache, Québec, Canada',
        'SpringfieldTennesseeUnitedStates': 'Springfield, Tennessee, États-Unis',
        'WoburnMassachusettsUnitedStates': 'Woburn, Massachusetts, États-Unis',
        'EvansvilleIndianaUnitedStates': 'Evansville, Indiana, États-Unis',
        'WestPalmBeachFloridaUnitedStates': 'West Palm Beach, Floride, États-Unis',
        'HuntsvilleAlabamaUnitedStates': 'Huntsville, Alabama, États-Unis',
        'KansasCityKansasUnitedStates': 'Kansas City, Kansas, États-Unis',
        'LouisvilleKentuckyUnitedStates': 'Louisville, Kentucky, États-Unis',
        'FresnoCaliforniaUnitedStates': 'Fresno, Californie, États-Unis',
        'NewOrleansLouisianaUnitedStates': 'La Nouvelle-Orléans, Louisiane, États-Unis',
        'PortlandOregonUnitedStates': 'Portland, Oregon, États-Unis',
        'SyracuseNewYorkUnitedStates': 'Syracuse, New York, États-Unis',
        'SacramentoCaliforniaUnitedStates': 'Sacramento, Californie, États-Unis',
        'LombardIllinoisUnitedStates': 'Lombard, Illinois, États-Unis',
        'JacksonMississippiUnitedStates': 'Jackson, Mississippi, États-Unis',
        'ArlingtonTexasUnitedStates': 'Arlington, Texas, États-Unis',
        'PortlandMaineUnitedStates': 'Portland, Maine, États-Unis',
        'NewBrunswickCanada': 'Nouveau-Brunswick, Canada',
        'MontrealNordQuebecCanada': 'Montréal-Nord, Québec, Canada',
        'VancouverBritishColumbiaCanada': 'Vancouver, Colombie-Britannique, Canada',
        'EtobicokeOntarioCanada': 'Etobicoke, Ontario, Canada',
        'HamiltonOntarioCanada': 'Hamilton, Ontario, Canada',
        'MississaugaOntarioCanada': 'Mississauga, Ontario, Canada',
        'SanJoseCaliforniaUnitedStates': 'San José, Californie, États-Unis',
        'WichitaKansasUnitedStates': 'Wichita, Kansas, États-Unis',
        'NorthbrookIllinoisUnitedStates': 'Northbrook, Illinois, États-Unis',
        'NashvilleTennesseeUnitedStates': 'Nashville, Tennessee, États-Unis',
        'MokenaIllinoisUnitedStates': 'Mokena, Illinois, États-Unis',
        'OmahaNebraskaUnitedStates': 'Omaha, Nebraska, États-Unis',
        'RochesterNewYorkUnitedStates': 'Rochester, New York, États-Unis',
        'PensacolaFloridaUnitedStates': 'Pensacola, Floride, États-Unis',
        'PhoenixArizonaUnitedStates': 'Phoenix, Arizona, États-Unis',
        'TallahasseeFloridaUnitedStates': 'Tallahassee, Floride, États-Unis',
        'AlbuquerqueNewMexicoUnitedStates': 'Albuquerque, Nouveau-Mexique, États-Unis',
        'BirminghamAlabamaUnitedStates': 'Birmingham, Alabama, États-Unis',
        'BurlingtonVermontUnitedStates': 'Burlington, Vermont, États-Unis',
        'LivoniaMichiganUnitedStates': 'Livonia, Michigan, États-Unis',
        'BatonRougeLouisianaUnitedStates': 'Bâton-Rouge, Louisiane, États-Unis',
        'BedfordNewHampshireUnitedStates': 'Bedford, New Hampshire, États-Unis',
        'AtlantaGeorgiaUnitedStates': 'Atlanta, Géorgie, États-Unis',
        'BridgewaterMassachusettsUnitedStates': 'Bridgewater, Massachusetts, États-Unis',
        'DeerfieldBeachFloridaUnitedStates': 'Deerfield Beach, Floride, États-Unis',
        'CharlestonSouthCarolinaUnitedStates': 'Charleston, Caroline du Sud, États-Unis',
        'ChattanoogaTennesseeUnitedStates': 'Chattanooga, Tennessee, États-Unis',
        'HartfordConnecticutUnitedStates': 'Hartford, Connecticut, États-Unis',
        'HoustonTexasUnitedStates': 'Houston, Texas, États-Unis',
        'IndianapolisIndianaUnitedStates': 'Indianapolis, Indiana, États-Unis',
        'KnoxvilleTennesseeUnitedStates': 'Knoxville, Tennessee, États-Unis',
        'SanAntonioTexasUnitedStates': 'San Antonio, Texas, États-Unis',
        'KitchenerOntarioCanada': 'Kitchener, Ontario, Canada',
        'Montréal-NordQuebecCanada': 'Montréal-Nord, Québec, Canada',
    },
    empty: 'Aucun emploi trouvé',
    error:{
        info:"Nous effectuons actuellement des mises à jour sur notre site web.",
        infoalt:"Nous n'avons pas d'offres d'emploi actuellement.",
        message1:"Cependant, cela ne vous empêchera pas de postuler à nos offres d'emploi.",
        message2:"Veuillez envoyer votre CV et votre candidature à"
    }
}