import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    JobsFilterContainer,
    JobsFilterForm,
    JobsFilterDropdown,
    FormContent
} from './JobListing.styles';

// Helper to group departments by translated names
// [prop] jobs: array of job objects
// [prop] selectedCategory: array of selected category IDs
// [prop] translate: function to translate text
// Returns: object with department names as keys and arrays of department IDs as values
const organizeDepartmentsByName = (jobs, selectedCategory, translate) => {
    return jobs.reduce((acc, job) => {
        job.departments.forEach(dept => {
            if (!selectedCategory || selectedCategory.includes(String(dept.parent_id))) {
                const deptName = translate(`resources.departments.id_${dept.id}`, dept.name);
                if (!acc[deptName]) acc[deptName] = [];
                acc[deptName].push(dept.id);
            }
        });
        return acc;
    }, {});
};

// Helper to prioritize locations
// [prop] locations: array of location names
// Returns: array of location names with priority locations first
const prioritizeLocations = (locations) => {
    const priority = ['Saint-Eustache, Quebec, Canada', 'Springfield, Tennessee, United States'];
    const prioritized = priority.filter(loc => locations.includes(loc));
    const remaining = locations.filter(loc => !priority.includes(loc));
    return [...prioritized, ...remaining];
};

export default function JobsFilters({ Jobs, handleFilterChange, currentFilters, categoryMappings }) {
    const { t } = useTranslation();
    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [groupedDepartments, setGroupedDepartments] = useState({});
    const initialRender = useRef(true);



    // Update filter options based on the current filters and jobs
    const updateFilterOptions = useCallback(() => {
        const filteredJobs = Jobs.filter(job =>
            !currentFilters.category || job.departments.some(dept => currentFilters.category.includes(String(dept.parent_id)))
        );

        const groupedDepts = organizeDepartmentsByName(filteredJobs, currentFilters.category, t);
        setGroupedDepartments(groupedDepts);

        const deptSet = new Set();
        filteredJobs.forEach(job => job.departments.forEach(dept => deptSet.add(JSON.stringify(dept))));
        const sortedDepts = Array.from(deptSet).map(dept => JSON.parse(dept)).sort((a, b) => a.name.localeCompare(b.name));
        setDepartments(sortedDepts);

        const filteredLocations = new Set();
        filteredJobs.forEach(job => {
            if (job.location && job.location.name) filteredLocations.add(job.location.name);
        });

        setLocations(prioritizeLocations(Array.from(filteredLocations).sort()));
    }, [Jobs, currentFilters, t]);

    useEffect(() => {
        updateFilterOptions();
    }, [updateFilterOptions]);

    const getSelectedDepartmentName = useCallback(() => {
        if (!currentFilters.department) return 'all';
    
        const selectedID = Number(currentFilters.department);
    
        for (const [name, ids] of Object.entries(groupedDepartments)) {
            if (ids.includes(selectedID)) {
                return name;
            }
        }
    
        return 'all';
    }, [currentFilters.department, groupedDepartments]);
    

    const handleCategoryChange = useCallback((e) => {
        const value = (e.target.value)
        handleFilterChange('category', value === 'all' ? '' : value);
    }, [handleFilterChange]);

    const handleDepartmentChange = useCallback((e) => {
        const value = e.target.value;
        const selectedIDs = groupedDepartments[value] || [];
        handleFilterChange('department', value === 'all' ? '' : selectedIDs.length >= 1 ? selectedIDs.join(',') : value);
    }, [groupedDepartments, handleFilterChange]);

    const handleLocationChange = useCallback((e) => {
        handleFilterChange('location', e.target.value === 'all' ? '' : e.target.value);
    }, [handleFilterChange]);

    useEffect(() => {
        if (
          initialRender.current &&
          departments.length > 0 &&
          locations.length > 0 &&
          Object.keys(categoryMappings).length > 0
        ) {
          const urlParams = new URLSearchParams(window.location.search);
          const urlCategory = urlParams.get('category');
          const urlDepartment = urlParams.get('department');
          const urlLocation = urlParams.get('location');
      
          if (urlCategory) {
            const mappedCategory = categoryMappings[urlCategory] || 'all';
            handleCategoryChange({ target: { value: mappedCategory.join(',') } });
          }
          if (urlDepartment) {
            const deptExists = departments.some(
              dept => String(dept.id) === urlDepartment || dept.name.toLowerCase() === urlDepartment.toLowerCase()
            );
            handleDepartmentChange({ target: { value: deptExists ? urlDepartment : 'all' } });
          }
          if (urlLocation) {
            const locExists = locations.includes(urlLocation);
            handleLocationChange({ target: { value: locExists ? urlLocation : 'all' } });
          }
      
          // Mark initial render as completed
          initialRender.current = false;
        }
      }, [departments, locations, categoryMappings]);
      

    return (
        Jobs.length > 0 && (
            <JobsFilterContainer>
                <JobsFilterForm>
                    <FormContent>
                        <label htmlFor="category">{t('careersPage.jobFilters.Categories')}</label>
                        <JobsFilterDropdown
                            name="category"
                            onChange={handleCategoryChange}
                            value={currentFilters.category || 'all'}
                        >
                            <option key="all-category" value="all">
                                {t('careersPage.jobFilters.AllCategories')}
                            </option>
                            {Object.keys(categoryMappings).map(category => (
                                <option
                                    key={`category-${category}`}
                                    value={categoryMappings[category]}
                                >
                                    {t(`resources.categories.${category}`, category)}
                                </option>
                            ))}
                        </JobsFilterDropdown>
                    </FormContent>

                    <FormContent>
                        <label htmlFor="department">{t('careersPage.jobFilters.Departments')}</label>
                        <JobsFilterDropdown
                            name="department"
                            onChange={handleDepartmentChange}
                            value={getSelectedDepartmentName()}
                        >
                            <option key="all-department" value="all">
                                {t('careersPage.jobFilters.AllDepartments')}
                            </option>
                            {Object.keys(groupedDepartments).map(name => (
                                <option key={`department-${name}`} value={name}>
                                    {name}
                                </option>
                            ))}
                        </JobsFilterDropdown>
                    </FormContent>

                    <FormContent>
                        <label htmlFor="location">{t('careersPage.jobFilters.Locations')}</label>
                        <JobsFilterDropdown
                            name="location"
                            onChange={handleLocationChange}
                            value={currentFilters.location || 'all'}
                        >
                            <option key="all-location" value="all">
                                {t('careersPage.jobFilters.AllLocations')}
                            </option>
                            {locations.map(loc => (
                                <option key={`location-${loc}`} value={loc}>
                                    {t(`resources.locations.${loc.replace(/[\s,]+/g, '')}`, loc)}
                                </option>
                            ))}
                        </JobsFilterDropdown>
                    </FormContent>
                </JobsFilterForm>
            </JobsFilterContainer>
        )
    );
}
