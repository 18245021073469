import styled from 'styled-components';

export const HeroContainer = styled.div`
    position: relative;
    width: 100%;
    background-image: 
    linear-gradient(to right, #003337E0, #00333700 100%),
    url(${props => props.$imgurl});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    justify-content: center;
    background-color: var(--color-white);
    color: var(--color-white);

    @media (max-width: 960px) {
        height: 450px;
        background-image: 
        linear-gradient(to bottom, #00333780, #00333780 100%),
        url(${props => props.$imgurlmobile});
        background-size: cover;
    }
`;