import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "300px",
  width: "600px",
  position: "relative",
  overflow: "hidden",
  backgroundColor: "rgb(229, 227, 223)",
};

const mapCenter = {
    lat: 41.035, // Center latitude between the two locations
    lng: -65, // Center longitude between the two locations
  };

// Custom style to match the muted theme of the provided image
const mapStyles = [
  {
    "featureType": "all",
    "elementType": "geometry",
    "stylers": [{ "color": "#e5e3df" }]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [{ "color": "#bcd5d8" }]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [{ "color": "#727272" }]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [{ "color": "#f5f5f5" }]
  },
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [{ "visibility": "off" }]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [{ "color": "#ffffff" }]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [{ "color": "#b8b8b8" }]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [{ "color": "#e3e3e3" }]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [{ "color": "#eeeeee" }]
  },
];

const iconSvg = {
    path: "M12 2C8.13 2 5 5.13 5 9c0 4.48 4.73 10.74 6.39 12.87.38.51 1.23.51 1.61 0C14.27 19.74 19 13.48 19 9c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z",
    fillColor: "#000", // Set your custom color here (e.g., green)
    fillOpacity: 1,
    scale: 1.5, // Adjust scale as needed
    strokeColor: "#000",
    strokeWeight: 1,
    anchor: { x: 12, y: 21 }
  };

// Locations with coordinates for markers
const locations = {
  usa: {
    position: { lat: 36.5397104, lng: -86.8972517 },
    title: "Bath Fitter - USA",
    iconSvg: { ...iconSvg, fillColor: "#4CAF50", strokeColor: "#4CAF50" } ,
  },
  canada: {
    position: { lat: 45.5636092, lng: -73.9287075 },
    title: "Bath Fitter - Canada",
    iconSvg: { ...iconSvg, fillColor: "#4CAF50", strokeColor: "#4CAF50" } ,
  }
};

const MapComponent = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_MAPKEY,
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div id="mainGMap" className="map-container" style={mapContainerStyle}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={4}
        center={mapCenter}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          fullscreenControl: false,
          styles: mapStyles,
          minZoom: 4,
          maxZoom: 15,
        }}
      >
        {/* Add markers for each location */}
        {Object.values(locations).map((location, index) => (
          <Marker
            key={index}
            position={location.position}
            title={location.title}
            icon={location.iconSvg}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default MapComponent;
