import React from 'react';
import { HeroContainer } from './HeroSection.styles.js';
import { 
  PrimaryHeading, 
  Paragraph, 
  Container 
} from '../common/Common.styles.js';

const DetailsHero = ({
  desktopImage,
  mobileImage,
  jobname,
  cat = '',
  dep = '',
  loc = '',
  maxWidth = '465px',
}) => (
  <HeroContainer $imgurl={desktopImage} $imgurlmobile={mobileImage}>
    <Container>
      <PrimaryHeading>
        {jobname}
      </PrimaryHeading>
      {cat === 'resources.categories.unknown' || dep === 'resources.departments.unknown' ? null : (
      <Paragraph style={{ maxWidth }}>
        {cat} -  {dep}
      </Paragraph>
        )}
      <Paragraph style={{ maxWidth }}>
        {loc}
      </Paragraph>
    </Container>
  </HeroContainer>
);

export default DetailsHero;
