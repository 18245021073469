import { ResourcesFr } from './ResourcesFR';

// Usage: import { Fr } from '../assets/translation/Fr.js';
export const Fr = {
    header: {
        home: 'Accueil',
        hiring: 'Nous embauchons',
        about: 'Notre culture',
        whoweare: 'Qui nous sommes',
        careers: 'Carrières',
        contact: 'Contactez-nous',
    },
    testimonial: {
        testimonial1Quote: "Les valeurs de l’entreprise font de Bain Magique un endroit tellement agréable où travailler! On y trouve une belle ambiance de travail, un excellent esprit d’équipe et les employés sont vraiment considérés. En plus, j’adore mon patron!",
        testimonial1Author: "Manon Céleste",
        testimonial1Role: "Affaires Juridiques",

        testimonial2Quote: "J’aime travailler chez Bain Magique parce que l’entreprise offre un environnement de travail des plus stimulants. Nous sommes en constante évolution et apprenons sans cesse des nouvelles techniques qui redéfinissent les rénovations d’une salle de bain. C’est une entreprise où il est agréable et gratifiant de bâtir une longue carrière.",
        testimonial2Author: "Mark Reilly",
        testimonial2Role: "Expédition",

        testimonial3Quote: "Comme membre de l’équipe de Bain Magique, je sens que je peux m’exprimer et que mon opinion est importante. Le fait de savoir que je suis appréciée me motive à faire de mon mieux. Une grande culture, une vision solide et des valeurs fondamentales qui correspondent à mes convictions personnelles.",
        testimonial3Author: "Tammy Woods",
        testimonial3Role: "Ressources Humaines",

        testimonial5Quote: "Lorsque j’ai commencé chez Bain Magique, je ne pensais jamais que je finirais par y rester pendant 19 ans! Ici, nous ne travaillons pas pour Bain Magique, nous travaillons à fournir aux clients des produits de grande qualité et des expériences inoubliables.",
        testimonial5Author: "Christian Coté",
        testimonial5Role: "Services Techniques",

        testimonial6Quote: "Travailler chez Bain Magique c’est stimulant. Notre créativité et notre imagination sont toujours les bienvenues et elles sont appréciées. J’aime l’implication sociale de l’entreprise au sein de la communauté, j’aime l’engagement de l’équipe de gestion, et ce à tous les niveaux et j’aime le sentiment familial qui règne au travail. J’aime tellement ça que je suis revenue y travailler.",
        testimonial6Author: "Sylvie Lépine",
        testimonial6Role: "Ressources Humaines",

        testimonial7Quote: "Je suis vraiment reconnaissante envers Bain Magique parce qu’il y a quatre ans, ils ont remarqué quelque chose en moi que je ne voyais pas moi-même. J’ai eu la chance d’évoluer avec l’entreprise et d’être reconnue pour la contribution que j’y apportais.",
        testimonial7Author: "Bethany Taylor",
        testimonial7Role: "Ressources Humaines",

        testimonial8Quote: "J'aime travailler chez Bain Magique parce qu'ici, je me sens et je suis écouté. Je sais que je peux partager mes idées avec mes collègues et la direction et quelles seront prises en considération. Nous sommes un TOUT.",
        testimonial8Author: "Yannick Levasseur",
        testimonial8Role: "Inventaire",

        testimonial9Quote: "J’aime la façon dont l’usine traite la planète. Je suis une personne « écolo » et j’apprécie beaucoup ce point. J’aime aussi beaucoup l’engagement communautaire. Je suis fière de ce que j’accomplis. Je sais que quelqu’un va payer pour ce que je fabrique alors je le fais de mon mieux afin que Bain Magique demeure parmi les meilleurs.",
        testimonial9Author: "Maria Meguiar",
        testimonial9Role: "Thermoformage",

        testimonial10Quote: "En tant qu’installateur chez Bain Magique, chaque jour représente une nouvelle aventure pour moi. À la fin d’une journée de travail, c’est tellement gratifiant de constater le fruit de ses efforts et de voir le sourire des clients lorsqu’ils découvrent leur nouvelle salle de bain.",

        testimonial11Quote: "Ce que j’aime le plus de mon travail d’installateur chez Bain Magique, c’est de voir le visage des clients lorsqu’ils découvrent leur nouvelle salle de bain.",
    },
    homePage: {
        heroSlide1Title: "VOTRE PLACE EST",
        heroSlide1TitleHighlight: "ici", heroSlide1DescriptionLine1: "Rejoignez une équipe dédiée à l'excellence et à la satisfaction client.", heroSlide1DescriptionLine2: "Nous sommes toujours à la recherche de personnes talentueuses pour rejoindre notre équipe.",
        heroSlide2Title: "VOTRE CARRIÈRE COMMENCE",
        heroSlide2TitleHighlight: "ici",
        heroSlide2DescriptionLine1: "Nous offrons un salaire compétitif, des avantages sociaux et un excellent environnement de travail.",
        heroSlide2DescriptionLine2: "Rejoignez-nous et aidez-nous à faire une différence dans la vie de nos clients.",
        heroSlide1ButtonLabel: "VOIR LES EMPLOIS",
        heroSlide2ButtonLabel: "Découvrir plus",

        mediaContentTitle: "L’univers Bain Magique",
        mediaContentDescription: "Bienvenue chez Bain Magique. Découvrez qui nous sommes, ce que nous faisons et comment nous le faisons! Entrez dans l’environnement de travail dynamique qui se cache derrière nos produits et consultez nos multiples opportunités d’emploi. Une carrière prometteuse vous attend!",
        mediaContentCtaLabel: "En savoir plus",

        awardsTitle: "MEILLEUR EMPLOYEUR, C'EST CERTIFIÉ !",

        fullWidthInfoDescription: "Notre mission est de faire sourire les gens à chaque fois qu'ils voient leur baignoire.",

        mediaContent2Title: "Notre candidat idéal",
        mediaContent2Description: "Nous sommes toujours à la recherche de nouveaux talents pour compléter nos équipes de passionnés pour nos secteurs administratif et de production ainsi que pour notre réseau de détail. Nous unissons nos forces afin d’offrir des produits et un service à la clientèle incomparables. Cette culture d’entreprise vous interpelle? Nous souhaitons vous connaître!",
        mediaContent2CtaLabel: "Rejoindre l'équipe",

        ctaSectionTitle: "Prêt à rejoindre l'équipe ?",
        ctaSectionDescription: "Nous sommes toujours à la recherche de personnes talentueuses pour rejoindre notre équipe. Rejoignez-nous et aidez-nous à faire une différence dans la vie de nos clients.",
        ctaSectionCtaLabel: "Voir les postes ouverts",
        ctaSectionCta2Label: "En savoir plus",
    },
    culturePage: {
        heroTitle: "Notre Culture",
        heroDescriptionLine1: "Chez Bain Magique, nous sommes engagés envers l'excellence et la satisfaction du client. Nos employés sont la force motrice derrière notre succès. Nous sommes toujours à la recherche de personnes talentueuses pour rejoindre notre équipe.",
        ourCulture: "LA VISION BAIN MAGIQUE",
        ourCultureDescription: "Dès le début de notre processus de recrutement, nous sélectionnons des gens qui excellent dans le travail d’équipe et qui sont prêts à donner le meilleur d’eux-mêmes. Nous croyons que les carrières sont appelées à évoluer, c’est pourquoi Bain Magique élabore des programmes de développement qui encouragent activement l’avancement et le perfectionnement de ses employés. Nous incarnons les valeurs fondamentales de Bain Magique qui inspirent et guident la culture de notre entreprise.",
        ourCultureCta: "Rejoindre l'équipe",
        safety: "LA SANTÉ ET SÉCURITÉ AVANT TOUT!",
        safetyDescription: "Bain Magique ne fait aucun compromis sur la santé et la sécurité de ses employés. Quel que soit le lieu où le travail est exécuté, le travail sécuritaire est toujours priorisé.",
        safetyCta: "À PROPOS DE NOUS",
        applyDescription: "DÉCOUVREZ LES OPPORTUNITÉS D'EMPLOI POUR VOUS!",
        applyCta: "VOIR LES OPPORTUNITÉS",
        infoCarouselTitle: "REDONNER À LA COMMUNAUTÉ",
        infoCarouselDescription: "Faire carrière chez Bain Magique, c’est aussi redonner à la communauté à chaque opportunité. Ayant le mieux-être de tous à cœur, Bain Magique a mis en place un programme de collecte de fonds qui permet de soutenir une multitude de causes chaque année. Comme nos employés sont dévoués, nous sommes heureux d’appuyer chacune de leurs initiatives visant à aider les gens dans le besoin.",
    },
    whowearePage: {
        heroTitle: "Qui Nous Sommes",
        heroDescriptionLine1: "Bain Magique est une entreprise dédiée à l'excellence et à la satisfaction de sa clientèle. Nos employés sont la force motrice de notre succès. Nous sommes toujours à la recherche de personnes talentueuses pour joindre notre équipe.",
        mediaContentTitle: "NOTRE HISTOIRE",
        mediaContentDescription: "Depuis 1984, Bain Magique fabrique et installe des revêtements de baignoires et des systèmes de mur monopièce en acrylique de qualité supérieure. Grâce à notre processus unique d’installation de baignoire par-dessus baignoire, nous avons transformé plus de 2 millions de salles de bain, et ce chiffre augmente tous les jours! Nos produits sont fabriqués dans nos usines du Canada et des États-Unis et sont représentés dans plus de 230 succursales afin de servir notre clientèle.",
        mediaContentCtaLabel: "REJOINDRE L'ÉQUIPE",
    },
    divisions: {
        mainTitle: 'SECTEURS BAIN MAGIQUE',
        mainDescription: 'Nous grandissons rapidement et nous avons d’innombrables possibilités de carrières passionnantes dans les secteurs administratif, de production ainsi qu’au sein de notre large réseau de détail.',
        admin: {
            title: 'ADMINISTRATION',
            description: "Découvrez un vaste éventail de possibilités de carrières stimulantes dans un de nos bureaux administratifs.",
            imageAlt: 'Professionnel administratif au travail',
            buttonText: 'CARRIÈRES ADMINISTRATIVES',
            list: {
                hr: 'Ressources humaines',
                customerService: 'Service à la clientèle',
                training: 'Formation et développement',
                rd: 'Recherche et développement',
                finance: 'Finance',
                marketing: 'Marketing',
                legal: 'Affaires juridiques',
                it: 'Technologies de l\'information',
                supplyChain: 'Chaîne d\'approvisionnement',
                businessDev: 'Développement des affaires'
            }
        },
        plant: {
            title: 'PRODUCTION',
            description: 'Nos deux usines modernes présentent plusieurs opportunités d’emplois comportant de multiples possibilités d’avancement.',
            imageAlt: 'Installation de production',
            buttonText: 'CARRIÈRES EN PRODUCTION',
            list: {
                adhesive: 'Adhésif',
                maintenance: 'Entretien',
                extrusion: 'Extrusion',
                thermoforming: 'Thermoformage',
                shipping: 'Expédition / Livraison',
                displays: 'Présentoirs',
                quality: 'Qualité',
                composites: 'Composites'
            }
        },
        retail: {
            title: 'VENTE AU DÉTAIL',
            description: "Vous trouverez diverses possibilités de carrière dans les magasins Bain Magique d’un bout à l’autre du Canada et des États-Unis.",
            imageAlt: 'Membre de l\'équipe de vente au détail',
            buttonText: 'CARRIÈRES EN VENTE AU DÉTAIL',
            list: {
                installer: 'Installateur',
                installerHelper: 'Gérant de succursale',
                leadInstaller: '',
                branchManager: '',
                salesConsultant: 'Conseiller aux ventes résidentielles',
                commercialSales: 'Conseiller aux ventes commerciales',
                adminCoordinator: 'Coordonnateur administratif',
                insideSales: 'Représentant aux ventes internes',
                eventManager: '',
                eventWorker: '',
                plumber: 'Plombier',
                shopHand: '',
            }
        }
    },
    contactPage: {
        heroTitle: "NOUS JOINDRE",
        heroDescription: "Nous sommes là pour vous aider. Envoyez-nous un message et nous vous répondrons dès que possible.",
        sectionTitle: "POUR NOUS JOINDRE",
        sectionDescription: "Voici toutes les informations dont vous avez besoin pour nous contacter.",
        sectionDescription2: "Pour toute question ou demande de renseignements supplémentaires, n’hésitez pas à communiquer avec nous.",
        faqDesc: "Nous avons les réponses! Visitez notre page FAQ pour obtenir les réponses aux questions courantes.",
        questions: "VOUS AVEZ DES QUESTIONS?",
        faq: "FAQ",
    },
    contactForm: {
        title: "Contactez-nous",
        description: "Nous sommes là pour vous aider. Envoyez-nous un message et nous vous répondrons dès que possible.",
        link: "cliquez ici.",
        placeholders: {
            firstName: "Prénom*",
            lastName: "Nom*",
            email: "Courriel*",
            phone: "Numéro de téléphone*",
            message: "Votre message*"
        },
        submit: "ENVOYER LE MESSAGE",
        consent: {
            text: "En soumettant vos informations personnelles via ce formulaire, vous acceptez que nous conservions, hébergions, partagions et utilisions vos informations conformément à notre politique de confidentialité. Consultez notre ",
            privacyLink: "Politique de confidentialité",
        }
    },
    installers:{
        heroTitle: "Explorez toutes les possibilités",
        heroDescriptionLine1: "Votre carrière d’installateur vous appelle et vous pouvez y répondre en une seule étape.",
        ctaButton: "JOIGNEZ-VOUS À NOTRE ÉQUIPE",
    
        mediaContentTitle: "ÉCOUTEZ CE QUE NOS INSTALLATEURS ONT À DIRE...",
        mediaContentDescription: "À propos des opportunités d’avancement de carrière, de leurs défis et de ce que leur travail représente pour eux sur les plans personnels et professionnels.",
        ctaLabel: "POSTULEZ MAINTENANT",
    
        benefitsSectionTitle: "Une opportunité à ne pas manquer",
        benefitsCard1Title: "Rémunération supérieure à la moyenne",
        benefitsCard2Title: "Nombreux avantages",
        benefitsCard3Title: "Formation et véhicule fournis par l'employeur",
        benefitsCard4Title: "Emploi à temps plein à l'intérieur",
    
        whyBathfitterTitle: "Pourquoi Bain Magique?",
        whyBathfitterDescription: "Nous connaissons une croissance fulgurante grâce à nos succursales situées partout en Amérique du Nord. La popularité de notre procédé d’installation en une seule journée, tout à fait unique, la très haute qualité de nos produits et notre garantie exceptionnelle ont fait de Bain Magique la marque numéro un dans l’industrie de la rénovation de salle de bain dans les résidences privées, hôtels, motels et immeubles multirésidentiels.",
        whyBathfitterCta: "NOTRE CULTURE",
    
        idealCandidateTitle: "Notre candidat idéal",
        idealCandidateDescription: "Nous recherchons un candidat qui peut prendre des mesures précises et qui est à l'aise avec l'utilisation d'outils électriques. La personne idéale est un apprenant rapide avec une attitude positive, qui aime interagir avec les gens et qui est fier de son apparence. Bien que l'expérience en menuiserie ou en construction soit utile, elle n'est pas requise. Un dossier de conduite exemplaire est essentiel, car nous valorisons la responsabilité et la fiabilité chez les membres de nos équipes.",
        idealCandidateCta: "APPLIQUEZ COMME INSTALLATEUR",
      },
    faq: {
        description: "Peu importe la quantité d'informations disponibles, il y aura toujours des questions. Nous sommes là pour vous aider. Cependant, avant de nous contacter, veuillez consulter la liste des questions les plus fréquemment posées ci-dessous. Cela nous permettra de consacrer plus de temps à répondre aux questions spécifiques et vous évitera d'attendre notre réponse.",
        questions: [
            {
                question: "Offrez-vous des opportunités de stage chez Bain Magique?",
                answer: "Bain Magique est toujours à la recherche de nouveaux talents et ce, à tous les niveaux. Si vous souhaitez faire un stage au sein de notre équipe, n'hésitez pas à envoyer votre CV ainsi que les détails relatifs au stage à notre équipe de recrutement à l'adresse courriel suivante: ",
                emailText: "carrieres@bainmagique.com",
                afterEmail: ""
            },
            {
                question: "Comment puis-je postuler chez Bain Magique?",
                answer: "Il y a plusieurs façons de postuler chez Bain Magique. ",
                linkText: "Consultez la page « Nous embauchons »",
                link: "/fr/carrieres",
                afterLink: " et déposez votre candidature dès maintenant!"
            },
            {
                question: "Offrez-vous de la formation pour les employés?",
                answer: "La formation et le développement de nos employés font partie intégrante de notre culture. Nous vous soutenons dès l'entrée en poste et nous misons sur votre développement tout au long de votre carrière afin de vous permettre d'accéder à de nouvelles opportunités d'avancement."
            },
            {
                question: "Combien de bureaux/succursales Bain Magique possède-t-elle?",
                answer: "Nous avons des bureaux et des usines à Saint-Eustache (Québec) et à Springfield (Tennessee), ainsi que des centaines de succursales au Canada et aux États-Unis."
            },
            {
                question: "Offrez-vous des avantages sociaux?",
                answer: "Bain Magique offre plusieurs programmes d’avantages sociaux complets.",
                linkText: "Consultez notre page « Nous embauchons »",
                link: "/fr/carrieres",
                afterLink: ", postulez à un emploi qui vous intéresse et nos recruteurs se feront un plaisir de vous donner plus de détails."
            },
            {
                question: "Les employés ont-ils des possibilités d'avancement chez Bain Magique?",
                answer: "Bain Magique se fait un devoir d'offrir des possibilités d'avancement à ses employés. Nous proposons des programmes de formation continue dont l'objectif est d'encourager et d'aider nos employés à progresser dans leur carrière."
            },
            {
                question: "Comment puis-je recommander une candidature pour un poste chez Bain Magique?",
                answer: "Vous pouvez recommander une candidature en déposant le CV de la personne proposée à l'une de nos succursales ou en la dirigeant vers notre site carrière, où elle pourra postuler en ligne."
            }
        ]
    },
    reasonsToWork: {
        title: "POURQUOI VOUS JOINDRE À NOUS?",
        reasons: [
            {
                title: "OPPORTUNITÉS DE DÉVELOPPEMENT",
                description: "Chaque jour, vous serez amené à vous dépasser et à donner le meilleur de vous-même. Grâce à des programmes de formation continue et de développement professionnel, nous soutenons tous nos employés dans le développement de leurs talents en leur donnant accès à de multiples opportunités d’avancement de carrière."
            },
            {
                title: "CULTURE",
                description: "Faire carrière chez Bain Magique, c’est faire partie d’une équipe gagnante, engagée et passionnée! C’est aussi avoir l’opportunité de s’accomplir tout en ayant du plaisir, ce qui reflète réellement la mission, la vision et les valeurs de notre entreprise.",
                link: "Jetez un coup d'œil"
            },
            {
                title: "RECONNAISSANCE",
                description: "Grâce à nos programmes internes, nous reconnaissons et récompensons les employés pour tous leurs bons coups. Nous encourageons le travail d’équipe, l’innovation, la fidélité, le dévouement et encore plus!"
            },
            {
                title: "SENTIMENT DE FIERTÉ AU TRAVAIL",
                description: "Nos clients sont impressionnés par l’expérience Bain Magique et par l’incroyable transformation de leur salle de bain. Travailler chez Bain Magique, c’est faire partie intégrante de cet immense succès peu importe le poste occupé et c’est ce qui rend nos employés si fiers et dévoués!"
            },
            {
                title: "ENGAGEMENT COMMUNAUTAIRE",
                description: "Bain Magique appuie et encourage activement les employés qui s’intéressent et veulent redonner à la communauté."
            }
        ]
    },
    values: {
        card_1_title: "Mission",
        card_1_description: "Faire sourire les gens... chaque fois qu’ils voient leur baignoire.",
        card_2_title: "Vision",
        card_2_description: "Être un excellent employeur et un excellent partenaire d’affaires.",
        card_3_title: "Valeurs",
        card_3_description: "Souci de la clientèle, fierté, respect, confiance, esprit d’équipe, débrouillardise, attitude positive, axé sur les résultats.",
    },
    careersPage: {
        weare: 'NOUS',
        hiring: 'embauchons',
        description_line_1: 'Nous sommes toujours à la recherche de personnes talentueuses pour rejoindre notre équipe.',
        description_line_2: 'Rejoignez-nous et aidez-nous à faire une différence dans la vie de nos clients.',
        openPositions: 'Voir les postes disponibles',
        InvestInYourFuture: 'Investissez dans votre avenir',
        InvestInYourFutureDescription: 'Nous sommes dédiés à l’excellence et à la satisfaction de nos clients. Nos employés sont la force motrice de notre succès. Nous recherchons constamment des individus talentueux pour rejoindre notre équipe. Nous offrons un salaire compétitif, des avantages sociaux, et un excellent environnement de travail.',
        card_1_title: 'Salaire compétitif',
        card_1_description: 'Nous offrons un salaire et un package d’avantages sociaux compétitifs.',
        card_2_title: 'Croissance professionnelle',
        card_2_description: 'Nous offrons des opportunités de croissance et d’avancement professionnels.',
        card_3_title: 'Excellent environnement de travail',
        card_3_description: 'Nous offrons un excellent environnement de travail et une équipe solidaire.',
        card_4_title: 'L’union fait la force',
        card_4_description: 'Nous croyons au travail d’équipe et à la collaboration.',
        installerApplyButton: 'En savoir plus',
        installerApplyDescription: 'Votre carrière d’installateur vous appelle et vous pouvez y répondre en une seule étape.',
        yourCareer: 'Opportunités de carrière',
        jobFilters: {
            Categories: 'Catégories',
            Locations: 'Lieux',
            Departments: 'Départements',
            AllCategories: 'Toutes les catégories',
            AllLocations: 'Tous les lieux',
            AllDepartments: 'Tous les départements',
        },
        jobsList: {
            loading: 'Chargement...',
            error: 'Erreur : {error.message}',
            notFound: 'Emploi non trouvé',
        },
    },
    jobDetailsPage: {
        title: 'Détails de l’emploi',
        description: 'Description de l’emploi',
        loading: 'Chargement...',
        error: 'Erreur : {error.message}',
        notFound: 'Emploi non trouvé',
        locationQuestions: 'Questions sur le lieu',
        applyNow: 'Postuler maintenant',
        apply: 'Postuler',
        uploadFile: 'Télécharger',
    },
    privacyPolicy: {
        r: "Bain Magique®",
        pageTitle: "Avis de confidentialité pour les employés",
        intro:
            "Cet avis informe les employés de Bain MagiqueDistributing Inc. et de ses affiliés sur la façon dont nous recueillons, utilisons, divulguons et conservons leurs renseignements personnels.",
        objectives: {
            title: "1.0 Objectif",
            description:
                "Le présent Avis informe les employés de Bath Fitter Distributing Inc. et de ses sociétés affiliées (collectivement, « BF », « nous », « notre » ou « nos ») de la façon dont BF recueille, utilise, divulgue et conserve leurs renseignements personnels."
        },
        scope: {
            title: "2.0 Portée",
            description:
                "Le présent Avis s'applique à tous les employés de BF. Aux fins du présent Avis, le terme « employés » inclut également les candidats à un emploi et les entrepreneurs de BF."
        },
        personalInfo: {
            title: "3.0 Renseignements personnels – Définition et exceptions",
            description:
                "Dans le présent Avis, le terme « renseignements personnels » désigne tout renseignement concernant une personne identifiable. Les renseignements personnels peuvent inclure les types de renseignements détaillés à la section 5 du présent Avis."
        },
        generalPolicy: {
            title: "4.0 Déclaration de politique générale",
            description:
                "BF s'engage à traiter les renseignements personnels des employés de BF conformément à la législation applicable en matière de protection des renseignements personnels en fonction du lieu de résidence de l'employé."
        },
        collectionOfInfo: {
            title: "5.0 Collecte de renseignements personnels",
            description:
                "BF recueille les renseignements personnels des employés tel que nécessaire pour établir, gérer et mettre fin à leur emploi ou à leur relation contractuelle avec BF. Les renseignements personnels que BF recueille au sujet de ses employés peuvent inclure les éléments suivants:",
            list: [
                "Identifiants et coordonnées, tels que votre nom, votre adresse courriel et postale, votre numéro de téléphone, vos pièces d'identité gouvernementales (telles que votre numéro d'assurance sociale, les renseignements relatifs à votre visa et votre passeport), vos permis de travail ou de résidence, votre adresse IP (protocole Internet) (si elle peut vous identifier en tant qu'individu), l’identifiant de votre appareil (par exemple, si vous vous connectez à notre réseau Wi-Fi), les renseignements relatifs à vos comptes (par exemple, si vous créez un compte pour utiliser une application d'entreprise). Nous pouvons également recueillir des renseignements sur vos personnes à contacter en cas d'urgence.",
                "Renseignements démographiques, tels que la date de naissance, le genre, la citoyenneté, les renseignements médicaux et les renseignements relatifs aux handicaps. Nous pouvons recueillir des renseignements sur votre origine ethnique sur une base volontaire conformément aux lois applicables.",
                "Renseignements professionnels ou liés à l'emploi, y compris: l'historique des postes et de la rémunération, l'horaire et le statut de travail, l'expérience professionnelle, la scolarité, les permis d'exercice et autres titres de compétences, les renseignements sur les avantages sociaux et les congés, les renseignements relatifs à toute question juridique ou litige pouvant survenir, les rapports et évaluations de rendement, les résultats des tests psychométriques, les dossiers disciplinaires, la date et les raisons (volontaires ou involontaires, et détails) de la cessation d’emploi.",
                "Renseignements financiers, tels que l'institution financière, le numéro de compte bancaire et les informations nécessaires à l'émission des formulaires fiscaux.",
                "Renseignements sur les bénéficiaires et les personnes à charge, tels que leur nom, leurs coordonnées et leur date de naissance.",
                "Renseignements relatifs à la vérification des antécédents, y compris les antécédents judiciaires.",
                "Données de géolocalisation, telles que les renseignements de localisation GPS, et les types de renseignements similaires créés et générés par votre utilisation des ressources de BF.",
                "Des renseignements sur votre utilisation d'Internet, de nos réseaux, de nos appareils et de nos locaux, tels que des renseignements sur votre utilisation de votre compte de messagerie professionnel, d'Internet, des ordinateurs, téléphones et autres appareils de BF auxquels vous avez accès dans le cadre de votre emploi, et de votre propre appareil dans le cadre des programmes BYOD (bring your own devices) permettant son utilisation. Nous pouvons également recueillir des images vidéo et des photos par l’entremise de caméras de surveillance en circuit fermé.",
                "Autres renseignements, tels que des renseignements que l'on peut vous demander de fournir de temps à autre ou des renseignements que vous pouvez fournir par l’entremise de nos fournisseurs de services tiers (par exemple, Greehouse.com, LinkedIn)."
            ]
        },
        useOfInfo: {
            title: "6.0 Utilisation des renseignements personnels",
            description:
                "BF utilise les renseignements personnels des employés de BF à des fins nécessaires pour établir, gérer ou mettre fin à sa relation (d'emploi ou contractuelle) avec lesdits employés de BF et à d'autres fins commerciales légitimes, tel que détaillé dans le présent Avis et dans d’autres politiques et avis de BF, ou autrement permis par la législation applicable en matière de protection des renseignements personnels. Par exemple, BF peut utiliser les renseignements personnels des employés aux fins suivantes:",
            list: [
                "L'administration du personnel, comme la vérification de l'identité, la gestion des impôts et de la sécurité sociale, la planification et le suivi des présences et des heures travaillées, l'établissement des coordonnées des personnes à contacter en cas d'urgence, le traitement des réclamations liées au travail des employés.",
                "Traitement des prestations, y compris la détermination de votre admissibilité et de celle de vos personnes à charge et bénéficiaires.",
                "Administration de la paie, y compris le traitement des salaires, des congés de maladie et des absences.",
                "L'examen et l'évaluation du rendement, y compris la prise de décisions concernant les affectations, la formation, la rémunération et l'aptitude aux promotions.",
                "Surveiller l'utilisation des technologies de l'information, des autres équipements et biens à des fins commerciales légitimes, telles que la sécurité, pour se protéger contre la fraude et assurer le respect de nos politiques et procédures.",
                "Assurer la santé et la sécurité, y compris le maintien d'un lieu de travail sécuritaire et la gestion des questions de santé, de sûreté et de sécurité.",
                "À des fins commerciales internes, telles que la mise en œuvre de plans de reprise après sinistre, de continuité des activités ou d'autres plans et procédures d'urgence similaires de BF; pour effectuer des examens internes, des audits et des enquêtes; pour créer des statistiques et des outils d'analyse à des fins commerciales légitimes, telles que l'amélioration des processus commerciaux et la gestion de la main-d'œuvre de BF.",
                "Préparer et gérer votre demande de visa de travail.",
                "Se conformer à nos obligations légales et réglementaires, répondre aux exigences en matière de sécurité, et à des fins de gestion des fraudes, y compris les enquêtes sur les fautes, l'inexécution des tâches et la violation du contrat de travail."
            ]
        },
        sharingOfInfo: {
            title: "7.0 Partage de renseignements personnels",
            description:
                "BF peut rendre les renseignements personnels des employés de BF disponibles à d'autres employés de BF pour une utilisation en lien avec l'exécution du travail qui leur est assigné et ainsi que d’autres tâches. Nous pouvons partager les renseignements personnels des employés de BF au sein de notre groupe d’entreprises aux fins détaillées dans le présent Avis. BF peut également divulguer les renseignements personnels des employés de BF à des tiers aux fins détaillées dans le présent Avis et dans d'autres politiques et avis de BF, ou autrement exigé ou permis par les lois applicables. Par exemple, BF peut divulguer les renseignements personnels des employés de BF:",
            list: [
                "aux organismes gouvernementaux ayant un besoin de savoir (par exemple, pour répondre à des demandes de renseignements sur l'assurance-emploi, dans le cadre d'une demande de subvention, de crédit ou de programme, etc.);",
                "aux fournisseurs de services tiers engagés par BF pour fournir des services administratifs (par exemple, des services d’audit, des services informatiques des services de paie, des services d'administration des ressources humaines, prestations et administration des avantages sociaux, ou des cabinets de services professionnels comme des cabinets d'avocats ou des cabinets de comptables qui fournissent des services professionnels à BF). BF s'efforcera de protéger les renseignements personnels divulgués à des tiers au moyen d'ententes contractuelles exigeant que ces tiers respectent des procédures de confidentialité et de sécurité;",
                "aux tiers concernés lorsqu'il est nécessaire d'effectuer des vérifications de références ou d'antécédents ou lorsque cela est contractuellement requis par un client ou un fournisseur de BF; et",
                "aux tiers concernés dans le cadre d'une transaction commerciale potentielle ou réalisée (par exemple, un achat/vente d'entreprise ou d'actifs, une fusion ou une absorption, ou une opération de financement) à laquelle BF est partie."
            ],
            addition: "De plus, BF coopérera avec les organismes chargées de l’application de la loi et se conformera à toute ordonnance d'un tribunal ou loi exigeant la divulgation de renseignements personnels, sans aucun autre préavis supplémentaire aux employés de BF concernés ni consentement de leur part."
        },
        crossBorderTransfer: {
            title: "8.0 Transfert transfrontalier de renseignements personnels",
            description:
                "Étant donné que nous exploitons une entreprise mondiale, les renseignements personnels des employés de BF peuvent être transférés, traités et stockés en dehors de votre pays de résidence, et par conséquent peuvent être accessibles aux autorités gouvernementales en vertu d'ordonnances légales et de lois applicables dans ces juridictions étrangères."
        },
        monitoring: {
            sectionTitle: "9.0 Surveillance",
            useOfResources: {
                title: "9.1 Utilisation des ressources informatiques de BF",
                description:
                    "Tous les documents, dossiers, données et renseignements créés, traités, stockés ou transmis à l'aide des ressources informatiques de BF (par exemple, les installations, l'équipement, les appareils et les services, y compris les courriels, la messagerie vocale, Internet et l'accès aux applications infonuagiques), ou sur des appareils personnels utilisés à des fins professionnelles, sont présumés être liés au travail. Les employés de BF n'ont aucune attente en matière de confidentialité à l'égard de ces documents, dossiers, données ou renseignements, qu'ils soient ou non marqués comme « personnels », « privés » ou « confidentiels ». BF se réserve le droit de surveiller toute utilisation des ressources informatiques de BF et d'accéder, d'utiliser, de divulguer et de conserver tous les documents, dossiers, données et renseignements créés, traités, stockés ou transmis à l'aide des ressources informatiques de BF ou sur des appareils personnels utilisés à des fins professionnelles, sans aucun autre préavis supplémentaire aux employés de BF concernés ni consentement de leur part. Toutes les ressources informatiques de BF doivent être utilisées conformément aux politiques de sécurité de l'information pertinentes de BF."
            },
            videoMonitoring: {
                title: "9.2 Surveillance",
                description:
                    "BF peut procéder à la surveillance vidéo de ses lieux de travail physiques à des fins commerciales (y compris à des fins de sûreté et de sécurité). BF peut également enregistrer des conversations, écrites ou orales, d'employés de BF utilisant les ressources informatiques de BF à diverses fins commerciales (y compris à des fins d'assurance qualité), et partager ces enregistrements avec des fournisseurs de services tiers à des fins de stockage ou d'analyse/stratégie commerciale."
            }
        },
        accuracyAndCorrections: {
            title: "10.0 Exactitude, accès et correction",
            description:
                "Les employés doivent fournir à BF des renseignements personnels exacts et complets, et doivent mettre à jour leurs renseignements personnels détenus par BF rapidement après toute modification apportée à ces renseignements. Les employés peuvent demander de l’information sur leurs renseignements personnels détenus par BF, ainsi que l'accès à ceux-ci, et peuvent demander que les renseignements personnels soient corrigés s'ils sont inexacts ou incomplets, sous réserve des diverses exceptions prévues par la loi applicable (y compris lorsque l'accès doit être refusé pour protéger d'autres personnes et pour protéger les renseignements privilégiés ou confidentiels de BF). Les employés peuvent également mettre à jour leurs renseignements personnels par le biais de notre SIRH. Les employés peuvent adresser leurs demandes d'accès ou de correction de leurs renseignements personnels à l'équipe des Ressources Humaines. Les employés de BF qui ne sont pas satisfaits du résultat de leur demande adressée à l'équipe des Ressources Humaines peuvent soumettre une demande d'accès officielle directement au responsable de la protection des renseignements personnels à l’adresse privacy@bathfitter.com."
        },
        complaintsAndQuestions: {
            title: "11.0 Plaintes et questions",
            description:
                "Les employés de BF qui croient que leurs renseignements personnels ont été traités en violation du présent Avis peuvent soulever la question auprès de leur gestionnaire ou leur superviseur. Les employés de BF qui ne sont pas satisfaits du résultat de leur plainte ou de leur question peuvent faire part de leurs préoccupations au responsable de la protection des renseignements personnels à l’adresse courriel suivante: privacy@bathfitter.com."
        },
        changesToNotice: {
            title: "12.0 Modifications au présent Avis",
            description:
                "BF révise régulièrement ses politiques et procédures, et peut réviser le présent Avis de temps à autre. "
        },
        versionControl: {
            title: "Contrôle de version",
            table: {
                version: "1.0",
                effectiveDate: "14 février 2024",
                revisionDate: "14 février 2024",
                approval: "Chef de la protection des renseignements personnels"
            },
            tableHeaders: {
                version: "Version",
                effectiveDate: "Date d'entrée en vigueur",
                revisionDate: "Date de révision",
                approval: "Approbation"
            }
        }
    },
    footer: {
        residential: "RÉSIDENTIEL",
        commercial: "COMMERCIAL",
        privacy: "POLITIQUE DE CONFIDENTIALITÉ",
        sitemap: "SITEMAP",
        address: "225 Rue Roy, Saint-Eustache, QC J7R 5R5, Canada",
    },
    seo: {
        // Global
        brandName: "Bainmagique",
        separator: "|",

        // Home page
        homePage: {
            title: "Nous embauchons | Carrières Bainmagique", // ~39 chars
            description: "Rejoignez l'équipe d'experts Bainmagique. Nous offrons des salaires compétitifs et des opportunités de croissance à travers l'Amérique du Nord.", // ~120 chars
            schema: {
                jobsAvailable: "Opportunités de carrière chez Bainmagique aux États-Unis et au Canada",
                companyMission: "Faire sourire les gens chaque fois qu'ils voient leur bain"
            }
        },

        // Culture page
        culturePage: {
            title: "Notre Culture | Carrières Bainmagique", // ~37 chars
            description: "Découvrez l'engagement de Bainmagique envers l'excellence, la croissance des employés et l'implication communautaire.", // ~110 chars
            schema: {
                missionStatement: "Faire sourire les gens chaque fois qu'ils voient leur bain",
                visionStatement: "Être une excellente entreprise pour laquelle travailler et faire des affaires",
                valuesStatement: "Nous nous engageons envers l'excellence et la satisfaction du client"
            }
        },

        // Who We Are page
        whoWeArePage: {
            title: "Qui sommes-nous | Carrières Bainmagique", // ~40 chars
            description: "Découvrez l'héritage d'excellence de Bainmagique depuis 1984. Rejoignez notre équipe d'experts dédiée à la transformation des salles de bain.", // ~120 chars
        },

        // Careers/Jobs page
        careersPage: {
            title: "Opportunités de carrière | Carrières Bainmagique", // ~46 chars
            description: "Explorez les opportunités de carrière chez Bainmagique. Trouvez des postes en vente au détail, fabrication et administration.", // ~120 chars
            schema: {
                divisions: {
                    retail: "Découvrez des carrières dans nos magasins d'un océan à l'autre",
                    plant: "Joignez-vous à nos installations de fabrication à la fine pointe",
                    admin: "Découvrez les opportunités dans nos bureaux administratifs"
                },
                benefits: {
                    salary: "Salaire et avantages sociaux compétitifs",
                    growth: "Opportunités de croissance et d'avancement professionnel",
                    environment: "Équipe solidaire et excellent environnement de travail",
                    teamwork: "Culture collaborative axée sur l'excellence"
                },
                locations: {
                    usa: ["TN", "GA", "CA", "MI", "AZ", "TX", "ME", "VT", "MA", "AL", "IN", "SC", "NY", "NE", "IL", "FL", "KY"],
                    canada: ["QC", "BC"]
                }
            }
        },

        // Contact page
        contactPage: {
            title: "Contactez-nous | Carrières Bainmagique", // ~40 chars
            description: "Communiquez avec l'équipe de recrutement de Bainmagique. Nous sommes là pour répondre à vos questions sur les opportunités de carrière.", // ~125 chars
        },

        // Locations
        locations: {
            usa: {
                address: "102 Evergreen Dr, Springfield, TN 37172, États-Unis",
                country: "États-Unis"
            },
            canada: {
                address: "225 Rue Roy, Saint-Eustache, QC J7R 5R5, Canada",
                country: "Canada"
            }
        },

        faqPage: {
            title: "Foire aux questions", // ~30 chars
            description: "Consultez les questions fréquemment posées sur les opportunités de carrière chez Bainmagique.", // ~120 chars
        },

        // Common metadata
        common: {
            author: "Bainmagique",
            type: "website",
            contactEmail: "carrieres@bainmagique.com",
            organization: {
                name: "Bainmagique",
                alternateName: "Carrières Bainmagique",
                description: "Solutions premium pour baignoires et douches depuis 1984"
            }
        }
    },
    resources: ResourcesFr,
}