import styled from "styled-components";
import { Card } from "../common/Common.styles";

export const BenefitsContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
   
    @media (max-width: 960px) {
        flex-direction: column;
        text-align: center;
    }
    `;

export const BenefitsCard = styled(Card)`
    height: 250px;

    @media (max-width: 680px) {
        height: 200px;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 3rem 2rem;
    
    & h2{
    text-align: center !important;
    }
    `;