// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Fr } from './Fr';
import { En } from './En';

// Function to initialize i18n dynamically
export const initializeI18n = (language) => {
  i18n.use(initReactI18next).init({
    resources: {
      fr: { translation: Fr },
      en: { translation: En },
    },
    lng: language, // Set the language dynamically
    fallbackLng: 'en', // Fallback to 'en' if language isn't available
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });
};

export default i18n;
