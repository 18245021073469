import { FooterContainer, FooterContent, FooterContentColumn, FooterInfo, FooterLogo, FooterLinks, FooterForm, FooterInput, FooterButton, FooterBottom, FooterBottomLinks } from "./Footer.styles";
import { buildUrl } from '../../utils/urlUtils'; // Adjust path as needed
import useLanguage from '../../hooks/useLanguage';
import { useTranslation } from "react-i18next";

import BF from '../../assets/media/BathFitterLogo_White.png';
import BM from '../../assets/media/BainMagique_Logo_blanc.png';

export default function Footer() {
    const { language } = useLanguage();
    const { t } = useTranslation();

    return (
        <FooterContainer>
            <FooterContent>
                <FooterContentColumn>
                    <FooterLogo
                        src={language === 'en' ? BF : BM}
                        alt="website logo"
                    />
                    <FooterInfo>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.364 17.364 12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0ZM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                        </svg>
                        <a href={language == 'en' ? "https://maps.app.goo.gl/uJ2p1xUePt6nmGTBA" : "https://maps.app.goo.gl/FH1jf6MErK94Ykm87"}>
                            {t('footer.address')}
                        </a>
                    </FooterInfo>
                    {
                        language === 'en' && (
                            <FooterInfo>
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.364 17.364 12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0ZM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                                </svg>
                                <a href={"https://maps.app.goo.gl/FH1jf6MErK94Ykm87"}>
                                225 Rue Roy, Saint-Eustache, QC J7R 5R5, Canada
                                </a>
                            </FooterInfo>
                        )
                    }
                    <FooterInfo>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm17 4.238-7.928 7.1L4 7.216V19h16V7.238ZM4.511 5l7.55 6.662L19.502 5H4.511Z"></path>
                        </svg>
                        {
                            language === 'en' ? <a href="mailto:careers@bathfitter.com">careers@bathfitter.com</a> : <a href="mailto:carrieres@bainmagique.com">carrieres@bainmagique.com</a>
                        }
                    </FooterInfo>
                    <FooterInfo>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.94 5.001a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002ZM7 8.481H3v12.52h4V8.481Zm6.32 0H9.34v12.52h3.94v-6.57c0-3.66 4.77-4 4.77 0v6.57H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68Z"></path>
                        </svg>
                        {language === 'en' ? <a href="https://www.linkedin.com/company/bath-fitter/">Bath Fitter's LinkedIn</a> : <a href="https://www.linkedin.com/company/bain-magique/">LinkedIn de Bain Magique</a>}
                    </FooterInfo>
                </FooterContentColumn>
                <FooterContentColumn>
                    <FooterLinks>
                        {language === 'en' ? <h4>Quick Links</h4> : <h4>Liens Rapides</h4>}
                        <a href={buildUrl('/', language)}> {t('header.home')}</a>
                        <a href={buildUrl('/ourculture', language)}>  {t('header.about')}</a>
                        <a href={buildUrl('/whoweare', language)}> {t('header.whoweare')}</a>
                        <a href={buildUrl('/careers', language)}>  {t('header.careers')}</a>
                        <a href={buildUrl('/contact', language)}> {t('header.contact')}</a>
                        <a href={buildUrl('/faq', language)}>FAQ</a>
                    </FooterLinks>
                </FooterContentColumn>
                <FooterContentColumn>
                </FooterContentColumn>
            </FooterContent>
            <FooterBottom>
                <FooterBottomLinks>
                    {language === 'en' ? <a href="https://bathfitter.com/">{t('footer.residential')}</a> : <a href="https://bainmagique.com/">{t('footer.residential')}</a>}
                    <a href="https://commercial.bathfitter.com/">{t('footer.commercial')}</a>
                    <a href={buildUrl('/privacy-policy', language)}>{t('footer.privacy')}</a>
                    {/* <a href={'/sitemap.xml'}>{t('footer.sitemap')}</a> */}
                </FooterBottomLinks>
                {language === 'en' ? <p>Copyright © 1997-2024 by Bath Fitter Franchising Inc. All Rights Reserved. </p> : <p>Droits d'auteur © 1997-2024 Bath Fitter Franchising inc. Tous droits réservés.  </p>}
            </FooterBottom>
        </FooterContainer>
    );
}
