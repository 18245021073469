import styled from 'styled-components';

export const LangSwitchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffffff80;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        text-decoration: none !important;
        color: #ffffff80 !important;
        margin: 0;

        &:hover {
            color: #ffffff !important;
        }
    }

    span {
        color: #ffffff80 !important;
        margin: 0 0.5rem;
    }


    @media (max-width: 960px) {
        justify-content: flex-end;
        padding: 1rem 2rem;

        button {
            color: #00000090 !important;
            font-size: 1rem;
            font-weight: 500;

            }

        span {
           color: #00000090 !important;
        }

    }
    `;