// ScrollUpButton.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled component for the button
const Button = styled.button`
   position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: var(--color-green);
    color: var(--color-white);
    border: 2px solid var(--color-green);
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 1000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
    height: 3rem;
    width: 3rem;
    display: grid;
    place-items: center;
    opacity: ${(props) => (props.visible ? '1' : '0')};

    & svg {
        height: 2rem;
        width: 2rem;
    }

    &:hover {
       & svg {
            scale: 1.1;
       }
    }

    &:active {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        & svg {
            scale: 0.98;
        }
    }

    @media (max-width: 680px) {
        bottom: 1rem;
        right: 1rem;
    }
`;

const ScrollUpButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 600) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top when button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll
    });
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', toggleVisibility);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Button onClick={scrollToTop} visible={isVisible} aria-label="Scroll to top">
    <svg width="32" height="32" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M5.25 15.375 12 8.625l6.75 6.75"></path>
</svg>
    </Button>
  );
};

export default ScrollUpButton;