import { ResourcesEn } from "./ResourcesEn";

// Usage: import { En } from '@/assets/translation/En'
export const En = {
  header: {
    home: "Home",
    hiring: "We are hiring",
    about: "Our Culture",
    whoweare: "Who We Are",
    careers: "Careers",
    contact: "Contact",
  },
  testimonial:{
    testimonial1Quote: "The company's values make Bath Fitter such a nice place to work! There is a great working atmosphere, excellent team spirit and the employees are really well taken care of. To top it all off, I simply adore my boss!",
    testimonial1Author: "Manon Céleste",
    testimonial1Role: "Legal",

    testimonial2Quote: "I enjoy working for Bath Fitter because it is a challenging environment. We are always evolving and learning new techniques to redefine home renovations. It is a very fun and rewarding company to build yourself a long lasting career.",
    testimonial2Author: "Mark Reilly",
    testimonial2Role: "Shipping",

    testimonial3Quote: "As a member of the Bath Fitter team I feel empowered because I have a voice and my opinions matter. Knowing that I'm appreciated motivates me to do my best. Great culture, firm vision and core values that align with my personal beliefs.",
    testimonial3Author: "Tammy Woods",
    testimonial3Role: "Human Resources",

    testimonial5Quote :"When I first started at Bath Fitter, I had no idea that I would wind up staying for 19 years! Here, we do not only work for Bath Fitter, we work to provide customers with high quality products and unforgettable experiences!",
    testimonial5Author: "Christian Coté",
    testimonial5Role: "Research and Development",

    testimonial6Quote: "Working for Bath Fitter is stimulating. Our creativity and imagination is always welcomed and appreciated. Love their social implication in the community, love the executive team’s engagement at all levels and love the family feeling at work. Love it so much that I came back.",
    testimonial6Author: "Sylvie Lépine",
    testimonial6Role: "Human Resources",

    testimonial7Quote: "I am so grateful for Bath Fitter, they saw something in me 4 years ago I didn’t see in myself. I have been blessed with the opportunity to grow in the company and be recognized for my contribution.",
    testimonial7Author: "Bethany Taylor",
    testimonial7Role: "Human Resources",

    testimonial8Quote: "I like working at Bath Fitter because I feel/am listened to. I can share my ideas with my colleagues and managers while knowing that my thoughts will be taken into account. Together, we do it all.",
    testimonial8Author: "Yannick Levasseur",
    testimonial8Role: "Inventory",

    testimonial9Quote: "I like the way that our manufacturing plant takes the environment into consideration; I'm a green person and really appreciate that. I also love the community involvement. I take pride in my responsibilities - I know someone else is paying for what I do, so I do my best to produce high-quality work for the company.",
    testimonial9Author: "Maria Meguiar",
    testimonial9Role: "Thermoforming",

    testimonial10Quote: "As a Bath Fitter installer, every day is a new adventure for me. It’s very rewarding to see the fruits of your labor at the end of the work day, and the smile on a customer’s face when they see their new bathroom is very rewarding.",

    testimonial11Quote: "The thing I enjoy most about being a Bath Fitter installer is seeing customers’ faces when they see their new bathrooms.",
  },
  homePage: {
    heroSlide1Title: "YOU BELONG",
    heroSlide1TitleHighlight: "here",
    heroSlide1DescriptionLine1: "Join a team that is dedicated to excellence and customer satisfaction.",
    heroSlide1DescriptionLine2: "We are always looking for talented individuals to join our team.",

    heroSlide2Title: "YOUR CAREER STARTS",
    heroSlide2TitleHighlight: "here",
    heroSlide2DescriptionLine1: "We offer a competitive salary, benefits, and a great working environment.",
    heroSlide2DescriptionLine2: "Join us and help us make a difference in the lives of our customers.",
    heroSlide1ButtonLabel: "VIEW JOBS",
    heroSlide2ButtonLabel: "Discover More",

    mediaContentTitle: "Inside Bath Fitter",
    mediaContentDescription: "Welcome to Bath Fitter. Find out more about who we are, what we do, and how we do what we do. Have a look around and be sure to checkout our career opportunities. It’s all here waiting for you to discover!",
    mediaContentCtaLabel: "Learn More",

    awardsTitle: "BEST EMPLOYER, IT'S CERTIFIED !",

    fullWidthInfoDescription: "Our mission is to make people smile, every time they see their bath.",

    mediaContent2Title: "Our ideal candidate",
    mediaContent2Description: "We’re looking for employees in our administrative, factory and retail divisions, who thrive on teamwork and professionalism. Our enthusiastic and resourceful team takes pride in getting it right, so that Bath Fitter products and customer service are second to none. If we’re on the same page, let’s connect.",
    mediaContent2CtaLabel: "Join the team",

    ctaSectionTitle: "Ready to join the team?",
    ctaSectionDescription: "We are always looking for talented individuals to join our team. Join us and help us make a difference in the lives of our customers.",
    ctaSectionCtaLabel: "View Open Positions",
    ctaSectionCta2Label: "Learn More",
  },
  culturePage: {
    heroTitle: "Our Culture",
    heroDescriptionLine1: "At Bath Fitter, we are committed to excellence and customer satisfaction. Our employees are the driving force behind our success. We are always looking for talented individuals to join our team.",
    ourCulture: "BATH FITTER VISION",
    ourCultureDescription: "We strive to hire Bath Fitter employees who are team players and have a positive attitude. We believe in careers that evolve, which is why Bath Fitter is developing learning programs that actively promote the advancement and growth of every employee. We live by Bath Fitter core values and they inspire and guide our company culture.",
    ourCultureCta: "Join the team",
    safety: "SAFETY FIRST",
    safetyDescription: "Bath Fitter does not compromise on safety. Our employees are our number one priority and their safety in our plants, offices, stores and on job sites, is of paramount importance to us.",
    safetyCta: "Learn more",
    applyDescription: "FIND A CAREER NEAR YOU",
    applyCta: "VIEW OPPORTUNITIES",
    infoCarouselTitle: "GIVING BACK",
    infoCarouselDescription: "Working at Bath Fitter is about giving back whenever we can. Our company firmly believes in supporting the community. Not only do we organize company fundraisers, we also actively encourage employee initiatives that extend help to people in need and are about making people smile.",
  },
  whowearePage: {
    heroTitle: "Who We Are",
    heroDescriptionLine1: "Bath Fitter is a company that is dedicated to excellence and customer satisfaction. Our employees are the driving force behind our success. We are always looking for talented individuals to join our team.",
    mediaContentTitle: "OUR STORY",
    mediaContentDescription: "Bath Fitter has been manufacturing and installing premium quality acrylic bathtubs and seamless bath wall systems since 1984. Our unique, tub-over-tub installation process has already transformed more than 2 million bathrooms and the company continues to grow rapidly. Bath Fitter products are made in the United States and Canada in our state-of-the-art manufacturing facilities and we connect with customers through more than 230 retail locations.",
    mediaContentCtaLabel: "JOIN THE TEAM",
  },
  divisions: {
    mainTitle: 'BATH FITTER DIVISIONS',
    mainDescription: 'We are a rapidly growing international company with countless exciting career opportunities in our administration, retail and plant divisions.',
    admin: {
      title: 'ADMINISTRATION',
      description: "Bath Fitter's administrative offices offer a wide range of rewarding career opportunities.",
      imageAlt: 'Administrative professional at work',
      buttonText: 'ADMINISTRATIVE CAREERS',
      list: {
        hr: 'Human Resources',
        customerService: 'Customer Service',
        training: 'Training and Development',
        rd: 'Research and Development',
        finance: 'Finance',
        marketing: 'Marketing',
        legal: 'Legal',
        it: 'Information Technology',
        supplyChain: 'Supply Chain',
        businessDev: 'Business Development'
      }
    },
    plant: {
      title: 'PLANT',
      description: 'There are many exciting Bath Fitter growth opportunities at our two state-of-the-art plants.',
      imageAlt: 'Manufacturing facility',
      buttonText: 'MANUFACTURING CAREERS',
      list: {
        adhesive: 'Adhesive',
        maintenance: 'Maintenance',
        extrusion: 'Extrusion',
        thermoforming: 'Thermoforming',
        shipping: 'Shipping',
        displays: 'Displays',
        quality: 'Quality',
        composites: 'Composites'
      }
    },
    retail: {
      title: 'RETAIL',
      description: "You'll find an impressive variety of careers in Bath Fitter stores from coast-to-coast in the U.S. and Canada.",
      imageAlt: 'Retail team member',
      buttonText: 'RETAIL CAREERS',
      list: {
        installer: 'Installer',
        installerHelper: '',
        leadInstaller: '',
        branchManager: 'Branch Manager',
        salesConsultant: 'Sales Consultant',
        commercialSales: 'Commercial Sales Consultant',
        adminCoordinator: 'Administrative Coordinator',
        insideSales: 'Inside Sales Rep',
        eventManager: '',
        eventWorker: '',
        plumber: 'Plumber',
        shopHand: 'Shop Hand'
      }
    },
  },
  contactPage: {
    heroTitle: "Contact Bath Fitter",
    heroDescription:"We're here to help. If you have any questions or need assistance, please don't hesitate to reach out to us.",
    sectionTitle: "CONTACT US TODAY",
    sectionDescription: "Here is all the information you need to contact our Bath Fitter offices.",
    sectionDescription2: "If you have a question or require additional information, do not hesitate to get in touch.",
    faqDesc: "Visit our FAQ page for answers to common questions",
    questions: "Have a question?",
    faq: "FAQ",
  },
  contactForm: {
    title: "Contact Us",
    description: "We are always looking for talented individuals to join our team. Join us and help us make a difference in the lives of our customers. To submit your application for one of our positions, ",
    link: "click here.",
    placeholders: {
      firstName: "First Name*",
      lastName: "Last Name*",
      email: "Email*",
      phone: "Phone Number*",
      message: "Your Message*"
    },
    submit: "SEND MESSAGE",
    consent: {
      text: "By submitting your personal information into this web form, you agree that we will store, host, share and use your information as provided in our Privacy Policy. See our ",
      privacyLink: "Privacy Policy",
    }
  },
  installers:{
    heroTitle: "Explore the Possibilities",
    heroDescriptionLine1: "Your career at Bathfitter as an installer is more than just a job. It’s a chance to be a part of a team that is dedicated to providing the best possible service to our customers.",
    ctaButton: "JOIN OUR TEAM",

    mediaContentTitle: "HEAR WHAT OUR INSTALLERS HAVE TO SAY...",
    mediaContentDescription: "about opportunities for advancement, their challenges and what their work means to them personally and professionally.",
    ctaLabel: "APPLY NOW",

    benefitsSectionTitle: "A rewarding career opportunity awaits",
    benefitsCard1Title: "Above-average earnings",
    benefitsCard2Title: "Great benefits",
    benefitsCard3Title: "Training & company vehicle provided",
    benefitsCard4Title: "Full-time indoor employment",

    whyBathfitterTitle: "Why Bath Fitter ?",
    whyBathfitterDescription: "We are growing rapidly with locations all across North America. The popularity of our unique one-day installation process, premium quality product and exceptional warranty, have made Bath Fitter the first choice, idustry leader for bathroom renovations in private homes, hotels, motels and multi-unit residential buildings alike.",
    whyBathfitterCta: "OUR CULTURE",

    idealCandidateTitle: "Our ideal candidate",
    idealCandidateDescription: "We are looking for a candidate who can take accurate measurements and is comfortable using hand power tools. The ideal person is a quick learner with a positive attitude, enjoys interacting with people, and takes pride in their appearance. While experience in carpentry or construction work is helpful, it’s not a requirement. A clean driving record is essential, as we value responsibility and reliability in our team members.",
    idealCandidateCta: "APPLY AS AN INSTALLER",
  },
  faq: {
    description: "No matter how much information you put out there, some questions will always remain. We are looking forward to helping you out. However, before reaching out to us please check the below list of most frequently asked questions. This will give us more time to answer specifics and it saves you time waiting for our reply.",
    questions: [
      {
        question: "Does Bath Fitter offer internship opportunities?",
        answer: "At every level, Bath Fitter is always searching for awesome talent. If you are interested in an internship with us, please forward your resume and relevant internship details to: ",
        emailText: "careers@bathfitter.com",
        afterEmail: ""
      },
      {
        question: "How do I apply for a position at Bath Fitter?",
        answer: "There are several ways to apply for a career opportunity at Bath Fitter. ",
        linkText: "Check out our job list page",
        link: "careers",
        afterLink: " and apply today!"
      },
      {
        question: "Is training available?",
        answer: "Training is an important part of what we do. We help you prepare for your new career and once you get the ball rolling, we continue developing our employees for bigger and better career opportunities."
      },
      {
        question: "How many offices/locations does Bath Fitter have?",
        answer: "We have offices and plants in St-Eustache, Quebec, and in Springfield, Tennessee, as well as hundreds of store branches across the United States and Canada."
      },
      {
        question: "Are there benefits?",
        answer: "Bath Fitter offers several comprehensive benefit packages. ",
        linkText: "Look over our employment opportunities",
        link: "careers",
        afterLink: ", apply for a career that interests you and our recruiters can provide you with more details."
      },
      {
        question: "Are there opportunities for growth at Bath Fitter?",
        answer: "Bath Fitter is all about offering more opportunity to our employees. We offer continuous training programs aimed at encouraging our employees to take their careers to the next level."
      },
      {
        question: "How can I refer someone?",
        answer: "We appreciate referrals so, by all means, spread the word! You can refer someone by dropping off his or her resume at one of our locations, or by having them fill out one of our online job applications."
      }
    ]
  },
  reasonsToWork: {
    title: "5 TOP REASONS TO WORK AT BATH FITTER",
    reasons: [
      {
        title: "ROOM TO GROW",
        description: "Everyone at Bath Fitter is provided with opportunities to move their careers forward through continuous training and personal development programs designed to help them achieve their professional goals."
      },
      {
        title: "CULTURE AND PEOPLE",
        description: "Life at Bath Fitter is about being part of a successful team that is resourceful and positive, working together in an atmosphere of accomplishment and fun, which reflects the Mission, Vision and Values that define us ",
        link: "have a look"
      },
      {
        title: "RECOGNITION",
        description: "Through corporate programs, we not only recognize, but also reward employees for all the good stuff – teamwork, initiative, innovation, loyalty and dedication."
      },
      {
        title: "REWARDING WORK",
        description: "Our customers love the work we do and are often amazed by the incredible transformation of their bath spaces. Careers at Bath Fitter are challenging, important and vital to the success of our reputation, product and work."
      },
      {
        title: "COMMUNITY INVOLVEMENT",
        description: "Bath Fitter not only encourages difference-makers, but actively participates with employees who care and want to give back to their communities through helpful initiatives."
      }
    ]
  },
  values: {
    card_1_title: "Mission",
    card_1_description: "To make people smile every time they see their bath.",
    card_2_title: "Vision",
    card_2_description: "To be a great company to work for and do business with.",
    card_3_title: "Values",
    card_3_description: "Customer focused, Pride, Respect, Trust, Teamwork, Resourceful, Positive attitude, Results oriented.",
  },
  careersPage: {
    weare: "WE ARE",
    hiring: "hiring",
    description_line_1:
      "We are always looking for talented individuals to join our team.",
    description_line_2:
      "Join us and help us make a difference in the lives of our customers.",
    openPositions: "View Open Positions",
    InvestInYourFuture: "Invest in your future",
    InvestInYourFutureDescription:
      "We are committed to excellence and customer satisfaction. Our employees are the driving force behind our success. We are always looking for talented individuals to join our team. We offer a competitive salary, benefits, and a great working environment.",
    card_1_title: "Competitive Salary",
    card_1_description: "We offer a competitive salary and benefits package.",
    card_2_title: "Career Growth",
    card_2_description:
      "We offer opportunities for career growth and advancement.",
    card_3_title: "Great Working Environment",
    card_3_description:
      "We offer a great working environment and a supportive team.",
    card_4_title: "Team work makes the dream work",
    card_4_description: "We believe in team work and collaboration.",
    installerApplyButton: "Learn more",
    installerApplyDescription:
      "Your career as an installer is calling and you can answer in just one step.",
    yourCareer: "Career Opportunities",
    jobFilters: {
      Categories: "Categories",
      Locations: "Locations",
      Departments: "Departments",
      AllCategories: "All Categories",
      AllLocations: "All Locations",
      AllDepartments: "All Departments",
    },
    jobsList: {
      loading: "Loading...",
      error: "Error: {error.message}",
      notFound: "Job not found",
    },
  },
  jobDetailsPage: {
    title: "Job Details",
    description: "Job Description",
    loading: "Loading...",
    error: "Error: {error.message}",
    notFound: "Job not found",
    locationQuestions: "Location Questions",
    applyNow: "Apply Now",
    apply: "Apply",
    uploadFile: "Upload",
  },
  privacyPolicy: {
    r: "Bath Fitter®",
    pageTitle: "Privacy Notice for Employees",
    intro:
      "This Notice informs employees of Bath Fitter Distributing Inc. and its affiliates about how we collect, use, disclose, and retain their personal information.",
    objectives: {
      title: "1.0 Objective",
      description:
        "This Notice informs employees of Bath Fitter Distributing Inc. and its affiliates (collectively, “BF”, “we” or “us”), about how BF collects, uses, discloses and retains their personal information.",
    },
    scope: {
      title: "2.0 Scope",
      description:
        "This Notice applies to all BF employees. For the purposes of this Notice, the term “employees” shall also include job applicants and contractors of BF.",
    },
    personalInfo: {
      title: "3.0 Personal Information – Definition and Exceptions",
      description:
        "In this Notice, “personal information” refers to any information about an identifiable individual. Personal information may include the type of information detailed in section 5 of this Notice.",
    },
    generalPolicy: {
      title: "4.0 General Policy Statement",
      description:
        "BF is committed to handling personal information of BF employees in compliance with applicable data protection legislation based on the employee's residence location.",
    },
    collectionOfInfo: {
      title: "5.0 Collection of Personal Information",
      description:
        "BF collects personal information of employees as necessary to establish, manage and terminate their employment or contractual relationship with BF. Personal information that BF collects about employees may include the following:",
      list: [
        "Identifiers and contact information, such as your name, email and postal addresses, telephone number, government issued IDs (such as your Social Insurance Number, visa and passport information), work/residence permits, Internet Protocol (IP) address (if it can identify you as an individual), device identifier (for example, if you connect to our Wi-Fi), account information (for example, if you create an account to use an enterprise application). We may also collect information about your emergency contacts.",
        "Demographic information, such as date of birth, gender, citizenship, medical information, and disability information. We may collect information about your ethnicity on a voluntary basis, in compliance with applicable laws.",
        "Professional or employment-related information, including: title and compensation history, work schedule and status, work experience, education, licensure and other credentials, benefits and leave information, information related to any legal issues or disputes that may arise, performance reports and assessments, psychometric test results, disciplinary records, termination date and reasons (voluntary or involuntary and details) for termination.",
        "Financial information, such as financial institution, bank account number and information required to issue fiscal forms.",
        "Beneficiary and Dependent information, such as their names, contact information, and dates of birth. ",
        "Background check information, including criminal records.",
        "Geolocation data, such as GPS location information, and similar types of information created and generated by your use of BF assets.",
        "Information about your use of the internet, our networks, our devices, and our premises, such as information about your use of your work email account, the internet, BF computers, phones and other devices to which you have access in the context of your employment, and your own device in the context of bring your own devices (BYOD) programs. We may also collect video and still photo images through closed-circuit video cameras.",
        "Other information, such as information you may be requested to provide from time to time or information you may provide through our third-party service providers (e.g. Greenhouse.com, LinkedIn).",
      ],
    },
    useOfInfo: {
      title: "6.0 Use of Personal Information",
      description:
        "BF uses personal information about BF employees for purposes necessary to establish, manage or terminate its relationship (employment or contractual) with such BF employees and for other legitimate business purposes, as detailed in this Notice and other BF policies and notices, or as otherwise permitted by applicable data protection legislation. For example, BF may use personal information of employees for the following purposes:",
      list: [
        "Personnel administration, such as identity verification, tax and social security management, scheduling and monitoring attendance and hours worked, establishing emergency contact information, processing employee work-related claims.",
        "Benefits processing, including determining eligibility for you and your dependents and beneficiaries.",
        "Payroll administration, including processing salary/sick pay and leaves of absence.",
        "Performance reviews and evaluation, including making decisions about assignments, training, compensation, and suitability for promotions.",
        "Monitoring the use of information technology, other equipment and property for legitimate business purposes, such as security, to protect against fraud, and to ensure compliance with our policies and procedures.",
        "Ensuring health and safety, including maintaining a safe workplace and to manage health, safety and security issues.",
        "Internal business purposes, such as to implement BF’s disaster recovery, business continuity or other similar emergency plans and procedures; to conduct internal reviews, audits and investigations; to create statistics and analytics tools for legitimate business purposes, such as improving business processes and managing BF’s workforce.",
        "Preparing and administering your work visa application.",
        "Complying with our legal and regulatory obligations, meeting security requirements, and for fraud management purposes, including investigation of misconduct, non-performance of duties, and contravention of the employment agreement.",
      ],
    },
    sharingOfInfo: {
      title: "7.0 Sharing of Personal Information",
      description:
        "BF may make personal information of BF employees available to other BF employees for use in connection with the performance of their assigned work and other tasks. We may share personal information of BF employees within our corporate group for the purposes detailed in this Notice. BF may also disclose personal information of BF employees to third parties for purposes detailed in this Notice and other BF policies and notices, or as otherwise required or permitted by applicable laws. For example, BF may disclose the personal information of BF employees:",
      list: [
        "to government agencies with a need to know (e.g., to respond to employment insurance inquiries, in connection with an application for a grant, subsidy or program, etc.);",
        "to third-party service providers engaged by BF to provide administrative services (e.g. audit services, information technology services, payroll services, human resources administration services, benefits provision and administration, or professional services firms like law firms or accounting firms that provide professional services to BF). BF will strive to protect personal information disclosed to third parties by contractual agreements requiring that those third parties adhere to confidentiality and security procedures;",
        "to relevant third parties when required to perform reference or background checks or when contractually required by a BF client or supplier; and",
        "to relevant third parties in connection with a prospective or completed business transaction (e.g. a purchase/sale of business or assets, a merger or amalgamation or a financing transaction) to which BF is a party.",
      ],
      addition: "In addition, BF will cooperate with law enforcement agencies and will comply with any court order or law requiring disclosure of personal information, without any additional notice to, or consent by, the relevant BF employees."
    },
    crossBorderTransfer: {
      title: "8.0 Cross-Border Transfer of Personal Information",
      description:
        "As we operate a global business, personal information of BF employees may be transferred, processed and stored outside your country of residence, and therefore may be available to government authorities under lawful orders and laws applicable in such foreign jurisdictions.",
    },
    monitoring: {
      sectionTitle: "9.0 Monitoring",
      useOfResources: {
        title: "9.1 Use of BF's Information Technology Resources",
        description:
          "All documents, records, data and information created, processed, stored or transmitted using BF’s information technology resources (e.g. facilities, equipment, devices and services, including email, voice mail, Internet and cloud-based application access), or on personal devices used for business purposes, are assumed to be work-related. BF employees do not have any expectation of privacy regarding those documents, records, data or information, regardless of whether or not they are marked “personal”, “private” or “confidential”. BF reserves the right to monitor all use of BF’s information technology resources, and to access, use, disclose and retain all documents, records, data and information created, processed, stored or transmitted using BF’s information technology resources or on personal devices used for business purposes, without any additional notice to, or consent by, the relevant BF employees. All BF information technology resources must be used in accordance with BF relevant information security policies.",
      },
      videoMonitoring: {
        title: "9.2 Monitoring",
        description:
          "BF may engages in video monitoring in its physical work places for business purposes (including safety and security purposes). BF may also record conversations, written or oral, of BF employees using BF’s information technology resources for a variety of business purposes (including quality assurance purposes), and share those recordings with third-party service providers for storage or business analysis/intelligence purposes.",
      },
    },
    accuracyAndCorrections: {
      title: "10.0 Accuracy, Access to and Correction",
      description:
        "Employees must provide BF with accurate and complete personal information, and must update their personal information held by BF promptly after any change to the information. Employees may request information about, and access to, their personal information held by BF, and may request that the personal information be amended if it is inaccurate or incomplete, subject to various exceptions provided by applicable law (including when access must be denied to protect other individuals and to protect BF privileged or confidential information). Employees may also update their personal information through our HRIS. Employees may direct their access requests or requests to have their personal information corrected to the Human Resources team. BF employees who are not satisfied with the outcome of their request directed to the Human Resources team can submit a formal access request directly to the Privacy Officer at privacy@bathfitter.com. ",
    },
    complaintsAndQuestions: {
      title: "11.0 Complaints and Questions",
      description:
        "BF employees who believe their personal information has been handled in contravention of this Notice may raise the matter with their manager or supervisor. BF employees who are not satisfied with the outcome of their complaint or question may address their concern to the Privacy Officer at the following email address: privacy@bathfitter.com",
    },
    changesToNotice: {
      title: "12.0 Changes to this Notice",
      description:
        'BF regularly reviews its policies and procedures, and may revise this Notice from time to time. ',
    },
    versionControl: {
      title: "Version Control",
      table: {
        version: "1.0",
        effectiveDate: "February 14, 2024",
        revisionDate: "February 14, 2024",
        approval: "Chief Privacy Officer",
      },
      tableHeaders: {
        version: "Version",
        effectiveDate: "Effective Date",
        revisionDate: "Revision Date",
        approval: "Approval",
      },
    },
  },
  footer: {
    residential: "RESIDENTIAL",
    commercial: "COMMERCIAL",
    privacy: "PRIVACY POLICY",
    sitemap: "SITEMAP",
    address: " 102 Evergreen Dr, Springfield, TN 37172, USA",
  },
  // Add to the En object:

  seo: {
    // Global
    brandName: "Bath Fitter",
    separator: "|",
    
    // Home page
    homePage: {
      title: "We are hiring | Bath Fitter Careers", // ~32 chars
      description: "Join Bath Fitter's team of renovation experts. We offer competitive salaries, benefits, and growth opportunities across North America.", // ~120 chars
      schema: {
        jobsAvailable: "Career opportunities at Bath Fitter across USA and Canada",
        companyMission: "Making people smile, every time they see their bath"
      }
    },
  
    // Culture page
    culturePage: {
      title: "Our Culture | Bath Fitter Careers", // ~31 chars
      description: "Discover Bath Fitter's commitment to excellence, employee growth, and community involvement. Join a team that values safety and innovation.", // ~125 chars
      schema: {
        missionStatement: "To make people smile every time they see their bath.",
        visionStatement: "To be a great company to work for and do business with.",
        valuesStatement: "Trust, Teamwork, Resourceful, Positive attitude, Results oriented."
      }
    },
  
    // Who We Are page
    whoWeArePage: {
      title: "Who We Are | Bath Fitter Careers", // ~31 chars
      description: "Learn about Bath Fitter's legacy of excellence since 1984. Join our team of experts dedicated to transforming bathrooms across North America.", // ~120 chars
    },
  
    // Careers/Jobs page
    careersPage: {
      title: "Career Opportunities | Bath Fitter Careers", // ~39 chars
      description: "Explore exciting career opportunities at Bath Fitter. Find positions in retail, manufacturing, and administration across North America.", // ~120 chars
      schema: {
        divisions: {
          retail: "Find careers in Bath Fitter stores from coast to coast",
          plant: "Join our state-of-the-art manufacturing facilities",
          admin: "Discover opportunities in our administrative offices"
        },
        benefits: {
          salary: "Competitive salary and benefits package",
          growth: "Career growth and advancement opportunities",
          environment: "Supportive team and great working environment",
          teamwork: "Collaborative culture focused on excellence"
        },
        locations: {
          usa: ["TN", "GA", "CA", "MI", "AZ", "TX", "ME", "VT", "MA", "AL", "IN", "SC", "NY", "NE", "IL", "FL", "KY"],
          canada: ["QC", "BC"]
        }
      }
    },
  
    // Contact page
    contactPage: {
      title: "Contact Us | Bath Fitter Careers", // ~30 chars
      description: "Get in touch with Bath Fitter's recruitment team. We're here to answer your questions about career opportunities and guide your application process.", // ~125 chars
    },

    faqPage: {
      title: "Frequently Asked Questions",
      description: "Find answers to common questions about Bath Fitter's hiring process, benefits, and career opportunities. Get in touch with our team for more information.",
    },
  
    // Locations
    locations: {
      usa: {
        address: "102 Evergreen Dr, Springfield, TN 37172, USA",
        country: "United States"
      },
      canada: {
        address: "225 Rue Roy, Saint-Eustache, QC J7R 5R5, Canada",
        country: "Canada"
      }
    },
  
    // Common metadata
    common: {
      author: "Bath Fitter",
      type: "website",
      contactEmail: "careers@bathfitter.com",
      organization: {
        name: "Bath Fitter",
        alternateName: "Bath Fitter Careers",
        description: "Premium bathtub and shower solutions since 1984"
      }
    }
  },

  resources: ResourcesEn,
};
