import HeroSection from "../components/HeroSection/HeroSection";
import desktopImage from "../assets/media/HeroSlide5.png";
import mobileImage from "../assets/media/HeroSlide5m.png";
import { buildUrl } from "../utils/urlUtils";
import MediaContent from "../components/MediaContent/MediaContent";
import useLanguage from "../hooks/useLanguage";

import bathfitter from "../assets/media/whybathfitter-1.jpg";
import CandidateSlide1 from '../assets/media/CandidateSlide1.png';
import { useTranslation } from "react-i18next";
import CTASection from "../components/CTASection/CTASection";
import BenefitsSection from "../components/Benefits/Benefits";
import TestimonialSection from "../components/TestimonialSection/TestimonialSection";

import Person1 from '../assets/media/DavidCooper.png';
import Person2 from '../assets/media/JustinBerg.png';

export default function Installers() {
    const { language } = useLanguage();
    const { t } = useTranslation();

    const testimonials = [
        {
            quote: t('testimonial.testimonial10Quote'),
            author: 'David Cooper',
            role: '',
            image: Person1,
        },
        {
            quote: t('testimonial.testimonial11Quote'),
            author: 'Justin Berg',
            role: '',
            image: Person2,
        },
    ];
    
    return (
        <>
            <HeroSection
                desktopImage={desktopImage}
                mobileImage={mobileImage}
                title={t('installers.heroTitle')}
                descriptionLine1={t('installers.heroDescriptionLine1')}
                buttonLabel={t('installers.ctaButton')}
                onButtonClick={() => window.location.href = buildUrl('/careers/?department=4005296008', language)}
                maxWidth={'465px'}
            />

            <MediaContent
                mediaLayout="left"
                mediaSrc={language === 'en' ? "https://player.vimeo.com/video/403403755" : "https://player.vimeo.com/video/403738494"}
                mediaType="video"
                title={t('installers.mediaContentTitle')}
                description={t('installers.mediaContentDescription')}
                ctaLabel={t('installers.ctaLabel')}
                onCtaClick={() => window.location.href = buildUrl('/careers/?department=4005296008', language)}
            />

            <BenefitsSection />

            <MediaContent
                mediaLayout="right"
                mediaSrc={bathfitter}
                mediaType="image"
                title={t('installers.whyBathfitterTitle')}
                description={t('installers.whyBathfitterDescription')}
                ctaLabel={t('installers.whyBathfitterCta')}
                onCtaClick={() => window.location.href = buildUrl('/ourculture', language)}
            />

            <MediaContent
                mediaLayout="left"
                mediaSrc={CandidateSlide1}
                mediaType="image"
                title={t('installers.idealCandidateTitle')}
                description={t('installers.idealCandidateDescription')}
                ctaLabel={t('installers.idealCandidateCta')}
                onCtaClick={() => window.location.href = buildUrl('/careers/?department=4005296008', language)}
            />

            <TestimonialSection testimonials={testimonials} />

            <CTASection
                title={t('homePage.ctaSectionTitle')}
                description={t('homePage.ctaSectionDescription')}
                ctaLabel={t('homePage.ctaSectionCtaLabel')}
                cta2Label={t('homePage.ctaSectionCta2Label')}
                onCtaClick={() => window.location.href = buildUrl('/careers/?department=4005296008', language)}
                onCta2Click={() => window.location.href = buildUrl('/FAQ', language)}
            />
        </>
    );
}