import styled from 'styled-components';
import { PrimaryButton } from '../common/Common.styles';

export const HeaderContainer = styled.div`
position: sticky;
top: 0;
align-items: center;
background-color: var(--header-background-color);
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: flex-start;
margin: 0 auto;
max-width: 100%;
min-height: 100px;
transition: min-height .3s ease-in-out;
box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
z-index: 1000;
width: 100%;

@media (max-width: 960px) {
    min-height: 80px;
}
`;

export const Logo = styled.img`
height: 55px;
`;

export const MobileLogo = styled.img`
height: 45px;
padding: 0 2rem;
display: none;

@media (max-width: 960px) {
    display: block;
}
`;

export const Nav = styled.nav`
align-items: center;
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
width: 1200px;
padding: 0 2rem;
margin: 0 auto;
gap: 1.2rem;

& button {
margin-left: 1rem;
}

@media (max-width: 1220px) {
   gap: 1rem;

      & button {
   font-size: 0.8rem;
}
}
@media (max-width: 960px) {
    display: none;
}
`;

export const NavLink = styled.a`
color: var(--color-white);
font-weight: 600;
text-transform: uppercase;
white-space: nowrap;

&:first-of-type {
    margin-right: auto;
}

& img {
    height: 55px;
}
@media (max-width: 1220px) {
    font-size: 0.8rem;
}
@media (max-width: 960px) {
    display: none;
}

`;

export const HamburgerIcon = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    span {
        height: 2px;
        width: 25px;
        background: #333;
        margin-bottom: 4px;
        border-radius: 5px;
    }

    @media (max-width: 960px) {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        span {
            background: #fff;
        }
    }
`;

export const MobileMenu = styled.div`
    display: none;
    @media (max-width: 960px) {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        background: #FFFFFF;
        width: 100%;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,.3);
        z-index: 100;
        height: max-content;

        a {
            padding: 1rem 2rem;
            color: #333;
            border-bottom: 1px solid #eee;
            text-align: right;
            &:last-child {
                border-bottom: none;
            }
        }
    }
`;

export const MobileWeAreHiring = styled(PrimaryButton)`
    display: none;
    @media (max-width: 960px) {
        position: sticky;
        top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,.3);
        z-index: 1000;
        width: 100%;

        &:hover {
           opacity: 1;
           filter: brightness(0.9); 
        }
    }
`;

export const MobileNavLink = styled.a`
    color: var(--color-white);
    text-transform: uppercase;
    border-bottom: 1px solid #eee;
    &:last-child {
        border-bottom: none;
    }
`;