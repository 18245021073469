// DivisionsSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import useLanguage from '../../hooks/useLanguage';
import { buildUrl } from '../../utils/urlUtils';
import {
  Container,
  Header,
  Title,
  Subtitle,
  DivisionCard,
  Content,
  DivisionTitle,
  DivisionText,
  Lists,
  List,
  ImageWrapper
} from './DivisionsSection.styles';

import division1 from '../../assets/media/Division-admin.png';
import division2 from '../../assets/media/Division-plant.png';
import division3 from '../../assets/media/Division-retail.png';
import { SecondaryHeading, SectionHeading, TertiaryButton } from '../common/Common.styles';

const DivisionsSection = () => {
  const { language } = useLanguage();
  const { t } = useTranslation();

  const handleNavigation = (path) => {
    window.location.href = buildUrl(path, language);
  };

  const divisions = [
    {
      id: 'admin',
      title: t('divisions.admin.title'),
      description: t('divisions.admin.description'),
      image: division1,
      imageAlt: t('divisions.admin.imageAlt'),
      buttonText: t('divisions.admin.buttonText'),
      buttonPath: '/careers?category=Admin',
      listLeft: [
        t('divisions.admin.list.hr'),
        t('divisions.admin.list.customerService'),
        t('divisions.admin.list.training'),
        t('divisions.admin.list.rd'),
        t('divisions.admin.list.finance')
      ],
      listRight: [
        t('divisions.admin.list.marketing'),
        t('divisions.admin.list.legal'),
        t('divisions.admin.list.it'),
        t('divisions.admin.list.supplyChain'),
        t('divisions.admin.list.businessDev')
      ]
    },
    {
      id: 'plant',
      title: t('divisions.plant.title'),
      description: t('divisions.plant.description'),
      image: division2,
      imageAlt: t('divisions.plant.imageAlt'),
      buttonText: t('divisions.plant.buttonText'),
      buttonPath: '/careers?category=Plant',
      listLeft: [
        t('divisions.plant.list.adhesive'),
        t('divisions.plant.list.maintenance'),
        t('divisions.plant.list.extrusion'),
        t('divisions.plant.list.thermoforming')
      ],
      listRight: [
        t('divisions.plant.list.shipping'),
        t('divisions.plant.list.displays'),
        t('divisions.plant.list.quality'),
        t('divisions.plant.list.composites')
      ]
    },
    {
      id: 'retail',
      title: t('divisions.retail.title'),
      description: t('divisions.retail.description'),
      image: division3,
      imageAlt: t('divisions.retail.imageAlt'),
      buttonText: t('divisions.retail.buttonText'),
      buttonPath: '/careers?category=Retail',
      listLeft: [
        { text: t('divisions.retail.list.installer'), path: '/installers' },
        { text: t('divisions.retail.list.installerHelper'), path: language === 'en' ? '/installers' : '' },
        { text: t('divisions.retail.list.leadInstaller'), path: '/installers' },
        t('divisions.retail.list.branchManager'),
        t('divisions.retail.list.salesConsultant'),
        t('divisions.retail.list.commercialSales')
      ],
      listRight: [
        t('divisions.retail.list.adminCoordinator'),
        t('divisions.retail.list.insideSales'),
        t('divisions.retail.list.eventManager'),
        t('divisions.retail.list.eventWorker'),
        t('divisions.retail.list.plumber'),
        t('divisions.retail.list.shopHand')
      ]
    }
  ];

  return (
    <Container>
      <Header>
        <SectionHeading>{t('divisions.mainTitle')}</SectionHeading>
        <Subtitle>{t('divisions.mainDescription')}</Subtitle>
      </Header>

      {divisions.map((division, index) => (
        <DivisionCard key={division.id} reversed={index === 1}>
          <Content>
            <SecondaryHeading>{division.title}</SecondaryHeading>
            <DivisionText>{division.description}</DivisionText>
            <Lists>
              <List>
                {division.listLeft.map((item, i) => (
                <li key={`${division.id}-left-${i}`}>
                {typeof item === 'object' && item.path.length > 0 ? (
                  <button
                    style={{ background: 'none', border: 'none', color: '#5bb872', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigation(item.path);
                    }}
                  >
                    {item.text}
                  </button>
                ) : (
                  // If item is an object without a `path`, render item.text, otherwise render item as is
                  typeof item === 'object' && item.text ? item.text : item
                )}
              </li>
              
                ))}
              </List>
              <List>
                {division.listRight.map((item, i) => (
                  <li key={`${division.id}-right-${i}`}>{item}</li>
                ))}
              </List>
            </Lists>
            <TertiaryButton
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation(division.buttonPath);
              }}
            >
              {division.buttonText}
            </TertiaryButton>
          </Content>
          <ImageWrapper>
            <img src={division.image} alt={division.imageAlt} />
          </ImageWrapper>
        </DivisionCard>
      ))}
    </Container>
  );
};

export default DivisionsSection;