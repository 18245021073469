import styled from 'styled-components';

// Ensure Carousel Container occupies space and handles overflow properly
export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  height: 450px;
  overflow: hidden;
  background-color: #f0f0f0;
`;

// Slide styling with proper transitions and consistent layout
export const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: 
    linear-gradient(90deg, #84bd00 0%, rgba(132, 189, 0, 0.8) 1%, rgba(0, 164, 153, 0.6) 71%, #00a499 100%),
    url(${(props) => props.$imgurl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  opacity: ${(props) => (props.className === 'active' ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 960px) {
    padding: 2rem;
  }
`;

// Testimonial container to ensure consistent height and alignment
export const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 2rem;
  max-width: 800px;
  min-height: 350px;
  margin: 0 auto;
  color: var(--color-white);
  position: relative;
`;

// Positioned quote image stays consistent across slides
export const QuoteImage = styled.img`
  position: absolute;
  top: 15px;
  height: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

// Testimonial text with consistent spacing
export const TestimonialText = styled.p`
  font-size: 1.3rem;
  margin: 1rem 0;
  flex: 1;
  display: flex;
  align-items: center; 
  justify-content: center;

  @media (max-width: 960px) {
    font-size: 1rem;
  }
`;

// Author section stays aligned at the bottom
export const TestimonialAuthor = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  text-align: left;
`;

// Testimonial image with responsive size
export const TestimonialImage = styled.img`
  width: 75px;
  height: 75px;
  border: 4px solid #fff;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 960px) {
    width: 50px;
    height: 50px;
  }
`;

export const TestimonialName = styled.h3`
  font-size: 1.2rem;
`;

export const TestimonialRole = styled.h4`
  font-size: 1rem;
  opacity: 0.8;
`;

// Dots container centered at the bottom
export const DotsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
`;

// Dots for navigation with hover effect
export const Dot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#fff' : 'transparent')};
  border: solid 1px #fff;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;
